import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toWei } from '../helpers/utils';

const urlGas = 'https://ethgasstation.info/json/ethgasAPI.json'

@Injectable({
  providedIn: 'root'
})
export class PolygonGasService {


  constructor(
    private http: HttpClient
  ) { }

  useGasPrice() {
    this.http.get<any>(urlGas)
      .subscribe((response: any) => {
        return response;
      })
  }


  /**
   * Obtener precio del gas de la red
   * @param opt 
   * @returns 
   */
   async getCurrentGasPrices(opt = 1): Promise<GasPrices>{
    let oracle: any;

    if(opt === 1){
      oracle = await this.getOracleCurrentGasPrices1();
    }else if(opt === 2){
      oracle = await this.getOracleCurrentGasPrices2();
    }

    const fields = [
      'SafeGasPrice',
      'ProposeGasPrice',
      'FastGasPrice',
      'suggestBaseFee',
      'mingaspricegwei',
      'rapidgaspricegwei',
      'fastgaspricegwei',
      'standardgaspricegwei',
    ];

    for (const field of fields) {
      if(Object.prototype.hasOwnProperty.call(oracle, field)){ oracle[field] = toWei(oracle[field], 9); }
    }

    return oracle;
  }


  /**
   * Oraculo 1
   * @returns 
   */
  async getOracleCurrentGasPrices1() {
    const url = `https://api.polygonscan.com/api`;
    console.log('url', url);

    return new Promise((resolve, reject) => {

      const params = new HttpParams()
        .set('module', 'gastracker')
        .set('action', 'gasoracle')
        .set('apikey', 'TFAY99Z9MR9YFI4EP1SH91TINJTTGKWV5E');

      /**
       * "SafeGasPrice"
       * "ProposeGasPrice"
       * "FastGasPrice"
       * "suggestBaseFee"
       */
      this.http.get(url, { params })
        .subscribe((res: any) => { resolve(res.result); }, err => { reject(err); });
    });
  }

  
  /**
   * Oraculo 2
   * @returns 
   */
  async getOracleCurrentGasPrices2() {
    const url = `https://gpoly.blockscan.com/gasapi.ashx`;
    console.log('url', url);

    return new Promise((resolve, reject) => {

      const params = new HttpParams()
        .set('apikey', 'key')
        .set('method', 'pendingpooltxgweidata');

      /**
       * "mingaspricegwei"
       * "rapidgaspricegwei"
       * "fastgaspricegwei"
       * "standardgaspricegwei"
       */
      this.http.get(url, { params })
        .subscribe((res: any) => { resolve(res.result); }, err => { reject(err); });
    });
  }

}

export interface GasPrices {
  SafeGasPrice?: number;
  ProposeGasPrice?: number;
  FastGasPrice?: number;
  suggestBaseFee?: number;
  mingaspricegwei?: number;
  rapidgaspricegwei?: number;
  fastgaspricegwei?: number;
  standardgaspricegwei?: number;
}
