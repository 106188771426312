import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Web3Service } from "src/app/services/contract/web3.service";
import { Observable, Subscription, catchError, from, map, of } from "rxjs";
import { getFormErrors } from "src/app/helpers/formErrors.helper";
import { fromWei, toWei } from "src/app/helpers/utils";
import { CollectionService } from "src/app/services/firebase/collection.service";
import { LocalCartService } from "src/app/services/local-cart.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Sweetalert2stepsService } from "src/app/services/sweetalert2steps.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { PurchaseService } from "src/app/services/firebase/purchase.service";
import { UsersCartService } from "src/app/services/usersCart.service";
import { TopPayService } from "src/app/services/api/topPay.service";
import { OracleService } from "src/app/services/oracle.service";
import { UserMyInvestmentCalculatorFormComponent } from "src/app/shared/user-my-investment-calculator-form/user-my-investment-calculator-form.component";

@Component({
  selector: "app-dashboard-user-my-investment-form",
  templateUrl: "./dashboard-user-my-investment-form.component.html",
  styleUrls: ["./dashboard-user-my-investment-form.component.css"],
})
export class DashboardUserMyInvestmentFormComponent implements OnInit, OnDestroy {

  /** Formulario de calculo de invesión */
  @ViewChild("myInvestmentCalculator") calculatorForm!: UserMyInvestmentCalculatorFormComponent;

  constructor( ) { }

  ngOnInit() { }

  /**
   * @dev Al seleccionar una propiedad para editar en el carrito de lista de deseos
   * @param item 
   */
  onSelectEditWishItem(item: any) {
    // console.log('onSelectEditWishItem', item);
    this.calculatorForm.bulkEditItem({
      id: item._id,
      collectionId: item.collectionId,
      nroTokens: item.nroTokens
    });
  }

  ngOnDestroy(): void {
    // this.sub$.unsubscribe();
  }
}
