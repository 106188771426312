import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "src/app/services/common.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { getFormErrors } from "src/app/helpers/formErrors.helper";
import { NewsletterService } from "src/app/services/firebase/newsletter.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  version: any;
  public form!: FormGroup;
  public vm: any = {
    email: [
      { type: "required", message: "form.validations.isRequired" },
      { type: "pattern", message: "form.validations.email" },
    ],
  };
  public submitted = false;
  public showSubmitLoaderButton = false;

  constructor(
    private commonSrv: CommonService,
    private fb: FormBuilder,
    private sweetAlert2Srv: Sweetalert2Service,
    private newsletterSrv: NewsletterService
  ) {}

  ngOnInit() {
    this.getVersion();
    this.buildForm();
  }

  getVersion() {
    this.version = this.commonSrv.getVersion();
  }

  buildForm() {
    this.form = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          // regex email
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
    });
  }

  get f() {
    return this.form.controls;
  }

  async onSubmit() {
    try {
      this.submitted = true;
      this.showSubmitLoaderButton = true;

      if (!this.form.valid) {
        const formErrors = getFormErrors(this.form);
        console.log("formErrors", formErrors);
        return;
      }

      const formData = this.form.value;
      const data = {
        email: `${formData.email}`.trim().toLowerCase(),
      };

      console.log(data);

      await this.newsletterSrv.store(data);

      this.form.disable();

      this.form.patchValue({
        email: "",
      });
      this.submitted = false;

      this.sweetAlert2Srv.showToast("footer.thanksForSubscribing", "success");
      return;
      // this.onSubmitFormEvent.next(this.form.value);
    } catch (err) {
      console.log("Error on FoooterComponent.onSubmit", err);
      return;
    } finally {
      this.showSubmitLoaderButton = false;
      this.form.enable();
    }
  }
}
