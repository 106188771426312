import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { TemplateService } from "src/app/services/template.service";
import { Subscription } from "rxjs";
import { AuthenticationService } from "../../../services/authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-dashboard-user-sidebar",
  templateUrl: "./dashboard-user-sidebar.component.html",
  styleUrls: ["./dashboard-user-sidebar.component.css"],
})
export class DashboardUserSidebarComponent implements OnInit {
  @ViewChild("sidebarUSer") sidebarUser!: ElementRef<any>;
  @ViewChild("menuMobile") menuMobile!: ElementRef<any>;

  public showNavbar = false;
  private sub$!: Subscription;

  constructor(
    private templateSrv: TemplateService,
    private authSrv: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.templateSrv.events$.next({ type: "sidebarUser", data: false });
    this.sub$ = this.templateSrv.events$.subscribe((res: any) => {
      const { type, data } = res;
      if (type === "sidebarUser") {
        this.showNavbar = data;
      }
    });
  }

  async openNav() {
    this.sidebarUser.nativeElement.classList.toggle("active");

    if (this.sidebarUser.nativeElement.classList.contains("active")) {
      this.templateSrv.events$.next({ type: "sidebarUser", data: false });
    } else {
      this.templateSrv.events$.next({ type: "sidebarUser", data: true });
    }
  }

  scrollToElement(element: string, page: string): void {
    this.router.navigate(["/", page]).then(
      (nav) => {
        console.log(nav);

        setTimeout(() => {
          let menuItem = document.querySelector(element);
          menuItem?.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 1);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  toggleMenuMobile() {
    this.menuMobile.nativeElement.classList.toggle("show");
  }

  async logout() {
    return this.authSrv.logout();
  }
}
