import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-profile-verification-list',
  templateUrl: './profile-verification-list.component.html',
  styleUrls: ['./profile-verification-list.component.css']
})
export class ProfileVerificationListComponent implements OnInit {

  public profile: any;
  public walletVerification: any;

  public userProfile$!: Observable<any>;

  constructor(
    private authSrv: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.userProfile$ = this.authSrv.userDoc$;
  }

}
