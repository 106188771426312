import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appFloatInput]'
})
export class FloatInputDirective {

  // constructor(private el: ElementRef) {}

  // @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
  //   const key = event.key;

  //   // Permitir teclas de navegación, retroceso, etc.
  //   const allowedKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter', '.'];
  //   if (allowedKeys.includes(key)) {
  //     return; // Permitir la acción predeterminada
  //   }

  //   // Convertir comas en puntos y permitir solo números
  //   if (key === ',') {
  //     event.preventDefault();
  //     this.insertTextAtCursor('.');
  //   } else if (!key.match(/^\d$/) && key !== '.') {
  //     event.preventDefault();
  //   }
  // }

  // private insertTextAtCursor(text: string) {
  //   if (!this.el.nativeElement.setRangeText) {
  //     this.el.nativeElement.value += text;
  //     return;
  //   }

  //   const start = this.el.nativeElement.selectionStart;
  //   const end = this.el.nativeElement.selectionEnd;

  //   this.el.nativeElement.setRangeText(text, start, end, 'end');
  // }

  @Input() maxDecimals?: number;

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const key = event.key;
    const currentValue = this.el.nativeElement.value;
    // Permitir teclas de navegación, retroceso, etc.
    const allowedKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter'];
    if (allowedKeys.includes(key)) {
      return; // Permitir la acción predeterminada
    }

    // Convertir comas en puntos
    if (key === ',' || key === '.') {
      event.preventDefault();
      if (!currentValue.includes('.')) { this.insertTextAtCursor('.'); }
      return;
    }

    // Permitir solo números
    if (!key.match(/^\d$/)) {
      event.preventDefault();
      return;
    }

    // Restricción de decimales
    if (currentValue.includes('.') && this.maxDecimals !== undefined) {
      const parts = currentValue.split('.');
      const decimalPart = parts[1];
      if (decimalPart && decimalPart.length >= this.maxDecimals) {
        event.preventDefault();
      }
    }
  }

  private insertTextAtCursor(text: string) {
    const { nativeElement } = this.el;
    if (!nativeElement.setRangeText) {
      nativeElement.value += text; // Fallback para navegadores sin setRangeText
      return;
    }

    const start = nativeElement.selectionStart;
    const end = nativeElement.selectionEnd;
    nativeElement.setRangeText(text, start, end, 'end');
    nativeElement.dispatchEvent(new Event('input')); // Disparar el evento input para asegurar la detección del cambio de valor
  }
}
