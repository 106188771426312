import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PipesModule } from "../pipes/pipes.module";
import { RouterModule } from "@angular/router";
import { ClipboardModule } from "ngx-clipboard";
import { TranslateModule } from "@ngx-translate/core";
import { SwiperModule } from "swiper/angular";
import { FooterComponent } from "./footer/footer.component";
import { FormContactComponent } from "./forms/form-contact/form-contact.component";
import { SliderNewsComponent } from "./sliders/slider-news/slider-news.component";
import { FormProjectYourInvestmentComponent } from "./forms/form-project-your-investment/form-project-your-investment.component";
import { PropertiesItemsCardComponent } from "./properties-items-card/properties-items-card.component";
import { SliderGrowYourInvestmentComponent } from "./sliders/slider-grow-your-investment/slider-grow-your-investment.component";
import { HeaderComponent } from "./header/header.component";
import { SliderPropertyImgComponent } from "./sliders/slider-property-img/slider-property-img.component";
import { AdminLayoutComponent } from "./admin-layout/admin-layout.component";
import { HeaderAdminComponent } from "./header-admin/header-admin.component";
import { SidebarAdminComponent } from "./sidebar-admin/sidebar-admin.component";
import { NotAccessComponent } from "./not-access/not-access.component";
import { InputFileSingleButtonComponent } from "./input-file-single-button/input-file-single-button.component";
import { FormBuyPropertieComponent } from "./forms/form-buy-propertie/form-buy-propertie.component";
import { FormRegisterUserComponent } from "./forms/form-register-user/form-register-user.component";
import { FormLoginUserComponent } from "./forms/form-login-user/form-login-user.component";
import { InputSingleImageComponent } from "./input-single-image/input-single-image.component";
import { FormProfileComponent } from "./forms/form-profile/form-profile.component";
import { FormProjectYourInvestmentUserComponent } from "./forms/form-project-your-investment-user/form-project-your-investment-user.component";
import { CalculatorComponent } from "./calculator/calculator.component";
import { DirectivesModule } from "../directives/directives.module";
import { ModalToppayFormComponent } from "./modal-toppay-form/modal-toppay-form.component";
import { UserMyInvestmentCalculatorFormComponent } from "./user-my-investment-calculator-form/user-my-investment-calculator-form.component";
import { UserMyInvestmentWishlistComponent } from "./user-my-investment-wishlist/user-my-investment-wishlist.component";
import { ModalSelectPaymentMethodFormComponent } from "./modal-select-payment-method-form/modal-select-payment-method-form.component";
import { ModalFindUserFormComponent } from "./modal-find-user-form/modal-find-user-form.component";
import { ModalContactFormComponent } from "./modal-contact-form/modal-contact-form.component";
import { SliderPropertiesComponent } from "./sliders/slider-properties/slider-properties.component";
import { LoadingComponent } from "./loading/loading.component";

@NgModule({
  declarations: [
    HeaderComponent,
    SliderGrowYourInvestmentComponent,
    PropertiesItemsCardComponent,
    FormProjectYourInvestmentComponent,
    FormProjectYourInvestmentUserComponent,
    SliderNewsComponent,
    FormContactComponent,
    FooterComponent,
    SliderPropertyImgComponent,
    AdminLayoutComponent,
    HeaderAdminComponent,
    SidebarAdminComponent,
    NotAccessComponent,
    InputFileSingleButtonComponent,
    FormBuyPropertieComponent,
    FormRegisterUserComponent,
    FormLoginUserComponent,
    InputSingleImageComponent,
    FormProfileComponent,
    CalculatorComponent,
    ModalToppayFormComponent,
    UserMyInvestmentCalculatorFormComponent,
    UserMyInvestmentWishlistComponent,
    ModalSelectPaymentMethodFormComponent,
    ModalFindUserFormComponent,
    ModalContactFormComponent,
    SliderPropertiesComponent,
    LoadingComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    RouterModule,
    ClipboardModule,
    TranslateModule,
    SwiperModule,
    DirectivesModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    RouterModule,
    ClipboardModule,
    TranslateModule,
    HeaderComponent,
    SliderGrowYourInvestmentComponent,
    PropertiesItemsCardComponent,
    FormProjectYourInvestmentComponent,
    FormProjectYourInvestmentUserComponent,
    SliderNewsComponent,
    FormContactComponent,
    FooterComponent,
    SliderPropertyImgComponent,
    AdminLayoutComponent,
    HeaderAdminComponent,
    SidebarAdminComponent,
    NotAccessComponent,
    InputFileSingleButtonComponent,
    FormBuyPropertieComponent,
    FormRegisterUserComponent,
    FormLoginUserComponent,
    InputSingleImageComponent,
    FormProfileComponent,
    CalculatorComponent,
    ModalToppayFormComponent,
    UserMyInvestmentCalculatorFormComponent,
    UserMyInvestmentWishlistComponent,
    ModalSelectPaymentMethodFormComponent,
    ModalFindUserFormComponent,
    ModalContactFormComponent,
    // FormBuyBankComponent,
    SliderPropertiesComponent,
    LoadingComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
