<div class="row mt-5">
  <div class="col-12 mb-5">
    <div
      class="card-dashboard-user-white"
      [innerHTML]="'general.projectionOfMyInvestment' | translate"></div>
  </div>

  <div class="col-12">
    <app-dashboard-user-my-investment-form></app-dashboard-user-my-investment-form>
  </div>
</div>
