import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  Subject,
  Subscription,
  catchError,
  combineLatest,
  from,
  map,
  of,
  startWith,
  tap,
} from "rxjs";
import { BsModalService } from "src/app/services/bs-modal.service";
import { Web3Service } from "src/app/services/contract/web3.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-modal-select-payment-method-form",
  templateUrl: "./modal-select-payment-method-form.component.html",
  styleUrls: ["./modal-select-payment-method-form.component.css"],
})
export class ModalSelectPaymentMethodFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Output() public onSubmitEvent = new Subject<any>();

  public mi: any;

  public mId = "modalSelectPaymentMethodForm";

  public whitelist: any;

  public pair: any;

  public walletBuy: any;

  public nativeCurrency = environment.chain.nativeCurrency;

  public form!: FormGroup; // variable de formulario de compra

  public vm: any = {
    whiteListToken: [
      { type: "required", message: "form.validations.isRequired" },
    ],
  };

  public submitted = false;

  public isProduction = environment.production;

  private sub$!: Subscription;

  constructor(
    private fb: FormBuilder,
    private bsModalSrv: BsModalService,
    private web3Srv: Web3Service
  ) {
    /** Construir formulario */
    this.buildForm();
  }

  ngOnInit(): void {
    /** Observable de wallet */
    const walletStatus$ = this.web3Srv.accountStatus$.pipe(
      startWith([]),
      map((data: any[]) => (data.length > 0 ? data[0] : null)),
      catchError((err) => of(null))
    );

    /** Observable de tokens */
    const tokens$ = from(this.web3Srv.vendor_whitelist_tokensListoc()).pipe(
      map((data: any[]) =>
        data
          .map((item: any, index: number) => ({ ...item, cId: index }))
          .filter((item: any) => item.active)
      ),
      catchError((err) => of([]))
      // map((data: any[]) => ({tokens: data}))
    );

    this.sub$ = combineLatest([walletStatus$, tokens$])
      .pipe(
        map(([walletStatus, whitelist]) => ({ walletStatus, whitelist })),
        tap(console.log)
      )
      .subscribe((vm: any) => {
        /** Si el formulario permanece desactivado - Activar */
        if (this.form.disabled) {
          this.form.enable();
        }

        /** Cargar listado de tokens de la whitelist */
        this.whitelist = vm.whitelist;

        /** Cargar wallet */
        this.walletBuy = vm.walletStatus;
      });
  }

  ngAfterViewInit() {
    this.buildModal();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  buildModal() {
    this.mi = this.bsModalSrv.buildModal(this.mId, {});
  }

  openModal() {
    this.mi.show();
  }

  closeModal(data = null, role = "cancel") {
    this.mi.hide();
    this.onSubmitEvent.next({ data, role });
  }

  buildForm() {
    const whiteListToken = this.isProduction ? "topPay" : "";
    this.form = this.fb.group({
      whiteListToken: [whiteListToken, [Validators.required]],
    });

    this.form.disable();
  }

  get f() {
    return this.form.controls;
  }

  onChangeWhitelist() {
    const token = this.form.value.whiteListToken;
    console.log(token);

    this.pair =
      token !== "stripe"
        ? this.whitelist.find((item: any) => item.addr === token)
        : "stripe";
  }

  launchOpenConnection() {
    this.web3Srv.launchAskConnectionType();
  }

  onSubmit() {
    this.submitted = true;
    const formData = this.form.value;

    if (!this.form.valid) {
      return;
    }

    const find =
      formData.whiteListToken !== "stripe"
        ? this.whitelist.find(
            (item: any) => item.addr === formData.whiteListToken
          )
        : null;

    this.closeModal(
      { ...formData, metadata: { ...find, walletBuy: this.walletBuy } },
      "accept"
    );
  }
}
