import { Injectable } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { finalize } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MediaFileService {
  /** Indicador de progreso de carga */
  public uploadProgress = 0;

  constructor(private storage: AngularFireStorage) {}

  /**
   * Seteo de valor de carga de archivo
   * @param i
   * @returns
   */
  setPercentBar(i: any) {
    let apc = i / 100;
    console.warn("setPercentBar", apc);
    return apc;
  }

  /**
   * Obtener base64 de archivo
   * @param event
   * @returns
   */
  getBase64(event: any) {
    // if (!event) {
    //   return;
    // }
    return new Promise(async (resolve, reject) => {
      const file = event;
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.onprogress = (pross) => {
        // console.log(pross);
      };

      reader.readAsDataURL(file);
    });
  }

  /**
   * Cargar archivo en directorio especifico
   * @param imageURI
   * @param folder
   * @param ext
   * @returns
   */
  uploadFileWithCustomFoler(
    imageURI: any,
    folder = "multimedia/investalk",
    ext: any,
    opts: any = {}
  ) {
    const { format = "data_url", contentType = `image/${ext}` } = opts;

    return new Promise(async (resolve, reject) => {
      try {
        const ramdom = Math.floor(Math.random() * 1000000 * 5);
        const filePath = `${folder}/${new Date().getTime()}_${ramdom}.${ext}`;
        const fileRef = this.storage.ref(filePath);

        const task = fileRef.putString(imageURI, format, { contentType });

        task.percentageChanges().subscribe((res) => {
          this.uploadProgress = this.setPercentBar(res);
        });

        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe((url) => {
                this.uploadProgress = 0;
                resolve(url);
              });
            })
          )
          .subscribe();
      } catch (err) {
        reject(err);
      }
    });
  }

  async uploadFileDocumentIntoRoute(filePath: string, file: File) {
    return new Promise(async (resolve, reject) => {
      try {
        /** Crear Referencia en el Bucket */
        const fileRef = this.storage.ref(filePath);

        /** Crear tarea para subir archivp */
        const task = this.storage.upload(filePath, file);

        /**
         * Ejecutar tarea y esperar respuesta
         * - Retorna la URL del archivo subido
         */
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe((url) => {
                let uploadProgress = 0;
                resolve(url);
              });
            })
          )
          .subscribe();
      } catch (err) {
        return reject(err);
      }
    });
  }

  /**
   * Remover archivo por URL
   * @param url       URL del archivo
   * @returns
   */
  async removeByURL(url: string): Promise<boolean> {
    await this.storage.storage.refFromURL(url).delete();
    return true;
  }
}
