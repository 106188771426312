<form [formGroup]="form" novalidate autocomplete="off">
  <div class="row align-items-center">
    <!-- Input Email -->
    <div class="col-12 mb-3 position-relative">
      <input
        type="text"
        class="form-control user"
        aria-label="email"
        formControlName="email"
        [placeholder]="'form.email' | translate" />
      <div class="icon-view">
        <img src="assets/img/user-icon-line.png" />
      </div>
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'email' }"></ng-container>
    </div>

    <!-- Input Password -->
    <div class="col-12 position-relative mb-3">
      <input
        [type]="showPassword ? 'text' : 'password'"
        class="form-control mb-0"
        aria-label="password"
        formControlName="password"
        [placeholder]="'form.password' | translate" />
      <ng-container
        [ngTemplateOutlet]="renderShowValueBtn"
        [ngTemplateOutletContext]="{
          field: 'repeatpassword',
          id: 'login-password'
        }"></ng-container>
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'password' }"></ng-container>
    </div>

    <div class="col-12">
      <div class="row">
        <div class="col-12 col-lg-6">
          <label class="container-check">
            {{ "form.rememberMe" | translate }}
            <input type="checkbox" />
            <span class="checkmark"></span>
          </label>
        </div>
        <div
          class="col-12 col-lg-6 d-flex justify-content-center justify-content-md-end">
          <a
            class="text-yellow mt-1 text-center mb-3 mb-md-0"
            routerLink="/recovery-password"
            [innerHTML]="'form.forgotYourPassword?' | translate">
          </a>
        </div>
      </div>
    </div>

    <div class="col-12 mt-3">
      <ng-container *ngIf="!loading; else renderLoadingBtn">
        <button type="submit" class="btn btn-main" (click)="onSubmit()">
          <span [innerHTML]="'form.enter' | translate"> </span>
        </button>
      </ng-container>
    </div>

    <div class="col-12 mt-4">
      <span
        class="text-yellow mt-1"
        [innerHTML]="'form.orSignInWith' | translate"></span>
      <div class="d-flex my-1">
        <button class="btn btn-login-social me-2">
          <img src="assets/img/google-icon.png" class="img-fluid" alt="" />
        </button>
        <button class="btn btn-login-social me-2">
          <img src="assets/img/facebook-icon.png" class="img-fluid" alt="" />
        </button>
        <button class="btn btn-login-social me-2">
          <img src="assets/img/apple-icon.png" class="img-fluid" alt="" />
        </button>
      </div>

      <div>
        <span
          class="text-yellow me-1"
          [innerHTML]="'form.dontHaveAnAccount?' | translate">
        </span>
        <a
          class="text-white"
          [routerLink]="'/register'"
          [innerHTML]="'general.signUp' | translate">
        </a>
      </div>
    </div>
  </div>
</form>

<ng-template #renderLoadingBtn>
  <button type="submit" class="btn btn-main" disabled>Procesando...</button>
</ng-template>

<ng-template #renderShowValueBtn let-field="field" let-id="id">
  <div
    class="password-view"
    [ngClass]="{ active: !showPassword }"
    [id]="id"
    (click)="showValue()">
    <img src="assets/img/eye-icon.png" class="eye" />
    <img src="assets/img/hidden-eye-icon.png" class="eye-hidden" />
  </div>
</ng-template>

<ng-template #renderVM let-field="field">
  <div class="mt-1 mx-2 mb-3" *ngIf="submit">
    <ng-container *ngFor="let val of vm[field]">
      <span class="text-danger" *ngIf="f[field].hasError(val.type)">
        {{ val.message | translate }}
      </span>
    </ng-container>
  </div>
</ng-template>
