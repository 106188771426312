import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/admin-layout/admin-layout.component";
import { DashboardUserLayoutComponent } from "./dashboard-user/components/dashboard-user-layout/dashboard-user-layout.component";
import { SecurePasswordComponent } from "./pages/secure-password/secure-password.component";
import { AuthGuard } from "./guards/auth.guard";
import { RegisterComponent } from "./pages/register/register.component";
import { LoginComponent } from "./pages/login/login.component";
const routes: Routes = [
  // {
  //     path: 'pages',
  //     loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  // },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "property",
    loadChildren: () =>
      import("./pages/property/property.module").then((m) => m.PropertyModule),
  },
  {
    path: "about-us",
    loadChildren: () =>
      import("./pages/about-us/about-us.module").then((m) => m.AboutUsModule),
  },
  {
    path: "admin",
    component: AdminLayoutComponent,
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
  },
  // {
  //   path: "dashboard-user/kyc",
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import("./modules/kyc/kyc.module").then((m) => m.KycModule),
  // },
  {
    path: "dashboard-user",
    component: DashboardUserLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import("./dashboard-user/dashboard-user.module").then((m) => m.DashboardUserModule),
  },
  {
    path: "register",
    component: RegisterComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "recovery-password",
    component: SecurePasswordComponent,
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/home",
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "/home",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
