import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";

@Component({
  selector: "app-form-login-user",
  templateUrl: "./form-login-user.component.html",
  styleUrls: ["./form-login-user.component.css"],
})
export class FormLoginUserComponent implements OnInit {
  @ViewChild("closeModal") closeModal!: ElementRef;
  public form!: FormGroup;
  public vm: any = {
    email: [
      { type: "required", message: "form.validations.isRequired" },
      { type: "pattern", message: "form.validations.email" },
    ],
    password: [{ type: "required", message: "form.validations.isRequired" }],
  };

  public submit = false;
  public showPassword = false;
  public loading: boolean = false;

  constructor(
    public fb: FormBuilder,
    private authSrv: AuthenticationService,
    private sweetAlert2Srv: Sweetalert2Service,
    private router: Router
  ) {
    this.buildForm();
  }

  ngOnInit() {}

  showValue() {
    this.showPassword = !this.showPassword;
  }

  mostrarContrasena(eyes: any, input: any) {
    let type: any = document.getElementById(input);
    let hiddenEyes: any = document.getElementById(eyes);

    if (type.type == "password") {
      type.type = "text";
      hiddenEyes.classList.add("active");
    } else {
      type.type = "password";
      hiddenEyes.classList.remove("active");
    }
  }

  get f() {
    return this.form.controls;
  }

  /**
   * Construir formulario
   */
  buildForm() {
    this.form = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      password: ["", [Validators.required]],
    });
  }

  /**
   * Al enviar formulario
   * @returns
   */
  async onSubmit() {
    try {
      this.submit = true;
      this.loading = true;
      const formData = this.form.value;
      // console.log("formData", formData);

      if (!this.form.valid) {
        this.form.markAllAsTouched();
        return;
      }

      const email = formData.email.trim().toLowerCase();
      const password = formData.password.trim();

      const result: any = await this.authSrv.signInWithEmail(email, password);

      /** Capturar UID del usuario */
      const uid = result.user.uid;

      /** Guardar identificador del usuario en el localStorage */
      this.authSrv.setLocalUID(uid);

      /** Obtener documento de usuario */
      const userDoc = await this.authSrv.getByUID(uid);

      const toParse = {
        email: userDoc.email,
        name: userDoc.name,
        avatar: userDoc.avatar,
        phoneNumber: userDoc.phoneNumber,
        wallet: userDoc.walletAddress,
      };

      /** Guardar documento de usuario en el localStorage */
      localStorage.setItem("profile", JSON.stringify(toParse));

      this.router.navigate(["/dashboard-user/home"]);
      //cerrar modal
      // this.closeModal.nativeElement.click();

      return;
    } catch (err: any) {
      console.log("Error on SignInComponent.submitSigInData", err);
      if (err.code === "auth/internal-error") {
        await this.sweetAlert2Srv.showError(
          // "la contraseña no es válida o el usuario no tiene una contraseña"
          "form.validations.incorrectUserNameAnd/OrPassword"
        );
      }

      if (err.code === "auth/user-not-found") {
        await this.sweetAlert2Srv.showError(
          // "No hay registro de usuario correspondiente a este identificador. El usuario puede haber sido eliminado."
          "form.validations.incorrectUserNameAnd/OrPassword"
        );
      }
      return;
    } finally {
      this.loading = false;
    }
  }

  async recoveryPassword() {
    // this.closeModal.nativeElement.click();
    this.router.navigate(["/recovery-password"]);
  }
}
