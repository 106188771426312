<router-outlet></router-outlet>
<div #loader>
  <app-loading />
</div>
<!--spinner-->
<ngx-spinner size="medium" type="triangle-skew-spin" [fullScreen]="true">
  <p style="color: white">
    {{ "general.loading" | translate }}
  </p>
</ngx-spinner>

<div class="btn-ws">
  <a
    href="https://api.whatsapp.com/send?phone=17864380031&text=Hola%20Investok!%20%0A%0AEstoy%20en%20la%20p%C3%A1gina%20y%20me%20gustar%C3%ADa%20ampliar%20la%20informaci%C3%B3n"
    target="_blank">
    <img class="img-fluid mb-2" src="assets/img/icon-whatsapp.svg" alt="" />
  </a>
</div>
