import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { AbiService } from "./services/contract/abi.service";
import { Web3Service } from "./services/contract/web3.service";
import * as AOS from "aos";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild("loader") loader!: ElementRef;
  authenticated: boolean = false;
  data: string[] | undefined;

  constructor(private web3: Web3Service, private abiSrv: AbiService) {
    console.log("MAIN:", environment.chain.chainId);
    console.log("MAIN:", environment.chain.rpc);

    // window.onload = () => {
    //   AOS.init();
    //   window.addEventListener("load", AOS.refresh);
    //   console.log("---  window loaded ---");
    //   this.loader.nativeElement.classList.add("d-none");
    // };
  }

  ngOnInit(): void {
    // this.parseABI();
  }

  ngAfterViewInit(): void {
    // Check if document is already loaded
    if (document.readyState === "complete") {
      this.initializePage();
    } else {
      window.addEventListener("load", () => {
        this.initializePage();
      });
    }
  }

  initializePage(): void {
    AOS.init();
    AOS.refresh();
    // console.log('---  window loaded ---');
    if (this.loader && this.loader.nativeElement) {
      this.loader.nativeElement.classList.add("d-none");
    }
  }

  async parseABI() {
    const r = await this.abiSrv.parseABI(
      "/assets/abi/Investok_SaleDistribution.json"
    );
    // const r = await this.abiSrv.parseABI(this.web3.distributionABI);
    console.log(r);
  }
}
