import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardUserComponent } from "./dashboard-user.component";
import { DashboardUserLayoutComponent } from "./components/dashboard-user-layout/dashboard-user-layout.component";
import { PipesModule } from "../pipes/pipes.module";
import { SharedModule } from "../shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DashboardUserSidebarComponent } from "./components/dashboard-user-sidebar/dashboard-user-sidebar.component";
import { DashboardUserCardInformationComponent } from "./components/dashboard-user-card-information/dashboard-user-card-information.component";
import { DashboardUserRoutingModule } from "./dashboard-user-routing.module";
import { DashboardUserMyInvestmentComponent } from "./pages/dashboard-user-my-investment/dashboard-user-my-investment.component";
import { DashboardUserPortfolioComponent } from "./pages/dashboard-user-portfolio/dashboard-user-portfolio.component";
import { DashboardUserInvestalkComponent } from "./pages/dashboard-user-investalk/dashboard-user-investalk.component";
import { DashboardUserProfileComponent } from "./pages/dashboard-user-profile/dashboard-user-profile.component";
import { ProfileRegisterWalletFormComponent } from "./components/profile-register-wallet-form/profile-register-wallet-form.component";
import { ProfileRegisterReferralCodeFormComponent } from "./components/profile-register-referral-code-form/profile-register-referral-code-form.component";
import { DashboardUserMyInvestmentFormComponent } from "./components/dashboard-user-my-investment-form/dashboard-user-my-investment-form.component";
import { ProfileVerificationListComponent } from './components/profile-verification-list/profile-verification-list.component';
import { ProfileRequestWalletCustodioInfoFormComponent } from './components/profile-request-wallet-custodio-info-form/profile-request-wallet-custodio-info-form.component';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    DashboardUserRoutingModule,
  ],
  declarations: [
    DashboardUserComponent,
    DashboardUserLayoutComponent,
    DashboardUserSidebarComponent,
    DashboardUserPortfolioComponent,
    DashboardUserCardInformationComponent,
    DashboardUserMyInvestmentComponent,
    DashboardUserInvestalkComponent,
    DashboardUserProfileComponent,
    ProfileRegisterWalletFormComponent,
    ProfileRegisterReferralCodeFormComponent,
    DashboardUserMyInvestmentFormComponent,
    ProfileVerificationListComponent,
    ProfileRequestWalletCustodioInfoFormComponent,
  ],
})
export class DashboardUserModule {}
