import { Component, OnInit, ViewChild } from "@angular/core";
import { SwiperComponent } from "swiper/angular";

import SwiperCore, {
  Swiper,
  Virtual,
  SwiperOptions,
  Autoplay,
  Pagination,
} from "swiper";

// install Swiper modules
SwiperCore.use([Virtual, Autoplay, Pagination]);

@Component({
  selector: "app-slider-grow-your-investment",
  templateUrl: "./slider-grow-your-investment.component.html",
  styleUrls: ["./slider-grow-your-investment.component.css"],
})
export class SliderGrowYourInvestmentComponent implements OnInit {
  @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;

  config: SwiperOptions = {
    slidesPerView: 2,
    spaceBetween: 10,
    loop: true,
    pagination: {
      el: ".swiper-pagination",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  };

  public investments = [
    {
      img: "assets/img/investment-img-1.png",
      number: "1.",
      text: "Registrarse y completar la verificación KYC.",
    },
    {
      img: "assets/img/investment-img-2.png",
      number: "2.",
      text: "Seleccione una propiedad y la cantidad de fracciones.",
    },
    {
      img: "assets/img/investment-img-3.png",
      number: "3.",
      text: "Seleccione el método de pago.",
    },
    {
      img: "assets/img/investment-img-1.png",
      number: "1.",
      text: "Registrarse y completar la verificación KYC.",
    },
    {
      img: "assets/img/investment-img-2.png",
      number: "2.",
      text: "Seleccione una propiedad y la cantidad de fracciones.",
    },
    {
      img: "assets/img/investment-img-3.png",
      number: "3.",
      text: "Seleccione el método de pago.",
    },
  ];

  constructor() {}

  ngOnInit() {}

  slideNext() {
    this.swiper?.swiperRef.slideNext(100);
  }
  slidePrev() {
    this.swiper?.swiperRef.slidePrev(100);
  }
}
