<div class="position-relative w-100 d-flex px-3">
  <swiper class="swiper mySwiper position-relative" #swiper [config]="config">
    <ng-template swiperSlide *ngFor="let item of investalkList">
      <div class="swiper-slide-new">
        <div class="new-container container">
          <div class="row justify-content-center g-0">
            <div class="col-12 col-md-5">
              <div class="new-container__img">
                <img [src]="item?.img" class="img-fluid" alt="" />
              </div>
            </div>

            <div class="col-1 d-flex justify-content-center">
              <div class="--line"></div>
            </div>
            <div class="col-12 col-md-5">
              <div class="new-container__contents">
                <h4 class="title-custom">{{ item?.title }}</h4>
                <p>
                  {{ item?.description | truncate : 160 }}
                </p>
                <a class="btn btn-new-d" target="_blank" [href]="item?.link">
                  SUSCRÍBETE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide *ngIf="investalkList?.length < 1"> </ng-template>
    <ng-template swiperSlide *ngIf="investalkList?.length < 2"> </ng-template>
    <ng-template swiperSlide *ngIf="investalkList?.length < 3"> </ng-template>

    <!-- <ng-template swiperSlide *ngIf="investalkList.length < 3"> </ng-template>
    <ng-template swiperSlide *ngIf="investalkList.length < 2"> </ng-template>
    <ng-template swiperSlide *ngIf="investalkList.length < 1"> </ng-template> -->
  </swiper>

  <div class="slider-footer" *ngIf="investalkList?.length >= 4">
    <div class="slider-arrows">
      <div class="swiper-button-prev" (click)="slidePrev()">
        <img
          src="assets/img/icon-arrow-gold-right.png"
          class="grey-arrow"
          alt="flecha" />
      </div>
      <div class="swiper-button-next" (click)="slideNext()">
        <img
          src="assets/img/icon-arrow-gold-left.png"
          class="grey-arrow"
          alt="flecha" />
      </div>
    </div>

    <!-- <div class="swiper-pagination"></div> -->
  </div>

  <div class="slider-footer d-flex d-xl-none">
    <div class="slider-arrows">
      <div class="swiper-button-prev" (click)="slidePrev()">
        <img
          src="assets/img/icon-arrow-gold-right.png"
          class="grey-arrow"
          alt="flecha" />
      </div>
      <div class="swiper-button-next" (click)="slideNext()">
        <img
          src="assets/img/icon-arrow-gold-left.png"
          class="grey-arrow"
          alt="flecha" />
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <tr *ngFor="let item of [1, 1, 1, 1]">
    <th scope="row" colspan="7" class="placeholder-glow">
      <span class="placeholder placeholder-lg col-12"></span>
    </th>
  </tr>
</ng-template>
