<app-header-admin></app-header-admin>

<div class="w-100">
  <ng-container
    *ngIf="dataStatus$ | async as dataStatus; else noConnected"
    [ngTemplateOutlet]="renderLayoutValidation"
    [ngTemplateOutletContext]="{ data: dataStatus }"
  ></ng-container>
</div>

<ng-template #renderLayoutValidation let-data="data">
  <ng-container *ngIf="data.access; else noStaff">
    <app-sidebar-admin></app-sidebar-admin>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </ng-container>
</ng-template>

<ng-template #noConnected>
  <div class="row mt-5">
    <div class="col-12 mt-5">
      <div class="text-center">
        <h1>Bienvenido al panel administrativo de Investok</h1>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noStaff>
  <div class="row">
    <div class="col-12 mt-5">
      <app-not-access></app-not-access>
    </div>
  </div>
</ng-template>
