<div class="position-relative w-100 d-flex px-3">
  <swiper
    class="swiper mySwiper-properties position-relative"
    #swiper
    [config]="config"
    effect="coverflow">
    <ng-container *ngIf="propertiesList; else loading">
      <ng-template *ngFor="let propertie of propertiesList" swiperSlide>
        <ng-container
          [ngTemplateOutlet]="
            propertie.status !== 'preview'
              ? renderRegularCardItem
              : renderDraftCardItem
          "
          [ngTemplateOutletContext]="{ item: propertie }"></ng-container>
      </ng-template>
      <!-- 
      <ng-template swiperSlide *ngIf="propertiesList.length < 1"> </ng-template>
      <ng-template swiperSlide *ngIf="propertiesList.length < 2"> </ng-template>
      <ng-template swiperSlide *ngIf="propertiesList.length < 3"> </ng-template> -->
    </ng-container>

    <!-- <ng-template swiperSlide *ngIf="investalkList.length < 3"> </ng-template>
    <ng-template swiperSlide *ngIf="investalkList.length < 2"> </ng-template>
    <ng-template swiperSlide *ngIf="investalkList.length < 1"> </ng-template> -->
  </swiper>

  <div class="slider-footer">
    <div class="slider-arrows">
      <div class="swiper-button-prev" (click)="slidePrev()">
        <img
          src="assets/img/arrow-left-dorado.png"
          class="grey-arrow"
          alt="flecha" />
      </div>
      <div class="swiper-button-next" (click)="slideNext()">
        <img
          src="assets/img/arrow-right-dorado.png"
          class="grey-arrow"
          alt="flecha" />
      </div>
    </div>

    <!-- <div class="swiper-pagination"></div> -->
  </div>

  <div class="slider-footer d-flex d-xl-none">
    <div class="slider-arrows">
      <div class="swiper-button-prev" (click)="slidePrev()">
        <img
          src="assets/img/arrow-left-dorado.png"
          class="grey-arrow"
          alt="flecha" />
      </div>
      <div class="swiper-button-next" (click)="slideNext()">
        <img
          src="assets/img/arrow-right-dorado.png"
          class="grey-arrow"
          alt="flecha" />
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <tr *ngFor="let item of [1, 1, 1, 1]">
    <th scope="row" colspan="7" class="placeholder-glow">
      <span class="placeholder placeholder-lg col-12"></span>
    </th>
  </tr>
</ng-template>

<ng-template #renderRegularCardItem let-item="item">
  <div class="swiper-slide-properties --coming">
    <div class="card-propertie-new">
      <div class="card-propertie-new__img">
        <img
          *ngIf="!item.gallery[0]"
          src="assets/img/properties-img.png"
          class="img-fluid"
          alt="" />
        <img
          *ngIf="item.gallery[0].type != 'video/mp4'"
          src="{{ item.gallery[0].url }}"
          class="img-fluid"
          alt="" />

        <video
          controls
          *ngIf="item.gallery[0].type == 'video/mp4'"
          [src]="item.gallery[0].url"
          class="img-fluid rounded galleryaMedia"></video>
      </div>
      <div class="card-propertie-new__body">
        <div class="card-propertie-new__texts">
          <p>{{ item.projectName | uppercase }}</p>
          <span> {{ item.address }}</span>
          <a
            [routerLink]="['/property', item.addr, 'details']"
            class="text-capitalize">
            {{ "general.seeMore" | translate }}
            <img
              class="img-fluid"
              src="assets/img/arrow-right-blue-mini.png"
              alt="" />
          </a>
        </div>

        <div class="card-propertie-new__percents">
          <div class="card-propertie-new__percents__item">
            <div class="card-propertie-new__percents__item__icon">
              <img
                class="img-fluid"
                src="assets/img/arrow-up-propertie.png"
                alt="arrow up" />
            </div>
            <div class="card-propertie-new__percents__item__text">
              <p [innerHTML]="'projects.annualReturn' | translate"></p>

              <div class="--estimate">
                <p>
                  <b>{{ item.estimatedProfitability }}%</b>
                  {{ "projects.estimated" | translate }}
                </p>
                <span [innerHTML]="'projects.rent+Valorization' | translate">
                </span>
              </div>
            </div>
          </div>

          <div class="card-propertie-new__percents__item">
            <div class="card-propertie-new__percents__item__icon">
              <img
                class="img-fluid"
                src="assets/img/arrow-up-propertie.png"
                alt="arrow up" />
            </div>
            <div class="card-propertie-new__percents__item__text">
              <p [innerHTML]="'projects.totalProfitability' | translate"></p>

              <div class="--estimate">
                <p>
                  <b>{{ item.estimatedProfitability * 5 }}%</b>
                  {{ "projects.estimated5Years" | translate }}
                </p>
                <span [innerHTML]="'projects.rent+Valorization' | translate">
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100">
          <button
            type="button"
            class="btn btn-transparent"
            (click)="openBuyForm($event.target, item.addr)"
            [innerHTML]="'general.buy' | translate"></button>

          <div class="form-content" id="{{ item.addr }}">
            <app-form-buy-propertie
              [dataId]="item.addr"
              [bg]="'black'"></app-form-buy-propertie>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #renderDraftCardItem let-item="item">
  <div class="card-propertie-new --coming">
    <div class="card-propertie-new__img">
      <p>COMING SOON</p>
      <img
        *ngIf="!item.gallery[0]"
        src="assets/img/properties-img.png"
        class="img-fluid"
        alt="" />
      <img
        *ngIf="item.gallery[0].type != 'video/mp4'"
        src="{{ item.gallery[0].url }}"
        class="img-fluid"
        alt="" />

      <video
        controls
        *ngIf="item.gallery[0].type == 'video/mp4'"
        [src]="item.gallery[0].url"
        class="img-fluid rounded galleryaMedia"></video>
    </div>
  </div>
</ng-template>
