import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { SwiperComponent } from "swiper/angular";

import SwiperCore, {
  Swiper,
  Virtual,
  SwiperOptions,
  Autoplay,
  Pagination,
} from "swiper";

// install Swiper modules
SwiperCore.use([Virtual, Autoplay, Pagination]);

@Component({
  selector: "app-slider-property-img",
  templateUrl: "./slider-property-img.component.html",
  styleUrls: ["./slider-property-img.component.css"],
})
export class SliderPropertyImgComponent implements OnInit {

  @Input() gallery!: any[];

  @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;

  public propertyImg = [
    { img: "assets/img/property-img-slider.png" },
    { img: "assets/img/property-img-slider.png" },
    { img: "assets/img/property-img-slider.png" },
  ];

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: {
      el: ".swiper-pagination",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1199: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  };

  constructor() {}

  ngOnInit() {
    this.gallery;
  }

  slideNext() {
    this.swiper?.swiperRef.slideNext(100);
  }
  slidePrev() {
    this.swiper?.swiperRef.slidePrev(100);
  }

}
