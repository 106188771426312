<div class="sidebar">
  <div class="sidebar-profile">
    <img
      class="sidebar-profile-img"
      src="./assets/img/logo-una-letra-orange.png"
      alt="Admin" />
    <p class="sidebar-profile-name mb-0">Admin</p>
  </div>

  <a [routerLink]="'/admin/users'" [routerLinkActive]="'active'">
    <img src="assets/img/user-icon-orange.png" class="icon orange-icon" />
    <img src="assets/img/user-icon-white.png" class="icon white-icon" />

    {{ "general.users" | translate }}
  </a>

  <a routerLink="/admin/payments-due" routerLinkActive="active">
    <img src="assets/img/payment-due-orange.png" class="icon orange-icon" />
    <img src="assets/img/payment-due-white.png" class="icon white-icon" />
    {{ "general.outstandingPayments" | translate }}
  </a>

  <a [routerLink]="'/admin/white-list-token'" [routerLinkActive]="'active'">
    <img
      src="assets/img/admin-icon-crypto-orange.png"
      class="icon orange-icon" />
    <img src="assets/img/admin-icon-crypto-white.png" class="icon white-icon" />

    {{ "general.tokenToReceive" | translate }}
  </a>

  <a [routerLink]="'/admin/collection'" [routerLinkActive]="'active'">
    <img
      src="assets/img/admin-icon-property-public-orange.png"
      class="icon orange-icon" />
    <img
      src="assets/img/admin-icon-property-public-white.png"
      class="icon white-icon" />
    Proyectos
  </a>
  <a [routerLink]="'/admin/investalk'" [routerLinkActive]="'active'">
    <img
      src="assets/img/chat-icon-admin-orange.png"
      class="icon orange-icon"
      width="32" />
    <img
      src="assets/img/chat-icon-admin-white.png"
      class="icon white-icon"
      width="32px" />
    InvesTalk
  </a>

  <a [routerLink]="'/admin/withdrawals'" [routerLinkActive]="'active'">
    <img
      src="./assets/img/admin-icon-retiro-orange.png"
      class="icon orange-icon" />
    <img src="assets/img/admin-icon-retiro-white.png" class="icon white-icon" />
    Retiros
  </a>
  <a [routerLink]="'/admin/newsletter'" [routerLinkActive]="'active'">
    <img
      src="./assets/img/admin-icon-newsletter-orange.png"
      class="icon orange-icon" />
    <img
      src="assets/img/admin-icon-newsletter-white.png"
      class="icon white-icon" />
    Newsletter
  </a>
  <a [routerLink]="'/admin/utilities'" [routerLinkActive]="'active'">
    <img src="./assets/img/utility-orange.svg" class="icon orange-icon" />
    <img src="assets/img/utility-white.svg" class="icon white-icon" />
    Utilidades
  </a>
</div>
