<div class="secure-container">
  <div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card recovery border-0 rounded-3 p-3 p-lg-4">
          <div class="position-relative">
            <div class="back" routerLink="/pages/home">
              <img src="assets/img/down-arrow-orange.png" alt="" />
            </div>
            <h2 class="card-title text-center mb-5 fs-5">
              <b [innerHTML]="'recoverPassword.title' | translate"> </b>
            </h2>

            <form [formGroup]="form">
              <ng-container *ngIf="formStatus == 1">
                <label
                  class="text-yellow mb-3"
                  for="floatingEmailInput"
                  [innerHTML]="
                    'recoverPassword.EnterYourRegisteredEmailAddress'
                      | translate
                  ">
                </label>
                <input
                  type="email"
                  [ngClass]="{ valid: submit && f['email'].errors }"
                  formControlName="email"
                  class="form-control mb-3"
                  id="floatingEmailInput" />
                <ng-container *ngIf="submit">
                  <ng-container *ngFor="let item of vm.email">
                    <span
                      class="badge bg-danger"
                      *ngIf="f['email'].hasError(item.type)">
                      {{ item.message | translate }}
                    </span>
                  </ng-container>
                </ng-container>

                <div class="mb-4">
                  <label for="email" class="form-label">
                    <span class="text-danger">*</span>
                    {{ "recoverPassword.sendVerificationCode" | translate }}
                  </label>
                  <div
                    class="d-flex justify-content-center flex-column align-items-center my-2">
                    <a
                      href="javascript:void(0)"
                      class="btn btn-main w-100"
                      (click)="sendCode($event)"
                      [innerHTML]="'general.send' | translate">
                    </a>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="formStatus == 2">
                <div class="mb-3" *ngIf="formStatus == 2">
                  <label
                    class="text-yellow"
                    for="floatingPasswordInput"
                    [innerHTML]="'form.password' | translate">
                  </label>
                  <input
                    type="password"
                    [ngClass]="{ valid: submit && f['password'].errors }"
                    formControlName="password"
                    class="form-control"
                    id="floatingPasswordInput"
                    [placeholder]="'form.password' | translate" />

                  <ng-container *ngIf="submit">
                    <ng-container *ngFor="let item of vm.password">
                      <span
                        class="badge bg-danger"
                        *ngIf="f['password'].hasError(item.type)">
                        {{ item.message | translate }}
                      </span>
                    </ng-container>
                  </ng-container>
                </div>

                <div class="mb-3" *ngIf="formStatus == 2">
                  <label
                    class="text-yellow"
                    for="floatingConfirmPasswordInput"
                    [innerHTML]="'recoverPassword.repeatPassword' | translate">
                  </label>
                  <input
                    type="password"
                    [ngClass]="{ valid: submit && f['confirmPassword'].errors }"
                    formControlName="confirmPassword"
                    class="form-control"
                    id="floatingConfirmPasswordInput"
                    [placeholder]="
                      'recoverPassword.repeatPassword' | translate
                    " />

                  <ng-container *ngIf="submit">
                    <ng-container *ngFor="let item of vm.confirmPassword">
                      <span
                        class="badge bg-danger"
                        *ngIf="f['confirmPassword'].hasError(item.type)">
                        {{ item.message | translate }}
                      </span>
                    </ng-container>
                  </ng-container>
                </div>

                <div class="mb-4">
                  <div
                    class="d-flex justify-content-center flex-column align-items-center my-2">
                    <a
                      href="javascript:void(0)"
                      class="btn btn-main w-100 text-uppercase"
                      [ngClass]="{ disabled: submit && loader }"
                      (click)="onSubmit()">
                      <ng-container *ngIf="!loader; else sendingForm">
                        {{ "general.update" | translate }}
                      </ng-container>

                      <ng-template #sendingForm>
                        <div class="spinner-border text-light" role="status">
                          <span
                            class="visually-hidden"
                            [innerHTML]="'general.loading' | translate"></span>
                        </div>
                      </ng-template>
                    </a>
                  </div>
                </div>
              </ng-container>
            </form>
            <div
              class="d-flex justify-content-center flex-column align-items-center my-2">
              <a
                class="btn btn-main w-100"
                routerLink="/sign-in"
                [innerHTML]="'general.cancel' | translate">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
