<form [formGroup]="form" novalidate autocomplete="off">
  <div class="row align-items-center">
    <!-- Input Name -->
    <div class="col-12 col-xl-6 mb-3 position-relative">
      <input
        type="text"
        class="form-control user-icon"
        aria-label="Name"
        formControlName="name"
        [placeholder]="'form.name' | translate" />
      <div class="icon-view">
        <img src="assets/img/user-icon-line.png" />
      </div>
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'name' }"></ng-container>
    </div>

    <div class="col-12 col-xl-6 mb-3 position-relative">
      <input
        type="text"
        class="form-control user-icon"
        aria-label="Name"
        formControlName="lastName"
        [placeholder]="'form.lastName' | translate" />
      <div class="icon-view">
        <img src="assets/img/user-icon-line.png" />
      </div>
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'lastName' }"></ng-container>
    </div>

    <!-- Input Email -->
    <div class="col-12 col-xl-6 mb-3 position-relative">
      <input
        type="email"
        class="form-control email-icon"
        aria-label="email"
        formControlName="email"
        [placeholder]="'form.email' | translate" />
      <div class="icon-view">
        <img src="assets/img/email-icon-line.png" />
      </div>
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'email' }"></ng-container>
    </div>

    <!-- Input Phone Number -->
    <div class="col-12 col-xl-6 mb-3 position-relative">
      <div class="row g-1">
        <div class="col-12 col-lg-4">
          <select
            class="form-select mb-3"
            aria-label="Default select example"
            formControlName="prefix"
            [innerHTML]="'form.prefix' | translate">
            <option hidden value="" disabled selected>Prefijo</option>
            <option *ngFor="let item of country" [value]="item.phonecode">
              {{ item.name }} +{{ item.phonecode }}
            </option>
          </select>

          <ng-container
            [ngTemplateOutlet]="renderVM"
            [ngTemplateOutletContext]="{ field: 'prefix' }"></ng-container>
        </div>
        <div class="col-12 col-lg-8 position-relative">
          <input
            type="text"
            class="form-control phone-icon"
            aria-label="Teléfono"
            formControlName="phoneNumber"
            [placeholder]="'form.phone' | translate" />
          <div class="icon-view">
            <img src="assets/img/phone-icon-line.png" />
          </div>
          <ng-container
            [ngTemplateOutlet]="renderVM"
            [ngTemplateOutletContext]="{ field: 'phoneNumber' }"></ng-container>
        </div>
      </div>
    </div>

    <div class="col-12">
      <ng-container *ngIf="!loading; else renderLoadingBtn">
        <button type="submit" class="btn btn-main" (click)="onSubmit()">
          <span [innerHTML]="'general.update' | translate"> </span>
        </button>
      </ng-container>
    </div>
  </div>
</form>

<ng-template #renderLoadingBtn>
  <button type="submit" class="btn" disabled>Procesando...</button>
</ng-template>

<ng-template #renderVM let-field="field">
  <div class="mt-1 mx-2 mb-3" *ngIf="submit">
    <ng-container *ngFor="let val of vm[field]">
      <span class="text-danger" *ngIf="f[field].hasError(val.type)">
        {{ val.message | titlecase }}
      </span>
    </ng-container>
  </div>
</ng-template>
