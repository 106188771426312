<div class="dw">
  <div class="card-buy">
    <form
      [class]="'form-' + bg"
      [formGroup]="form"
      novalidate
      autocomplete="off">
      <div class="row align-items-center justify-content-center g-2">
        <div class="col-12 col-lg-6">
          <label
            for="costo"
            class="form-label"
            [innerHTML]="'general.tokenNo' | translate"></label>
          <div class="number-select-input mb-3">
            <div class="icon-cgp" (click)="addTokens()">
              <i class="bi bi-plus"></i>
            </div>
            <div class="campo-amount">
              <input
                type="text"
                min="0"
                appIntegerInput
                formControlName="numberTokens" />
            </div>
            <div class="icon-cgp" (click)="restTokens()">
              <i class="bi bi-dash"></i>
            </div>
          </div>

          <ng-container
            [ngTemplateOutlet]="renderVM"
            [ngTemplateOutletContext]="{
              field: 'numberTokens'
            }"></ng-container>
        </div>

        <div class="col-12 col-lg-6">
          <label
            for="costo"
            class="form-label"
            [innerHTML]="'general.cost' | translate"></label>
          <input
            type="text"
            class="form-control mb-3 cost"
            readonly
            formControlName="amount" />
          <ng-container
            [ngTemplateOutlet]="renderVM"
            [ngTemplateOutletContext]="{ field: 'amount' }"></ng-container>
        </div>

        <div class="col-12 col-xl-6">
          <label
            for="exampleFormControlInput1"
            class="form-label"
            [innerHTML]="'general.methodOfPayment' | translate">
          </label>
          <select
            class="form-select mb-3"
            formControlName="whiteListToken"
            (change)="onChangeWhitelist()">
            <!-- <option value="topPay" *ngIf="isProduction">
              Tarjeta de Crédito/Débito
            </option> -->
            <option *ngFor="let item of whitelist" [value]="item.addr">
              <ng-container
                *ngIf="!item.isNative; else nativeName"
                [ngTemplateOutlet]="renderERC20Name"
                [ngTemplateOutletContext]="{ item: item }"></ng-container>
            </option>

            <!-- <option value="stripe">STRIPE</option> -->
            <option
              value="transfer"
              [innerHTML]="'general.transfer' | translate"></option>
          </select>

          <ng-container
            [ngTemplateOutlet]="renderVM"
            [ngTemplateOutletContext]="{
              field: 'whiteListToken'
            }"></ng-container>
        </div>

        <div class="col-12 col-md-6 d-flex justify-content-center">
          <ng-container
            *ngIf="!showSubmitLoaderButton; else renderSubmitLoaderButton">
            <ng-container
              *ngIf="
                user && user.exist;
                then renderUserAutenticard;
                else renderNotAutenticatedUser
              "></ng-container>
          </ng-container>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Botón para compra con Web3 -->
<ng-template #renderWeb3SubmitButton>
  <ng-container *ngIf="form.valid">
    <button
      type="button"
      class="btn btn-main text-uppercase"
      *ngIf="!walletBuy"
      (click)="launchOpenConnection()"
      [innerHTML]="'general.buyNow' | translate"></button>
    <button
      type="submit"
      class="btn btn-main text-uppercase"
      *ngIf="walletBuy"
      (click)="onSubmit()"
      [innerHTML]="'general.buyNow' | translate"></button>
  </ng-container>

  <ng-container *ngIf="form.invalid">
    <button
      type="submit"
      class="btn btn-main text-uppercase"
      (click)="onSubmit()"
      [innerHTML]="'general.buyNow' | translate"></button>
  </ng-container>
</ng-template>

<!-- Botón para compra con Stripe -->
<ng-template #renderStripeSubmitButton>
  <button
    type="button"
    class="btn btn-main text-uppercase"
    (click)="onSubmitStripe()"
    [innerHTML]="'general.buyNow' | translate"></button>
</ng-template>
<!-- Botón para compra con transfer -->
<ng-template #renderTransferSubmitButton>
  <button
    type="button"
    class="btn btn-main text-uppercase"
    [routerLink]="['/property', dataId, 'transfer']"
    [innerHTML]="'general.buyNow' | translate"></button>
</ng-template>

<!-- Render de usuario autenticado -->
<!-- Usuario registrado y con perfil completado -->
<!-- <ng-template #renderUserAutenticard>
  
  <ng-container
    *ngIf="
      user.walletAddress && user.kyc == true;
      else renderIncompleteProfile
    ">
    <ng-container
      *ngIf="
        f['whiteListToken'].value === 'transfer';
        then renderTransferSubmitButton;
        else renderWeb3SubmitButton
      "></ng-container>
  </ng-container>
</ng-template> -->

<!-- para uso de prueba  para probar transferencia-->
<ng-template #renderUserAutenticard>
  <!-- Usuario registrado y con perfil completado -->
  <ng-container *ngIf="user.walletAddress; else renderIncompleteProfile">
    <ng-container
      *ngIf="
        f['whiteListToken'].value === 'transfer';
        then renderTransferSubmitButton;
        else renderWeb3SubmitButton
      "></ng-container>
  </ng-container>
</ng-template>

<!-- Botón de usuario con perfil incompleto -->
<ng-template #renderIncompleteProfile>
  <button
    type="button"
    class="btn btn-main text-uppercase"
    (click)="launchCompleteIncompletedAlert()"
    [innerHTML]="'general.buyNow' | translate"></button>
</ng-template>

<!-- Botón de usuario no autenticado -->
<ng-template #renderNotAutenticatedUser>
  <button
    type="button"
    class="btn btn-main text-uppercase"
    [routerLink]="'/login'"
    [innerHTML]="'general.buyNow' | translate"></button>
</ng-template>

<!-- Botón de carga de formulario -->
<ng-template #renderSubmitLoaderButton>
  <button type="button" class="btn btn-main" disabled>
    <div class="d-flex justify-content-center align-items-center">
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"></div>
      &nbsp;
      <strong [innerHTML]="'form.processing' | translate"></strong>
    </div>
  </button>
</ng-template>

<ng-template #renderERC20Name let-item="item">
  {{ item.addr | erc20 : "symbol" | async | uppercase }} (Polygon)
</ng-template>

<ng-template #nativeName>
  {{ nativeCurrency.name | uppercase }} (Polygon)
</ng-template>

<!-- <app-modal-toppay-form
  #modalBuyTopPay
  (onSubmitEvent)="onCompletedTopPayModal($event)"
  [mId]="modalId"></app-modal-toppay-form> -->

<ng-template #renderVM let-field="field">
  <div class="mt-1 mx-2" *ngIf="submit">
    <ng-container *ngFor="let val of vm[field]">
      <span class="text-danger" *ngIf="f[field].hasError(val.type)">
        {{ val.message | translate }}
      </span>
    </ng-container>
  </div>
</ng-template>
