<div class="position-relative w-100 d-flex px-3">
  <swiper class="swiper mySwiper position-relative" #swiper [config]="config">
    <ng-template swiperSlide>
      <div class="swiper-slide-grow-your-investment">
        <div class="grow-your-investment-item">
          <div class="grow-your-investment-item-img">
            <img src="assets/img/investment-img-1.png" alt="" />
          </div>
          <div class="grow-your-investment-item-content">
            <span>1.</span>
            <p>
              Regístrate y completa la <br class="d-none d-md-block" />
              verificación KYC.
            </p>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="swiper-slide-grow-your-investment">
        <div class="grow-your-investment-item">
          <div class="grow-your-investment-item-img">
            <img src="assets/img/investment-img-2.png" alt="" />
          </div>
          <div class="grow-your-investment-item-content">
            <span>2.</span>
            <p>
              Elige un proyecto y la <br class="d-none d-md-block" />
              cantidad de tokens que deseas <br class="d-none d-md-block" />
              comprar
            </p>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="swiper-slide-grow-your-investment">
        <div class="grow-your-investment-item">
          <div class="grow-your-investment-item-img">
            <img src="assets/img/investment-img-3.png" alt="" />
          </div>
          <div class="grow-your-investment-item-content">
            <span>3.</span>
            <p>Selecciona el método de pago.</p>
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>

  <div class="slider-footer d-md-none">
    <div class="slider-arrows">
      <div class="swiper-button-prev" (click)="slidePrev()">
        <img
          src="assets/img/icon-arrow-gold-right.png"
          class="grey-arrow"
          alt="flecha" />
        <!-- <img
          src="assets/img/right-chevron-slider-orange.png"
          class="orange-arrow"
          alt=""
        /> -->
      </div>
      <div class="swiper-button-next" (click)="slideNext()">
        <img
          src="assets/img/icon-arrow-gold-left.png"
          class="grey-arrow"
          alt="flecha" />
        <!-- <img
          src="assets/img/right-chevron-slider-orange.png"
          class="orange-arrow"
          alt="flecha"
        /> -->
      </div>
    </div>

    <!-- <div class="swiper-pagination"></div> -->
  </div>
</div>
