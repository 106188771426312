import { Pipe, PipeTransform } from '@angular/core';
import { Web3Service } from '../services/contract/web3.service';

@Pipe({
  name: 'customERC721a'
})
export class CustomERC721aPipe implements PipeTransform {

  constructor(
    private web3Srv: Web3Service,
  ) { }

  async transform(address: string, field: string, params: any = null): Promise<any> {
    return await this.web3Srv.callDinamyContractOffLine({
      contractAddress: address,
      method: field,
      callType: 'call',
      params: params,
      optionals: null,
      urlABI: this.web3Srv.elysiumERC721aABI
    });
  }

}
