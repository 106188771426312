import { Injectable } from "@angular/core";
import { AngularFirestore, Query } from "@angular/fire/firestore";
import { map, Observable } from "rxjs";
import {
  handlerArrayResult,
  handlerObjectResult,
} from "src/app/helpers/model.helper";
import { getCollectionName } from "src/app/helpers/utils";

@Injectable({
  providedIn: "root",
})
export class NewsletterService {
  public collection = getCollectionName("newsletter");

  constructor(public afs: AngularFirestore) {}

  /**
   * Registrar
   * @param data
   * @returns
   */
  async store(data: any) {
    const snapshot = await this.afs.collection(this.collection).add(data);
    return snapshot.id;
  }

  /**
   * Obtener listado completo
   * @returns
   */
  async getAll() {
    const snapshot = await this.afs.collection(this.collection).ref.get();
    return await handlerArrayResult(snapshot);
  }

  /**
   * Actualizar
   * @param docId
   * @param data
   * @returns
   */
  async update(docId: string, data: any) {
    return await this.afs.collection(this.collection).doc(docId).update(data);
  }

  /**
   * Borrar
   * @param docId
   * @returns
   */
  async delete(docId: string) {
    return await this.afs.collection(this.collection).doc(docId).delete();
  }

  /**
   * Obtener listado dinamico
   * @param where
   * @param where.field
   * @param where.condition
   * @param where.value
   * @param opts
   * @param opts.idField
   * @param opts.orderBy
   * @param opts.orderBy.field
   * @param opts.orderBy.order
   *
   * @returns
   */
  getDynamic(where: any[] = [], opts: any = {}): Observable<any[]> {
    const {
      idField = "_id",
      startAt = null,
      startAfter = null,
      endAt = null,
      endBefore = null,
      orderBy = [],
      limit = null,
      limitToLast = null,
    } = opts;

    return this.afs
      .collection(this.collection, (ref) => {
        let query: Query = ref;
        for (const row of where) {
          query = query.where(row.field, row.condition, row.value);
        }

        for (const order of orderBy) {
          query = query.orderBy(order.field, order.order);
        }

        if (startAt) {
          query = query.startAt(startAt);
        }

        if (typeof startAfter === "string") {
          query = query.startAfter(startAfter);
        }

        if (Array.isArray(startAfter)) {
          query = query.startAfter(...startAfter);
        }

        if (typeof endAt === "string") {
          query = query.endAt(endAt);
        }

        if (Array.isArray(endAt)) {
          query = query.endAt(...endAt);
        }

        if (endBefore) {
          query = query.endBefore(endBefore);
        }

        if (limit) {
          query = query.limit(limit);
        }

        if (limitToLast) {
          query = query.limitToLast(limitToLast);
        }

        return query;
      })
      .valueChanges({ idField });
  }

  getDynamicCount(where: any[] = []): Observable<number> {
    return this.afs
      .collection(this.collection, (ref) => {
        let query: Query = ref;
        for (const row of where) {
          query = query.where(row.field, row.condition, row.value);
        }
        return query;
      })
      .valueChanges()
      .pipe(map((data) => data.length));
  }
}
