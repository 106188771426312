import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import BigNumber from "bignumber.js";
import * as moment from "moment";
import {
  Subscription,
  catchError,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  tap,
} from "rxjs";
import { getFormErrors } from "src/app/helpers/formErrors.helper";
import { fromWei } from "src/app/helpers/utils";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CollectionService } from "src/app/services/firebase/collection.service";
import { LocalCartService } from "src/app/services/local-cart.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { UsersCartService } from "src/app/services/usersCart.service";

@Component({
  selector: "app-user-my-investment-calculator-form",
  templateUrl: "./user-my-investment-calculator-form.component.html",
  styleUrls: ["./user-my-investment-calculator-form.component.css"],
})
export class UserMyInvestmentCalculatorFormComponent
  implements OnInit, OnDestroy
{
  public form: FormGroup;

  public vm: any = {
    collectionId: [
      { type: "required", message: "form.validations.ownershipIsRequired" },
    ],
    nroTokens: [
      { type: "required", message: "form.validations.numberOfTokensRequired" },
      {
        type: "min",
        message: "form.validations.theNumberOfTokensMustBeGreaterThan0",
      },
    ],
  };

  public submitted = false;

  public showSubmitLoaderButton = false;

  public collections!: any[];

  private editItem: any;

  private sub$!: Subscription;

  constructor(
    private fb: FormBuilder,
    private collectionSrv: CollectionService,
    private sweetAlert2Srv: Sweetalert2Service,
    private localCartSrv: LocalCartService,
    private authSrv: AuthenticationService,
    private usersCartService: UsersCartService
  ) {
    /* contruccion del formulario de inversiones */
    this.form = this.fb.group({
      id: [""],
      collectionId: ["", [Validators.required]],
      profit: 0,
      price: 0,
      nroTokens: [0, [Validators.required, Validators.min(1)]],
      totales: 0,
      gananciaAnual: 0,
      gananciaMensual: 0,
      gananciaAnualFive: 0,
    });

    this.form.disable();
  }

  ngOnInit(): void {
    // console.log('UserMyInvestmentCalculatorFormComponent');

    this.sub$ = this.collectionSrv
      .getDynamic([
        { field: "status", condition: "in", value: ["preview", "opening"] },
      ])
      .pipe(
        // tap((data) => console.log('data', data)),
        catchError((err) => of([]))
      )
      .subscribe((collections) => {
        this.collections = collections;

        /** Si hay colecciones - Habilitar formulario */
        if (collections.length > 0) {
          this.form.enable();
        }
      });

    /** Escuchar evento de cambio de colección */
    this.sub$.add(
      combineLatest([
        this.form.get("collectionId")!.valueChanges.pipe(
          map((collectionId: string) =>
            collectionId.length > 0 ? collectionId : null
          ),
          distinctUntilChanged()
        ),
        this.form.get("nroTokens")!.valueChanges.pipe(
          map((nroTokens: string) => (!nroTokens ? null : Number(nroTokens))),
          distinctUntilChanged()
        ),
      ]).subscribe(([collectionId, nroTokens]) => {
        // console.log('collectionId', collectionId);
        // console.log('nroTokens', nroTokens);

        /** Si no hay colección o número de tokens - Limpiar formulario */
        if (!collectionId || !nroTokens) {
          this.form.patchValue({
            profit: 0,
            price: 0,
            totales: 0,
            gananciaAnual: 0,
            gananciaMensual: 0,
            gananciaAnualFive: 0,
          });
          return;
        }

        /** Obtener información de la colección */
        const collectionDoc = this.collections.find(
          (item: any) => item._id == collectionId
        );
        // console.log("collectionDoc", collectionDoc);

        /** Calcular ganancias */
        const profit = collectionDoc.estimatedProfitability;
        const price = fromWei(collectionDoc.price);
        const totales = new BigNumber(nroTokens).multipliedBy(price).toNumber();
        const gananciaAnual = new BigNumber(totales)
          .multipliedBy(profit)
          .dividedBy(100)
          .plus(totales)
          .toNumber();
        const gananciaMensual = new BigNumber(gananciaAnual)
          .dividedBy(12)
          .plus(totales)
          .toNumber();
        const gananciaAnualFive = new BigNumber(gananciaAnual)
          .multipliedBy(5)
          .plus(totales)
          .toNumber();

        /** Actualizar formulario */
        this.form.patchValue({
          profit: profit,
          price: price,
          totales: totales,
          gananciaAnual: gananciaAnual,
          gananciaMensual: gananciaMensual,
          gananciaAnualFive: gananciaAnualFive,
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  get f() {
    return this.form.controls;
  }

  /**
   * @dev Cargar datos de la colección en el formulario
   * @param item
   */
  bulkEditItem(item: any) {
    this.editItem = item;
    this.form.patchValue(item);
  }

  async onSubmit() {
    try {
      this.submitted = true;
      const formData = this.form.value;

      if (!this.form.valid) {
        const formErrors = getFormErrors(this.form);
        const { key, errors } = formErrors[0];
        const ferr = Object.keys(errors)[0];
        console.log("formErrors", formErrors);

        const findMessage = this.vm[key].find((item: any) => item.type == ferr);
        // console.log("findMessage", findMessage);

        this.sweetAlert2Srv.showWarning(findMessage.message);
        return;
      }

      this.showSubmitLoaderButton = true;
      this.form.disable();

      // console.log('Trying to submit form', formData);

      if (formData.id) {
        await this.updateWishItem(formData);
      } else {
        await this.storeNewWishItem(formData);
      }
      return;
    } catch (err) {
      console.log(
        "Error on UserMyInvestmentCalculatorFormComponent.onSubmit",
        err
      );
      return;
    } finally {
      this.form.enable();
      this.showSubmitLoaderButton = false;
    }
  }

  async storeNewWishItem(formData: any) {
    /** Obtener id de usuario */
    const userId = await this.authSrv.sessionUid();
    // console.log("userId", userId);

    /** Crear documento por si no existe */
    // this.localCartSrv.buildAndStore();

    /** Obtener documento de la colección */
    const collectionDoc = this.collections.find(
      (item: any) => item._id == formData.collectionId
    );
    // console.log("collectionDoc", collectionDoc);

    /** Capturar tiempo actual */
    const createdAt = moment().valueOf();

    /** Crear documento de inversión */
    const data = {
      // ...collectionDoc,
      collectionId: formData.collectionId,
      priceParsed: Number(fromWei(collectionDoc.price)),
      nroTokens: formData.nroTokens,
      totales: formData.totales,
      gananciaAnual: formData.gananciaAnual,
      gananciaMensual: formData.gananciaMensual,
      gananciaAnualFive: formData.gananciaAnualFive,
      gananciaTotal: formData.totales + formData.gananciaAnual,
      userId: userId,
      createdAt: createdAt,
    };
    // console.log("data", data);

    /** Guardar colección en wishList */
    await this.usersCartService.store(data);

    this.form.patchValue({ collectionId: "", nroTokens: 0 });
    this.sweetAlert2Srv.showToast("property.addWhiteListCollection", "success");
    return;
  }

  async updateWishItem(formData: any) {
    /** Obtener documento de la colección */
    const collectionDoc = this.collections.find(
      (item: any) => item._id == formData.collectionId
    );
    // console.log("collectionDoc", collectionDoc);

    const data = {
      ...this.editItem,
      collectionId: formData.collectionId,
      priceParsed: Number(fromWei(collectionDoc.price)),
      nroTokens: formData.nroTokens,
      totales: formData.totales,
      gananciaAnual: formData.gananciaAnual,
      gananciaMensual: formData.gananciaMensual,
      gananciaAnualFive: formData.gananciaAnualFive,
      gananciaTotal: formData.totales + formData.gananciaAnual,
    };

    await this.usersCartService.update(data.id, data);

    this.sweetAlert2Srv.showToast(
      "property.pdateWhiteListCollection",
      "success"
    );
    this.editItem = null;
    this.form.patchValue({ id: "", collectionId: "", nroTokens: 0 });
    return;
  }
}
