import { FormGroup } from "@angular/forms";

export function getFormErrors(form: FormGroup) : FormError[] {
    return Object.keys(form.controls).map(key => {
      return {
        key: key,
        errors: form.get(key)?.errors
      };
    })
    .filter(({errors}) => errors != null);
}

export interface FormError {
    key: string;
    errors: any;
}