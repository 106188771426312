import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { SwiperComponent } from "swiper/angular";
import { Observable, catchError, from, map, of } from "rxjs";
import { InvestalkService } from "src/app/services/firebase/investalk.service";

import SwiperCore, {
  Swiper,
  Virtual,
  SwiperOptions,
  Autoplay,
  Pagination,
} from "swiper";

// install Swiper modules
SwiperCore.use([Virtual, Autoplay, Pagination]);

@Component({
  selector: "app-slider-news",
  templateUrl: "./slider-news.component.html",
  styleUrls: ["./slider-news.component.css"],
})
export class SliderNewsComponent implements OnInit {
  @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;

  @Input() data: any;
  public investalkList!: any;

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: {
      el: ".swiper-pagination",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 10,
      },

      1199: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    },
  };

  constructor(private investalkService: InvestalkService) {}

  async ngOnInit() {
    await this.loadData();
  }

  async loadData() {
    if (this.data === "public") {
      this.investalkList = await this.investalkService.getDynamicToPromise([
        { field: "status", condition: "==", value: "public" },
      ]);
    } else if (this.data === "private") {
      this.investalkList = await this.investalkService.getDynamicToPromise([
        { field: "status", condition: "==", value: "private" },
      ]);
    }
  }

  slideNext() {
    this.swiper?.swiperRef.slideNext(100);
  }
  slidePrev() {
    this.swiper?.swiperRef.slidePrev(100);
  }
}
