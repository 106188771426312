import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, Query } from "@angular/fire/firestore";
import firebase from "firebase/app";
import { Router } from "@angular/router";
// import moment from 'moment';
import {
  BehaviorSubject,
  catchError,
  lastValueFrom,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from "rxjs";
import { environment } from "src/environments/environment";
import {
  handlerArrayResult,
  handlerObjectResult,
} from "../helpers/model.helper";
import { getCollectionName } from "../helpers/utils";

@Injectable({
  providedIn: "root",
})
export class UsersCartService {

  // public collection = "usersCart";
  public collection = getCollectionName("usersCart");

  constructor(
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    private router: Router,
    private _http: HttpClient
  ) {}

  /**
   * Registrar documento al carrito del usuario
   * @param data
   * @returns
   */
  async store(data: any) {
    const snapshot = await this.afs.collection(this.collection).add(data);
    return snapshot.id;
  }

  async update(docId: string, data: any) {
    return await this.afs.collection(this.collection).doc(docId).update(data);
  }

  async remove(docId: string) {
    return await this.afs.collection(this.collection).doc(docId).delete();
  }

  /**
   * Obtener listado dinamico
   * @param where
   * @param where.field
   * @param where.condition
   * @param where.value
   * @param opts
   * @param opts.idField
   * @param opts.orderBy
   * @param opts.orderBy.field
   * @param opts.orderBy.order
   * @param opts.startAt
   * @param opts.endAt
   * @param opts.limit
   *
   * @returns
   */
  getDynamic(where: any[] = [], opts: any = {}): Observable<any[]> {
    const {
      idField = "_id",
      startAt = null,
      endAt = null,
      orderBy = [],
      limit = null,
    } = opts;

    return this.afs
      .collection(this.collection, (ref) => {
        let query: Query = ref;
        for (const row of where) {
          query = query.where(row.field, row.condition, row.value);
        }

        for (const order of orderBy) {
          query = query.orderBy(order.field, order.order);
        }

        if (startAt) {
          query = query.startAt(startAt);
        }

        if (endAt) {
          query = query.endAt(endAt);
        }

        if (limit) {
          query = query.limit(limit);
        }

        return query;
      })
      .valueChanges({ idField });
  }

  async getDynamicToPromiseCart(
    where: any[] = [],
    opts: any = {}
  ): Promise<any[]> {
    try {
      const {
        idField = "_id",
        startAt = null,
        endAt = null,
        orderBy = [],
      } = opts;

      const snapshot = await this.afs
        .collection(this.collection, (ref) => {
          let query: Query = ref;
          for (const row of where) {
            query = query.where(row.field, row.condition, row.value);
          }

          for (const order of orderBy) {
            query = query.orderBy(order.field, order.order);
          }

          if (startAt) {
            query = query.startAt(startAt);
          }

          if (endAt) {
            query = query.endAt(endAt);
          }

          return query;
        })
        .get()
        .toPromise();

      return await handlerArrayResult(snapshot, { idField });
    } catch (err) {
      console.log("Error on AuthenticationService.getDynamicToPromise", err);
      return [];
    }
  }
}
