<div class="row mt-5">
  <div class="col-12 mb-5">
    <div class="card-dashboard-user-white">InvesTalk</div>
  </div>

  <div class="col-12">
    <ul class="nav nav-pills mb-3 nav-fill" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active me-3"
          id="pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-home"
          type="button"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true">
          VIP
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link ms-3"
          id="pills-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-profile"
          type="button"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
          [innerHTML]="'general.public' | translate"></button>
      </li>
    </ul>

    <div class="tab-content mt-5" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab">
        <app-slider-news [data]="'private'"></app-slider-news>
      </div>
      <div
        class="tab-pane fade"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab">
        <app-slider-news [data]="'public'"></app-slider-news>
      </div>
    </div>
  </div>
</div>
