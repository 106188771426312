<!-- Menu para desktop -->
<button
  class="btn btn-sidebar-user"
  (click)="openNav()"
  [ngClass]="{  active: showNavbar, }">
  <img src="assets/img/arrow-blue.png" alt="" />
</button>

<div id="mySidenav" #sidebarUSer class="sidenav active">
  <img
    [routerLink]="'/home'"
    [ngClass]="{ 'd-flex': showNavbar, 'd-none': 
    !showNavbar, }"
    src="assets/img/logo-k.png"
    class="img-buy logo-sidebar-user mb-5"
    alt="" />

  <img
    [routerLink]="'/home'"
    [ngClass]="{ 'd-flex': !showNavbar, 'd-none': showNavbar, }"
    src="assets/img/logo-new-3.png"
    class="img-buy logo-sidebar-user-completed w-100"
    alt="" />

  <ul
    class="list-close-sidebar"
    [ngClass]="{ 'd-flex': showNavbar, 'd-none': !showNavbar, }">
    <li
      [routerLink]="'/dashboard-user/portfolio'"
      [routerLinkActive]="'active'">
      <a>
        <img src="assets/img/folder-setup.png" class="img-fluid" alt="" />
      </a>
    </li>
    <li [routerLink]="'/dashboard-user/investalk'">
      <a>
        <img src="assets/img/chat-icon.png" class="img-fluid" alt="" />
      </a>
    </li>
    <li
      [routerLink]="'/dashboard-user/my-investment'"
      [routerLinkActive]="'active'">
      <a>
        <img src="assets/img/profit-icon.png" class="img-fluid" alt="" />
      </a>
    </li>
    <li data-bs-toggle="modal" data-bs-target="#staticBackdrop">
      <a>
        <img src="assets/img/calculator-icon.png" class="img-fluid" alt="" />
      </a>
    </li>

    <li (click)="logout()">
      <a>
        <img src="assets/img/logout-user-orange.png" class="img-fluid" alt="" />
      </a>
    </li>
  </ul>

  <ul
    class="list-open-sidebar"
    [ngClass]="{ 'd-flex': !showNavbar, 'd-none': showNavbar, }">
    <li
      [routerLink]="'/dashboard-user/portfolio'"
      [routerLinkActive]="'active'">
      <a>
        <img src="assets/img/folder-setup.png" class="img-fluid" alt="" />
        {{ "general.myPortfolio" | translate }}
      </a>
    </li>
    <li [routerLink]="'/dashboard-user/investalk'">
      <a>
        <img src="assets/img/chat-icon.png" class="img-fluid" alt="" />
        INVESTalk
      </a>
    </li>
    <li>
      <a
        [routerLink]="'/dashboard-user/my-investment'"
        [routerLinkActive]="'active'">
        <img src="assets/img/profit-icon.png" class="img-fluid" alt="" />
        {{ "general.projectionOfMyInvestment" | translate }}
      </a>
    </li>
    <li data-bs-toggle="modal" data-bs-target="#staticBackdrop">
      <a>
        <img src="assets/img/calculator-icon.png" class="img-fluid" alt="" />
        {{ "general.calculator" | translate }}
      </a>
    </li>

    <li (click)="logout()">
      <a>
        <img src="assets/img/logout-user-orange.png" class="img-fluid" alt="" />
        {{ "general.logOut" | translate }}
      </a>
    </li>
  </ul>
</div>

<!-- Menu para mobile -->

<nav class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="'/home'">
      <img src="assets/img/logo-new-3.png" alt="" class="img-fluid" />
    </a>
    <div>
      <button class="navbar-toggler" type="button" (click)="toggleMenuMobile()">
        <img
          src="assets/img/menu-mobile-icon-white.png"
          class="img-fluid"
          alt="" />
      </button>
    </div>

    <div
      class="collapse navbar-collapse"
      id="navbarSupportedContent"
      #menuMobile>
      <ul class="navbar-nav me-auto">
        <li
          [routerLink]="'/dashboard-user/portfolio'"
          [routerLinkActive]="'active'"
          (click)="toggleMenuMobile()">
          <a>
            <img src="assets/img/folder-setup.png" class="img-fluid" alt="" />
            {{ "general.myPortfolio" | translate }}
          </a>
        </li>
        <li
          [routerLink]="'/dashboard-user/investalk'"
          [routerLinkActive]="'active'"
          (click)="toggleMenuMobile()">
          <a>
            <img src="assets/img/chat-icon.png" class="img-fluid" alt="" />
            INVESTalk
          </a>
        </li>
        <li
          [routerLink]="'/dashboard-user/my-investment'"
          [routerLinkActive]="'active'"
          (click)="toggleMenuMobile()">
          <a>
            <img src="assets/img/profit-icon.png" class="img-fluid" alt="" />
            {{ "general.projectionOfMyInvestment" | translate }}
          </a>
        </li>

        <li (click)="logout()">
          <a>
            <img
              src="assets/img/logout-user-orange.png"
              class="img-fluid"
              alt="" />

            {{ "general.logOut" | translate }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- Modal para la calculadora -->
<div
  class="modal fade"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">
          {{ "general.calculator" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close">
          <img src="assets/img/close-icon.png" alt="icono de cerrar" />
        </button>
      </div>
      <div class="modal-body m-0 p-0">
        <app-calculator></app-calculator>
      </div>
    </div>
  </div>
</div>
