import { AfterViewInit, Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { getFormErrors } from 'src/app/helpers/formErrors.helper';
import { BsModalService } from 'src/app/services/bs-modal.service';

@Component({
  selector: 'app-modal-toppay-form',
  templateUrl: './modal-toppay-form.component.html',
  styleUrls: ['./modal-toppay-form.component.css']
})
export class ModalToppayFormComponent implements OnInit, AfterViewInit {

  @Input() public mId = "modalToppayForm";
  
  @Output() onSubmitEvent = new Subject<any>();

  public mi: any;

  public form!: FormGroup;

  public vm: any = {
    username: [
      { type: "required", message: "Requerido" },
      { type: "pattern", message: "Sólo letras" },
      { type: "minlength", message: "Mínimo 2 caracteres" },
    ],
    useremail: [
      { type: "required", message: "Requerido" },
      { type: "pattern", message: "Correo no válido" },
    ],
    userphone: [
      { type: "required", message: "Requerido" },
      { type: "pattern", message: "Sólo números" },
      { type: "minlength", message: "mínimo 9 números" },
      { type: "maxlength", message: "máximo 12 números" },
    ],
    numdoc: [
      { type: "required", message: "Requerido" },
      { type: "pattern", message: "Sólo números" },
      { type: "minlength", message: "mínimo 7 números" },
      { type: "maxlength", message: "máximo 10 números" },
    ],
    address: [{ type: "required", message: "Requerido" }],
    additional: [
      { type: "required", message: "Requerido" },

      { type: "minlength", message: "Mínimo 3 caracteres" },
    ],
    check: [{ type: "required", message: "Requerido" }],
  };

  public submitted = false;

  public showSubmitLoaderButton = false;

  constructor(
    private bsModalSrv: BsModalService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void { this.buildForm() }

  ngAfterViewInit() { this.buildModal(); }

  buildModal(){ this.mi = this.bsModalSrv.buildModal(this.mId, {}); }

  openModal(){ this.mi.show(); }

  closeModal(){ this.mi.hide(); }

  /**
   * @dev Construir formulario
   */
  buildForm() {
    this.form = this.fb.group({
      username: [
        "",
        [
          Validators.required,
          Validators.pattern("[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*"),
          Validators.minLength(2),
        ],
      ],
      useremail: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      userphone: [
        "",
        [
          Validators.required,
          Validators.pattern(/^\d+$/),
          Validators.minLength(9),
          Validators.maxLength(12),
        ],
      ],
      numdoc: [
        "",
        [
          Validators.required,
          Validators.pattern(/^\d+$/),
          Validators.minLength(7),
          Validators.maxLength(12),
        ],
      ],
      address: ["", [Validators.required]],
      additional: [""],
      check: ["", [Validators.requiredTrue]],
    });
  }

  get f() { return this.form.controls; }

  /**
   * @dev Enviar formulario
   */
  async onSubmit() {
    try {
      this.submitted = true;
      const formData = this.form.value;

      if(!this.form.valid) {
        const formErrors = getFormErrors(this.form);
        console.log('formErrors', formErrors);
        return;
      }

      this.showSubmitLoaderButton = true;
      this.form.disable();

      const _data = {
        username: `${formData.username}`.trim().toLowerCase(),
        useremail: `${formData.useremail}`.trim().toLowerCase(),
        userphone: `${formData.userphone}`.trim(),
        numdoc: `${formData.numdoc}`.trim(),
        address: `${formData.address}`.trim(),
        additional: `${formData.additional}`.trim(),
        check: formData.check,
      };

      this.onSubmitEvent.next(_data);
      this.closeModal();
      this.submitted = false;

      this.form.enable();
      this.form.patchValue({
        username: "",
        useremail: "",
        userphone: "",
        numdoc: "",
        address: "",
        additional: "",
        check: "",
      });
      return;
      
    } catch (err) {
      console.log('Error on ModalToppayFormComponent', err);
      return;

    } finally {
      this.showSubmitLoaderButton = false;
    }
  }

}
