import { Component, OnInit } from "@angular/core";
import { HdWalletService } from "src/app/services/api/hd-wallet.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";

@Component({
  selector: "app-profile-request-wallet-custodio-info-form",
  templateUrl: "./profile-request-wallet-custodio-info-form.component.html",
  styleUrls: ["./profile-request-wallet-custodio-info-form.component.css"],
})
export class ProfileRequestWalletCustodioInfoFormComponent implements OnInit {
  public showSubmitLoaderButton = false;

  constructor(
    private authSrv: AuthenticationService,
    private hdWalletSrv: HdWalletService,
    private sweetAlert2Srv: Sweetalert2Service
  ) {}

  ngOnInit(): void {}

  async sendInfo() {
    // console.log('sendInfo');
    try {
      this.showSubmitLoaderButton = true;

      const uid = await this.authSrv.sessionUid();
      // console.log('uid', uid);

      const res = await this.hdWalletSrv.requestWalletCustodioInfo({
        uid: uid!,
      }); // Add the non-null assertion operator (!) to ensure uid is of type string
      // console.log('res', res);

      this.sweetAlert2Srv.showToast(
        "walletRegister.weWillShortlySendYouAnEmailWithYourWalletInformation",
        "success"
      );
      return;
    } catch (err) {
      console.log(
        "Error on ProfileRequestWalletCustodioInfoFormComponent.sendInfo",
        err
      );
      return;
    } finally {
      this.showSubmitLoaderButton = false;
    }
  }
}
