import { Component, OnInit } from "@angular/core";
import {
  catchError,
  map,
  Observable,
  of,
  switchMap,
  Subscription,
  tap,
} from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Web3Service } from "src/app/services/contract/web3.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { NgxSpinnerService } from "ngx-spinner";
import { KycApiService } from "src/app/services/api/kyc-api.service";

@Component({
  selector: "app-dashboard-user-profile",
  templateUrl: "./dashboard-user-profile.component.html",
  styleUrls: ["./dashboard-user-profile.component.css"],
})
export class DashboardUserProfileComponent implements OnInit {
  public id!: any;
  public profile: any;
  public dataStatus$!: Observable<any>;
  public walletVerification!: string;

  public userDoc$!: Observable<null | any>;

  constructor(
    private authSrv: AuthenticationService,
    private web3Srv: Web3Service,
    private sweetAlert2Srv: Sweetalert2Service,
    private spinner: NgxSpinnerService,
    private kycApiSrv: KycApiService
  ) {
    this.id = this.authSrv.getLocalUID();
    this.getUser(this.id);
  }

  ngOnInit() {
    /** TODO: revisar funcionalidad de estas lineas */
    //estatus de la cuenta de wallet
    // this.web3Srv.accountStatus$
    //   .pipe(
    //     map((data: any[]) => (data.length > 0 ? data[0] : null)),
    //     map((addr: any) => {
    //       if (!addr) return null;
    //       return addr;
    //     }),
    //     catchError((err) => {
    //       return of(null);
    //     })
    //   )
    //   .subscribe((data) => {
    //     console.log(data);
    //   });

    // // /** Intentar reestablecer conexión si la misma existe */
    // this.web3Srv.checkAlreadyConnected();

    this.userDoc$ = this.authSrv.userDoc$.pipe(
      tap((data) => console.log("userDoc", data))
    );
  }

  async getUser(id: any) {
    this.profile = await this.authSrv.getByUID(id);
    this.walletVerification = this.profile.walletAddress;
  }

  async connectWallet() {
    try {
      this.web3Srv.launchAskConnectionType().then((value: any) => {
        console.log(value[0]);
        let wallet = value[0];
        let data = {
          walletAddress: wallet,
        };
        this.authSrv.update(this.id, data);
        this.sweetAlert2Srv.showSuccess("Se ha registrado la wallet");
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * @description Función para crear enlace de KYC
   * @param uid             Identificador del usuario (dirección de wallet)
   * @returns
   */
  async createKYCLink(uid: string) {
    try {
      console.log("createKYCLink");

      await this.spinner.show();

      const result = await this.kycApiSrv.createLink(uid);

      this.sweetAlert2Srv
        .showSuccess("kyc.KYCLinkHasBeenSuccessfullyCreated")
        .then(() => {
          this.openKYCLink(result.url);
        });

      return;
    } catch (err) {
      console.log("Error on DashboardUserProfileComponent.startKYC", err);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  /**
   * @description Función para abrir enlace de KYC
   * @param url             URL del enlace de KYC
   */
  openKYCLink(url: string) {
    window.open(url, "_blank");
  }
}
