<div class="page-register-login">
  <div class="container">
    <div class="row justify-content-center">
      <div
        class="col-12 col-lg-6 d-flex justify-content-center align-items-center mb-5 mb-lg-0 flex-column">
        <img
          src="assets/img/logo-new-2.png"
          class="img-fluid logo-r-l"
          alt="" />
        <p
          class="text-yellow mt-3"
          [innerHTML]="'signInAndSignUp.slogan' | translate"></p>
      </div>
      <div class="col-12 col-lg-6 position-relative">
        <div class="back-r-l" [routerLink]="'/home'">
          <img src="assets/img/arrow-line-orange.png" class="me-2" />
          <span
            class="text-white"
            [innerHTML]="'general.backTo' | translate"></span>
        </div>
        <h1
          class="text-white title-r-l"
          [innerHTML]="'signInAndSignUp.welcomeTo' | translate"></h1>
        <div class="d-flex align-items-center mb-3 text-white">
          <img
            src="assets/img/user-icon-orange.png"
            width="25"
            class="me-2"
            alt="" />
          {{ "general.signIn" | translate }}
        </div>
        <app-form-login-user></app-form-login-user>
      </div>
    </div>
  </div>
</div>
