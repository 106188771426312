import { AfterViewInit, Component, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalService } from 'src/app/services/bs-modal.service';

@Component({
  selector: 'app-modal-contact-form',
  templateUrl: './modal-contact-form.component.html',
  styleUrls: ['./modal-contact-form.component.css']
})
export class ModalContactFormComponent implements AfterViewInit {

  @Output() public onSubmitEvent = new Subject<any>();

  @Input() public mId = "modalContactForm";

  public mi: any;

  constructor(
    private bsModalSrv: BsModalService
  ) { }

  ngAfterViewInit(): void {
    this.buildModal();
  }

  buildModal() { this.mi = this.bsModalSrv.buildModal(this.mId, {}); }

  openModal() {
    this.mi.show();
  }

  closeModal(data = null, role = 'cancel') {
    this.mi.hide();
    this.onSubmitEvent.next({ data, role });
  }

  onFormSubmit(data: any) {
    this.closeModal(data, 'submit');
  }

}
