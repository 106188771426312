<div class="row mt-5">
  <div class="col-12">
    <div class="card-verefication-user">
      <div class="card-verefication-user-header">
        <p [innerHTML]="'general.myPortfolio' | translate"></p>
      </div>

      <div class="card-verefication-user-body">
        <div class="table-responsive">
          <table class="table" style="background-color: #232323 !important">
            <thead>
              <tr>
                <th scope="col">
                  <span [innerHTML]="'general.projectName' | translate"></span>
                </th>
                <th scope="col">
                  <span [innerHTML]="'general.myTokens' | translate"></span>
                </th>
                <th scope="col">
                  <span [innerHTML]="'general.investment' | translate"></span>
                </th>
                <th scope="col">
                  <span>{{ "general.performance" | translate }} (%)</span>
                </th>
                <th scope="col">
                  <span
                    [innerHTML]="
                      'projectYourInvestment.one-yearYield' | translate
                    "></span>
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <ng-container [ngTemplateOutlet]="renderCheck"></ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-5">
  <div class="col-12 col-lg-6 mb-4 mb-lg-0">
    <p class="text-yellow f-18">
      {{ "general.valueOfThePortfolio" | translate }}:
    </p>
    <div class="card-dashboard-user-blue">
      <ng-container
        *ngIf="userPortfolio$ | async as info; else renderLoaderAmount">
        {{ info.vp | currency }} USD
      </ng-container>
    </div>
  </div>

  <div class="col-12 col-lg-6">
    <p class="text-yellow f-18">
      {{ "general.estimatedYield(Annual)" | translate }}:
    </p>
    <div class="card-dashboard-user-blue">
      <ng-container
        *ngIf="userPortfolio$ | async as info; else renderLoaderAmount">
        {{ info.ra | currency }} USD
      </ng-container>
    </div>
  </div>
</div>

<ng-template #renderTableRow let-propertie="propertie" let-row="row">
  <tr>
    <td scope="row">
      <div class="d-flex align-items-center justify-content-center">
        <!-- <img *ngIf="!propertie?.gallery" src="assets/img/property-img-slider.png" class="img-table" alt="" />
        <img *ngIf="propertie?.gallery[0].url" src="{{ propertie.gallery[0].url }}" class="img-table" alt="" /> -->
        <img
          [src]="
            !propertie?.gallery
              ? 'assets/img/property-img-slider.png'
              : propertie?.gallery[0].url
          "
          class="img-table"
          alt="" />

        {{ propertie.projectName }}
      </div>
    </td>
    <td>
      {{ row.propertiesTokensNumberBuy }}
    </td>
    <td>{{ row.propertiesPriceUSD | currency }} USD</td>
    <td>{{ propertie.estimatedProfitability }} %</td>
    <td>
      {{
        (row.propertiesPriceUSD * propertie.estimatedProfitability * 1) / 100 +
          row.propertiesPriceUSD | currency
      }}
      USD
    </td>
  </tr>
</ng-template>

<ng-template #renderUserPorfolio>
  <ng-container *ngFor="let row of userPorfolio">
    <ng-container
      *ngIf="
        row.propertieId | propertie | async as propertie;
        else renderRowLoader
      "
      [ngTemplateOutlet]="renderTableRow"
      [ngTemplateOutletContext]="{
        row: row,
        propertie: propertie
      }"></ng-container>
  </ng-container>
</ng-template>

<ng-template #noNotRecords>
  <tr>
    <th scope="row" colspan="7">
      <h5
        class="text-center"
        [innerHTML]="'general.noRecords' | translate"></h5>
    </th>
  </tr>
</ng-template>

<ng-template #renderCheck>
  <ng-container
    *ngIf="
      userPorfolio.length > 0;
      then renderUserPorfolio;
      else noNotRecords
    "></ng-container>
</ng-template>

<ng-template #loading>
  <ng-container
    *ngFor="let item of [1, 1, 1, 1]"
    [ngTemplateOutlet]="renderRowLoader"></ng-container>
</ng-template>

<ng-template #renderLoaderAmount>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span
        class="visually-hidden"
        [innerHTML]="'general.loading' | translate"></span>
    </div>
  </div>
</ng-template>

<ng-template #renderRowLoader>
  <tr>
    <th scope="row" colspan="7" class="placeholder-glow">
      <span class="placeholder placeholder-lg col-12"></span>
    </th>
  </tr>
</ng-template>
