<div class="position-relative w-100 d-flex">
  <swiper class="swiper mySwiper position-relative" #swiper [config]="config">
    <ng-container *ngIf="gallery; else loading">
      <ng-template swiperSlide *ngFor="let item of gallery">
        <div class="swiper-slide-property-img">
          <img
            *ngIf="item.type != 'video/mp4'"
            src="{{ item?.url }}"
            class="img-fluid"
            alt="" />
          <video
            controls
            *ngIf="item.type == 'video/mp4'"
            [src]="item.url"
            class="img-fluid rounded galleryaMedia"></video>
        </div>
      </ng-template>
    </ng-container>

    <ng-template swiperSlide *ngIf="gallery.length < 1"> </ng-template>
    <ng-template swiperSlide *ngIf="gallery.length < 2"> </ng-template>
    <ng-template swiperSlide *ngIf="gallery.length < 3"> </ng-template>
  </swiper>

  <div class="slider-footer" *ngIf="gallery.length >= 4">
    <div class="slider-arrows">
      <div class="swiper-button-prev" (click)="slidePrev()">
        <img
          src="assets/img/arrow-orange-circle.png"
          class="grey-arrow"
          alt="flecha" />
      </div>
      <div class="swiper-button-next" (click)="slideNext()">
        <img
          src="assets/img/arrow-orange-circle.png"
          class="grey-arrow"
          alt="flecha" />
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <tr *ngFor="let item of [1, 1, 1, 1]">
    <th scope="row" colspan="7" class="placeholder-glow">
      <span class="placeholder placeholder-lg col-12"></span>
    </th>
  </tr>
</ng-template>
