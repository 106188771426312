import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "src/app/services/authentication.service";
// import { EmailNotificationService } from 'src/app/services/email-notification.service';
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { DataService } from "src/app/services/data.service";
import { MustMatch } from "src/app/helpers/must-match.validator";
import {
  checkEmailStored,
  cmIsValidAddress,
} from "src/app/helpers/custom-validations.helper";
import { TemporalTokenService } from "src/app/services/temporal-token.service";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { CommonService } from "src/app/services/common.service";
import { slugify } from "src/app/helpers/slugify";
import { Web3Service } from "src/app/services/contract/web3.service";

@Component({
  selector: "app-form-register-user",
  templateUrl: "./form-register-user.component.html",
  styleUrls: ["./form-register-user.component.css"],
})
export class FormRegisterUserComponent implements OnInit {
  public showPassword: any = {
    password: false,
    repeatpassword: false,
  };

  public form!: FormGroup;
  public vm: any = {
    name: [
      { type: "required", message: "form.validations.isRequired" },
      { type: "pattern", message: "form.validations.lettersOnly" },
      { type: "minlength", message: "form.validations.minimum2Letters" },
    ],
    lastName: [
      { type: "required", message: "form.validations.isRequired" },
      { type: "pattern", message: "form.validations.lettersOnly" },
      { type: "minlength", message: "form.validations.minimum2Letters" },
    ],
    email: [
      { type: "required", message: "form.validations.isRequired" },
      { type: "pattern", message: "form.validations.email" },
      {
        type: "emailStored",
        message:
          "form.validations.EmailAlreadyExistsOrIsInUsePleaseEnterAnotherAddress",
      },
    ],
    phoneNumber: [
      { type: "required", message: "form.validations.isRequired" },
      { type: "pattern", message: "form.validations.onlyNumbersAreAllowed" },
      {
        type: "minlength",
        message: "form.validations.theTelephoneNumberMustHaveAtLeast9Numbers",
      },
      {
        type: "maxlength",
        message: "form.validations.thePhoneNumberMustHaveAMaximumOf10Numbers",
      },
    ],
    password: [
      { type: "required", message: "form.validations.isRequired" },
      {
        type: "pattern",
        message: "form.validations.passwordPattern",
      },
    ],
    repeatpassword: [
      { type: "required", message: "form.validations.isRequired" },
      {
        type: "mustMatch",
        message: "form.validations.passwordMustMatch",
      },
    ],
    term: [{ type: "required", message: "form.validations.isRequired" }],
    prefix: [{ type: "required", message: "form.validations.isRequired" }],
  };
  public submit = false;
  public loading: boolean = false;
  public country!: any[];

  constructor(
    public dataService: DataService,
    public fb: FormBuilder,
    private authSrv: AuthenticationService,
    private sweetAlert2Srv: Sweetalert2Service,
    private temporalTokenSrv: TemporalTokenService,
    // private emailNotificationSrv: EmailNotificationService,
    public dataSrv: DataService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private commonSrv: CommonService,
    private web3Srv: Web3Service
  ) {
    this.buildForm();

    /** Obtener listado de prefijos por ciudades */
    this.country = this.dataService.getCountry();

    /**
     * Obtener código de referido desde la URL
     * - Obtener valor desde la URL Query String
     * - Remover caracteres especiales
     * - Convertir a minúsculas
     * - Convertir a slug
     */
    const referralCode: string = slugify(
      this.commonSrv
        .removeSpecialCharacters(
          this.commonSrv.getParameterByName("code") || ""
        )
        .trim()
        .toLowerCase()
    );
    console.log("referralCode", referralCode);
    this.form.patchValue({ referralCode: referralCode });
  }

  ngOnInit() {}

  get f() {
    return this.form.controls;
  }

  /**
   * Construir formulario
   */
  buildForm(): void {
    this.form = this.fb.group(
      {
        name: [
          "",
          [
            Validators.required,
            Validators.pattern("[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*"),
            Validators.minLength(2),
          ],
        ],
        lastName: [
          "",
          [
            Validators.required,
            Validators.pattern("[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*"),
            Validators.minLength(2),
          ],
        ],
        email: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ),
          ],
          [checkEmailStored(this.authSrv)],
        ],
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/
            ),
          ],
        ],
        phoneNumber: [
          "",
          [
            Validators.required,
            Validators.pattern(/^\d+$/),
            Validators.minLength(9),
            Validators.maxLength(10),
          ],
        ],
        prefix: ["", [Validators.required]],
        repeatpassword: ["", [Validators.required]],
        term: [false, [Validators.requiredTrue]],
        referralCode: [""],
      },
      { validator: MustMatch("password", "repeatpassword") }
    );
  }

  showValue(field: string) {
    this.showPassword[field] = !this.showPassword[field];
  }

  async checkReferralCode(formData: any) {
    try {
      await this.spinner.show();

      /** Buscar código de referido */
      const findReferralCode =
        await this.web3Srv.vendor_referral_getInfoByCode_OC(
          formData.referralCode
        );
      console.log("findReferralCode", findReferralCode);

      this.spinner.hide();

      if (!findReferralCode.exist || !findReferralCode.status) {
        const askContinue = await this.sweetAlert2Srv.askConfirm(
          "form.checkReferralCodeMessage"
        );

        if (!askContinue) {
          return false;
        }

        /** Actualizar registro con código GENESIS */
        this.form.patchValue({
          referralCode: environment.appDefaultReferralCode,
        });
        formData.referralCode = environment.appDefaultReferralCode;
      }

      return true;
    } catch (err) {
      console.log("Error on FormRegisterUserComponent.checkReferralCode", err);
      return false;
    } finally {
      this.spinner.hide();
    }
  }

  /**
   * Al enviar formulario
   * @returns
   */
  async onSubmit() {
    try {
      this.submit = true;
      this.loading = true;
      const formData = this.form.value;
      // console.log("formData", formData);

      if (!this.form.valid) {
        this.form.markAllAsTouched();
        return;
      }

      /** Construir documento de usuario */
      const data = this.authSrv.buildUserDoc({
        name: `${formData.name}`.trim().toLowerCase(),
        lastName: `${formData.lastName}`.trim().toLowerCase(),
        email: `${formData.email}`.trim().toLowerCase(),
        password: `${formData.password}`.trim(),
        prefix: formData.prefix,
        phoneNumber: `${formData.phoneNumber}`.trim(),
        term: formData.term,
        referralCode: formData.referralCode,
      });
      // console.log("data", data);

      /** Verificar código de referido y actualizar en el formulario */
      const checkReferralCode = await this.checkReferralCode(data);
      if (!checkReferralCode) {
        return;
      }

      /** TODO: pendiente por habilitar */
      /** Ejecutar 2FA en proceso de registro */
      const run2FA = await this.temporalTokenSrv.runByEmail(
        this.form.value.email
      );
      // console.log("run2FA", run2FA);
      if (!run2FA.status) {
        // await this.sweetAlert2Srv.showError(run2FA.message);
        return;
      }

      await this.spinner.show();

      /** Crear usuario en sistema de autenticación */
      const afsUser: any = await this.authSrv.createUserWithEmailAndPassword(
        data.email,
        data.password
      );
      // console.log("afsUser", afsUser);

      /** Capturar UID del usuario */
      const uid = afsUser.user.uid;

      // /** Registrar usuario */
      await this.authSrv.store(`${uid}`, data);
      this.sweetAlert2Srv.showSuccess("form.successfulRegistration");

      /** TODO: Enviar mail de bienvenida */
      // await this.emailNotificationSrv.sendWelcomeNotification(data.name, data.email);

      /** Guardar identificador en el localStorage */
      this.authSrv.setLocalUID(uid);

      /** Obtener documento actualizado del usuario */
      const userDoc = await this.authSrv.getByUID(uid);
      const toParse = {
        email: userDoc.email,
        name: userDoc.name,
        avatar: userDoc.avatar,
        phoneNumber: userDoc.phoneNumber,
        wallet: userDoc.walletAddress,
      };

      localStorage.setItem("profile", JSON.stringify(toParse));
      this.form.reset();
      this.router.navigate(["home"]);
      return;
    } catch (err: any) {
      /**
       * todo:
       *
       * no funciona el beta se logea el usuario
       */
      console.log("Error on SignUpComponent.onSubmit", err);
      // alert("Error on SignUpComponent.onSubmit" + err.message);

      await this.sweetAlert2Srv.showError(err.message);
      this.authSrv.logout();
      // window.location.reload();
      // this.form.reset();
      return;
    } finally {
      this.loading = false;
      this.spinner.hide();
    }
  }
}
