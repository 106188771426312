import { Component, OnInit, ViewChild } from "@angular/core";
import { Observable, Subscription, catchError, map, of, tap } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MediaFileService } from "src/app/services/media-file.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { InputSingleImageComponent } from "src/app/shared/input-single-image/input-single-image.component";
import { ClipboardService } from "ngx-clipboard";
import { environment } from "src/environments/environment";
import { ModalContactFormComponent } from "src/app/shared/modal-contact-form/modal-contact-form.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-dashboard-user-card-information",
  templateUrl: "./dashboard-user-card-information.component.html",
  styleUrls: ["./dashboard-user-card-information.component.css"],
})
export class DashboardUserCardInformationComponent implements OnInit {
  @ViewChild(InputSingleImageComponent) inputFile!: InputSingleImageComponent;
  @ViewChild("modalContractForm") modalContactForm!: ModalContactFormComponent;

  public id!: any;
  public profile: any;
  public submit = false;

  public loading: boolean = false;
  public imgUpdate: any = false;
  public imgPreview: any;

  public userDoc$!: Observable<null | any>;

  public currentLanguage: string = "";
  public selectLanguageCache;

  constructor(
    private authSrv: AuthenticationService,
    private sweetAlert2Srv: Sweetalert2Service,
    private spinner: NgxSpinnerService,
    private mediaFileSrv: MediaFileService,
    private _clipboardSrv: ClipboardService,
    public translateSrv: TranslateService
  ) {
    this.id = this.authSrv.getLocalUID();
    this.getUser(this.id);
    this.selectLanguageCache = localStorage.getItem("language");
    if (this.selectLanguageCache) {
      this.translateSrv.use(this.selectLanguageCache);
    }
    this.currentLanguage = this.translateSrv.currentLang;
  }

  ngOnInit() {
    /** Obtener documento de usuario */
    this.userDoc$ = this.authSrv.userDoc$;
  }

  async getUser(id: any) {
    this.profile = await this.authSrv.getByUID(id);
  }

  async onAddImage() {
    this.inputFile.take();
  }

  /**
   * Al seleccionar imagen
   * @param files
   * @returns
   */
  async onSelectImages(files: any = []) {
    try {
      this.imgUpdate = files.toString();
      console.log(this.imgUpdate);

      return;
    } catch (error) {
      console.log("Error on AvatarComponent.onSelectImages", error);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  async saveAvatar() {
    try {
      const ask = await this.sweetAlert2Srv.askConfirm(
        "¿Estás seguro de actualizar la foto de perfil?"
      );
      if (!ask) {
        return;
      } else {
        await this.spinner.show();
        if (this.imgUpdate) {
          await this.mediaFileSrv.uploadFileWithCustomFoler(
            this.imgUpdate,
            "profile",
            "png"
          );
        } else {
          this.imgUpdate = false;
        }

        let data = {
          avatar: this.imgUpdate,
        };

        await this.authSrv.update(this.id, data);

        this.sweetAlert2Srv.showSuccess("Se ha actualizado la foto de perfil");
        this.getUser(this.id);
        window.location.reload();
      }
      return;
    } catch (error) {
      console.log("Error on AvatarComponent.onSelectImages", error);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  copyReferralLink(userDoc: any) {
    if (!userDoc.userCode) {
      return;
    }
    const url = environment.urlWeb + `/register?code=${userDoc.userCode}`;
    this._clipboardSrv.copy(url);
    this.sweetAlert2Srv.showToast("¡Enlace copiado!", "success");
  }

  launchModalContactForm() {
    this.modalContactForm.openModal();
  }

  changeLanguage(lang: string) {
    localStorage.setItem("language", lang);
    console.log(lang);
    this.translateSrv.use(lang);
    this.currentLanguage = lang;
    let vsubMenu2 = document.getElementById("languaje");
    vsubMenu2?.classList.toggle("menu-languaje-active");
    let vsubMenu3 = document.getElementById("languaje-mobile");
    vsubMenu3?.classList.toggle("menu-languaje-active");
  }
  menuLanguaje() {
    let vsubMenu2 = document.getElementById("languaje");
    vsubMenu2?.classList.toggle("menu-languaje-active");
    let vsubMenu3 = document.getElementById("languaje-mobile");
    vsubMenu3?.classList.toggle("menu-languaje-active");
  }
}
