import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class HdWalletService {

  public prefix = '/wallet-custodio';

  constructor(
    private apiSrv: ApiService
  ) { }

  async createWalletCustodio({uid}: {uid: string}){
    try {
      return await this.apiSrv.post(`${this.prefix}/create`, {uid});
    } catch (err) {
      console.log('Error on HdWalletService.createWalletCustodio', err);
      throw err;
    }
  }

  async requestWalletCustodioInfo({uid}: {uid: string}){
    try {
      return await this.apiSrv.post(`${this.prefix}/request-information`, {uid});
    } catch (err) {
      console.log('Error on HdWalletService.requestWalletCustodioInfo', err);
      throw err;
    }
  }

}
