import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatInputDirective } from './float-input.directive';
import { IntegerInputDirective } from './integer-input.directive';



@NgModule({
  declarations: [
    FloatInputDirective,
    IntegerInputDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FloatInputDirective,
    IntegerInputDirective
  ]
})
export class DirectivesModule { }
