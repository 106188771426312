<div class="container calculator-container">
  <div class="row justify-content-center align-items-center">
    <div class="col-12 col-md-6 d-flex justify-content-center">
      <div class="calculator">
        <div class="calculatorOperation mb-1">
          <p>
            <ng-container *ngIf="funcT">
              {{ funcT }}
            </ng-container>
          </p>
          <p>{{ calValue | number }}</p>
        </div>
        <div class="calculatorButtons">
          <input
            type="button"
            value="7"
            (click)="onClickValue('7', 'number')" />
          <input
            type="button"
            value="8"
            (click)="onClickValue('8', 'number')" />
          <input
            type="button"
            value="9"
            (click)="onClickValue('9', 'number')" />
          <input
            type="button"
            value="+"
            (click)="onClickValue('+', 'function')" />
        </div>

        <div class="calculatorButtons">
          <input
            type="button"
            value="4"
            (click)="onClickValue('4', 'number')" />
          <input
            type="button"
            value="5"
            (click)="onClickValue('5', 'number')" />
          <input
            type="button"
            value="6"
            (click)="onClickValue('6', 'number')" />

          <input
            type="button"
            value="-"
            (click)="onClickValue('-', 'function')" />
        </div>

        <div class="calculatorButtons">
          <input
            type="button"
            value="1"
            (click)="onClickValue('1', 'number')" />
          <input
            type="button"
            value="2"
            (click)="onClickValue('2', 'number')" />
          <input
            type="button"
            value="3"
            (click)="onClickValue('3', 'number')" />

          <input
            type="button"
            value="*"
            (click)="onClickValue('*', 'function')" />
        </div>

        <div class="calculatorButtons">
          <input
            type="button"
            value="0"
            (click)="onClickValue('0', 'number')" />

          <input
            type="button"
            value="/"
            (click)="onClickValue('/', 'function')" />
          <input
            type="button"
            value="="
            (click)="onClickValue('=', 'function')" />

          <input
            type="button"
            value="C"
            (click)="onClickValue('c', 'function')" />
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <div class="card bg-dark text-light" id="scroll">
        <div
          class="card-title p-3 mb-0 fw-bold"
          [innerHTML]="'general.history' | translate"></div>
        <div
          class="card-body scroll py-2"
          data-spy="scroll"
          data-target="#scroll"
          #mostrarOperciones></div>
      </div>

      <div class="mt-3 d-flex justify-content-center">
        <button
          class="btn btn-outline-danger btn-lg mb-2"
          style="text-align: center"
          (click)="clearHistory()">
          <i class="fa fa-trash-o" aria-hidden="true"></i>
          {{ "general.clearHistory" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
