import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseApiService {

  public prefix = '/purchase';

  constructor(
    private apiSrv: ApiService
  ) { }

  /**
   * @dev Método para obtener el valor del portafolio de un usuario
   * @param param0 
   * @returns 
   */
  async user_getPorfolioValue({uid}: {uid: any}){
    try {
      return await this.apiSrv.post(`${this.prefix}/user/portfolio-value`, {uid});
    } catch (err) {
      console.log('Error on PurchaseApiService.user_getPorfolioValue', err);
      throw err;
    }
  }

}
