import { Component, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { Subject } from "rxjs";
import { getFormErrors } from "src/app/helpers/formErrors.helper";
import { EmailNotificationApiService } from "src/app/services/api/email-notification-api.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-form-contact",
  templateUrl: "./form-contact.component.html",
  styleUrls: ["./form-contact.component.css"],
})
export class FormContactComponent implements OnInit {
  @Output() onSubmitFormEvent = new Subject<any>();

  public form!: FormGroup;

  public vm: any = {
    names: [
      { type: "required", message: "form.validations.isRequired" },
      { type: "pattern", message: "form.validations.lettersOnly" },
      { type: "minlength", message: "form.validations.minimum3Letters" },
    ],
    email: [
      { type: "required", message: "form.validations.isRequired" },
      { type: "pattern", message: "form.validations.email" },
    ],
    description: [
      { type: "required", message: "form.validations.isRequired" },
      { type: "minlength", message: "form.validations.minimum10Letters" },
    ],
  };

  public submitted = false;

  public showSubmitLoaderButton = false;

  constructor(
    private fb: FormBuilder,
    private notificationSrv: EmailNotificationApiService,
    private sweetAlert2Srv: Sweetalert2Service
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      names: [
        "",
        [
          Validators.required,
          // regex solo letras
          Validators.pattern(/^[a-zA-Z\s]*$/),
          Validators.minLength(3),
        ],
      ],
      email: [
        "",
        [
          Validators.required,
          // regex email
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      description: ["", [Validators.required, Validators.minLength(10)]],
    });
  }

  get f() {
    return this.form.controls;
  }

  async onSubmit() {
    try {
      this.submitted = true;
      this.showSubmitLoaderButton = true;

      if (!this.form.valid) {
        const formErrors = getFormErrors(this.form);
        console.log("formErrors", formErrors);
        return;
      }

      const formData = this.form.value;
      const data = {
        names: `${formData.names}`.trim().toLowerCase(),
        email: `${formData.email}`.trim().toLowerCase(),
        description: `${formData.description}`.trim().toLowerCase(),
      };

      this.form.disable();

      await this.notificationSrv.quickNotification({
        subject: "Contracto " + moment().format("YYYY-MM-DD HH:mm:ss"),
        email: environment.contactEmail,
        greeting: "Mensaje de contacto",
        messageBody: [
          { type: "line", text: `Nombres: ${data.names}` },
          { type: "line", text: `Correo: ${data.email}` },
          { type: "line", text: `Mensaje: ${data.description}` },
        ],
      });

      this.onSubmitFormEvent.next(data);

      this.form.patchValue({
        names: "",
        email: "",
        description: "",
      });
      this.submitted = false;

      this.sweetAlert2Srv.showToast(
        "contact.thankYouForContactingUsWeWillGetBackToYouAsSoonAsPossible",
        "success"
      );
      return;
      // this.onSubmitFormEvent.next(this.form.value);
    } catch (err) {
      console.log("Error on FormContactComponent.onSubmit", err);
      return;
    } finally {
      this.showSubmitLoaderButton = false;
      this.form.enable();
    }
  }
}
