import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, Query } from "@angular/fire/firestore";
import firebase from "firebase/app";
import { Router } from "@angular/router";
// import moment from 'moment';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  lastValueFrom,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from "rxjs";
import { environment } from "src/environments/environment";
import {
  handlerArrayResult,
  handlerObjectResult,
} from "../helpers/model.helper";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  
  public collection = "users";
  public uid$: Observable<null | any>;
  public userDoc$: Observable<null | any>;
  // public userDoc$ = new BehaviorSubject<null | any>(null);
  // public authState: Observable<any>;

  /** Observable para actualizar segmento de login y sign-up */
  public authOption$ = new BehaviorSubject<string>("sign-up");

  constructor(
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    private router: Router,
    private _http: HttpClient
  ) {
    /** Observable para obtener identificador del usuario */
    this.uid$ = this.afAuth.authState.pipe(
      map((user) => (user ? user.uid : null))
    );

    /** Observable para obtener documento de usuario */
    this.userDoc$ = this.afAuth.authState.pipe(
      map((user) => (user ? user.uid : null)),
      distinctUntilChanged(),
      switchMap((uid: any) => {
        if (!uid) {
          return of({ exist: false });
        }
        return this.afs
          .collection(this.collection)
          .doc(uid)
          .valueChanges({ idField: "_id" })
          .pipe(
            map((data: any) =>
              data ? { ...data, exist: true } : { exist: false }
            )
          );
      }),
      catchError((err) => of({ exist: false }))
      // switchMap(async (uid: any) => {
      //   return uid ? await this.getByUID(uid) : null;
      // })
    );
  }

  /**
   * Construir documento de usuario
   * @param params
   * @returns
   */
  buildUserDoc(params: any = {}) {
    return {
      email: params.email || null,
      avatar: params.avatar || null,
      password: params.password || null,
      name: params.name || null,
      lastName: params.lastName || null,
      prefix: params.prefix || null,
      phoneNumber: params.phoneNumber || null,
      configCode: false,
      createWallet: null,
      userCode: null,
      walletAddress: params.walletAddress || null,
      kyc: null,
      socialMedia: false,
      google: false,
      facebook: false,
      icloud: false,
      referralCode: params.referralCode || environment.appDefaultReferralCode,
      dev_balance: 0,
      prod_balance: 0,
    };
  }

  /**
   * Registrar documento de usuario
   * @param data
   * @returns
   */
  async store(docId: string, data: any) {
    const fmt = Object.assign({}, data);
    return await this.afs.collection(this.collection).doc(docId).set(fmt);
  }

  /**
   * Actualizar
   * @param docId
   * @param data
   * @returns
   */
  async update(docId: string, data: any) {
    return await this.afs.collection(this.collection).doc(docId).update(data);
  }

  /**
   * Obtener listado completo
   * @returns
   */
  async getAll() {
    const snapshot = await this.afs.collection(this.collection).ref.get();
    return await handlerArrayResult(snapshot);
  }

  /**
   * Obtener a través de identificador
   * @param uid
   * @returns
   */
  async getByUID(uid: any) {
    const snapshot = await lastValueFrom(
      this.afs.collection(this.collection).doc(uid).get()
    );
    return await handlerObjectResult(snapshot);
  }

  getByUIDToObservable(uid: any, idField = "_id") {
    return this.afs
      .collection(this.collection)
      .doc(uid)
      .valueChanges()
      .pipe(
        map((data: any) =>
          data
            ? { ...data, [idField]: uid, exist: true }
            : { [idField]: uid, exist: false }
        ),
        catchError((err) => of({ [idField]: uid, exist: false }))
      );
  }

  /**
   * Obtener documento de perfil desde el localStorage
   * @returns
   */
  getLocalProfile() {
    const profileToParse = window.localStorage.getItem("profile") || "{}";
    return JSON.parse(profileToParse);
  }

  /**
   * Obtener identificador del usuario desde el localStorage
   * @returns
   */
  getLocalUID() {
    return window.localStorage.getItem("uid") || null;
  }

  /**
   *
   * @param uid
   */
  setLocalUID(uid: any) {
    window.localStorage.setItem("uid", uid);
  }

  /**
   * @dev Obtener el identificador del usuario autenticado
   * @returns 
   */
  async sessionUid(): Promise<string | null> {
    const user = await this.afAuth.currentUser;
    return user?.uid || null;
  }

  /**
   * Obtener identificador de usuario como una promesa
   * @returns
   */
  async getUIDPromise() {
    return new Promise((resolve, reject) => {
      this.uid$.subscribe((uid) => {
        resolve(uid);
      });
    });
  }

  /**
   * @dev Actualizar contador de document
   * @param docId               Identificador del documento
   * @param field               Campo a actualizar
   * @param amount              Cantidad a incrementar
   */
  async updateCounter(docId: string, field: string, amount: number) {
    const ref = this.afs.collection(this.collection).doc(docId);
    await ref.update({ [field]: firebase.firestore.FieldValue.increment(amount) });
  }

  /**
   * Crear usuario a través del correo y contraseña
   * @param email
   * @param password
   * @returns
   */
  async createUserWithEmailAndPassword(email: string, password: string) {
    return await this.afAuth.createUserWithEmailAndPassword(email, password);
    // this.afAuth.createUserWithEmailAndPassword(email, password);
    // return new Promise((resolve, reject) => {
    //   firebase
    //     .auth()
    //     .createUserWithEmailAndPassword(email, password)
    //     .then((user) => {
    //       resolve(user);
    //     })
    //     .catch((error) => {
    //       reject(error);
    //     });
    // });
  }

  async signInWithEmail(email: string, password: string) {
    return await this.afAuth.signInWithEmailAndPassword(email, password);
    // return this.afAuth.auth.signInWithEmailAndPassword(
    //   credentials.email,
    //   credentials.password
    // );
  }

  /**
   * Ingresar con Google
   * @returns
   */
  async signGoogle() {
    return await this.afAuth.signInWithPopup(
      new firebase.auth.GoogleAuthProvider()
    );
  }

  /**
   * Ingresar con Google
   * @returns
   */
  async signFacebook() {
    return await this.afAuth.signInWithPopup(
      new firebase.auth.FacebookAuthProvider()
    );
  }

  /**
   * Validar si el email ya se encuentra registrado
   * @param email
   * @returns
   */
  async checkEmail(email: string) {
    const snapshot = await lastValueFrom(
      this.afs
        .collection(this.collection, (ref) => ref.where("email", "==", email))
        .get()
    );
    const result = await handlerArrayResult(snapshot);
    return result.length > 0 ? true : false;
  }

  /**
   * Actualizar email de usuario
   * @param uid
   * @param email
   * @returns
   */
  async updateUserEmail(uid: string, email: string) {
    try {
      const url = `${environment.API_URL}profile/change-email`;

      const result = await lastValueFrom(this._http.post(url, { uid, email }));
      console.log("result", result);
      return true;
    } catch (err) {
      console.log("Error on AuthenticationService.updateUserEmail", err);
      throw err;
    }
  }

  /**
   * Actualizar contraseña de usuario
   * @param uid
   * @param password
   * @returns
   */
  async updateUserPassword(uid: string, password: any) {
    try {
      const url = `${environment.API_URL}/admin/reset-user-password`;

      const result = await lastValueFrom(
        this._http.post(url, { uid, password })
      );
      console.log("result", result);
      return true;
    } catch (err) {
      console.log("Error on AuthenticationService.updateUserEmail", err);
      throw err;
    }
  }

  /**
   * Cerrar sesión Usuario
   */
  async logout() {
    await this.afAuth.signOut();
    // window.localStorage.clear();
    window.localStorage.removeItem("profile");
    window.localStorage.removeItem("uid");
    // this.userDoc$.next(null);
    // this.router.navigate(["/sign-in"]);
    window.location.reload();
  }

  /**
   * Cerrar sesión Admin
   */
  async logoutAdmin() {
    await this.afAuth.signOut();
    window.localStorage.clear();
    // this.userDoc$.next(null);
    this.router.navigate(["/admin/login"]);
  }

  getDynamicCount(where: any[] = []): Observable<number> {
    return this.afs.collection(this.collection,
      (ref) => {
        let query: Query = ref;
        for (const row of where) { query = query.where(row.field, row.condition, row.value); }
        return query;
      }
    )
    .valueChanges()
    .pipe( map((data) => data.length) );
  }

  /**
   * Obtener listado dinamico
   * @param where
   * @param where.field
   * @param where.condition
   * @param where.value
   * @param opts
   * @param opts.idField
   * @param opts.orderBy
   * @param opts.orderBy.field
   * @param opts.orderBy.order
   *
   * @returns
   */
  getDynamic(where: any[] = [], opts: any = {}): Observable<any[]> {
    const {
      idField = "_id",
      startAt = null,
      startAfter = null,
      endAt = null,
      endBefore = null,
      orderBy = [],
      limit = null,
      limitToLast = null,
    } = opts;

    return this.afs.collection(this.collection,
      (ref) => {
        let query: Query = ref;
        for (const row of where) { query = query.where(row.field, row.condition, row.value); }

        for (const order of orderBy) { query = query.orderBy(order.field, order.order); }

        if (startAt) { query = query.startAt(startAt); }

        if (typeof startAfter === 'string') { query = query.startAfter(startAfter); }

        if (Array.isArray(startAfter)) { query = query.startAfter(...startAfter); }

        if (typeof endAt === 'string') { query = query.endAt(endAt); }

        if (Array.isArray(endAt)) { query = query.endAt(...endAt); }

        if (endBefore) { query = query.endBefore(endBefore); }

        if (limit) { query = query.limit(limit); }

        if (limitToLast) { query = query.limitToLast(limitToLast); }

        return query;
      }
    ).valueChanges({ idField });
  }

  async getDynamicToPromise(where: any[] = [], opts: any = {}): Promise<any[]> {
    try {
      const {
        idField = "_id",
        startAt = null,
        endAt = null,
        orderBy = [],
      } = opts;

      const snapshot = await this.afs
        .collection(this.collection, (ref) => {
          let query: Query = ref;
          for (const row of where) {
            query = query.where(row.field, row.condition, row.value);
          }

          for (const order of orderBy) {
            query = query.orderBy(order.field, order.order);
          }

          if (startAt) {
            query = query.startAt(startAt);
          }

          if (endAt) {
            query = query.endAt(endAt);
          }

          return query;
        })
        .get()
        .toPromise();

      return await handlerArrayResult(snapshot, { idField });
    } catch (err) {
      console.log("Error on AuthenticationService.getDynamicToPromise", err);
      return [];
    }
  }

  search(searchSelect: string, start: string, end: string): Observable<any> {
    return this.afs
      .collection(this.collection, (ref) =>
        ref.limit(100).orderBy(searchSelect).startAt(start).endAt(end)
      )
      .valueChanges();
  }

  /**
   * enviar correo para recuperacion de contrasena
   */
  async getByEmailAddress(email: any, opts = {}): Promise<any | null> {
    const snapshot = await lastValueFrom(
      this.afs
        .collection("users", (ref) => ref.where("email", "==", email).limit(1))
        .get()
    );

    const result = await handlerArrayResult(snapshot, opts);
    return result.length > 0 ? result.pop() : null;
  }
}
