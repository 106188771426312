import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Web3Service } from "src/app/services/contract/web3.service";
import {
  Observable,
  Subscription,
  catchError,
  distinctUntilChanged,
  from,
  map,
  of,
  combineLatest,
} from "rxjs";
import { getFormErrors } from "src/app/helpers/formErrors.helper";
import { fromWei, toWei } from "src/app/helpers/utils";
import { CollectionService } from "src/app/services/firebase/collection.service";
import { LocalCartService } from "src/app/services/local-cart.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Sweetalert2stepsService } from "src/app/services/sweetalert2steps.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { PurchaseService } from "src/app/services/firebase/purchase.service";
import { UsersCartService } from "src/app/services/usersCart.service";
import BigNumber from "bignumber.js";
import { PurchaseApiService } from "src/app/services/api/purchase-api.service";
const DEAFULT_PORFOLIO_VALUE = { uid: null, records: 0, vp: 0, ra: 0 };
@Component({
  selector: "app-form-project-your-investment-user",
  templateUrl: "./form-project-your-investment-user.component.html",
  styleUrls: ["./form-project-your-investment-user.component.css"],
})
export class FormProjectYourInvestmentUserComponent implements OnInit {
  /* variables para el calculo de inversion */

  public form: FormGroup; // variable de formulario de inveriones
  public vm: any = {
    collectionId: [
      { type: "required", message: "form.validations.ownershipIsRequired" },
    ],
    nroTokens: [
      { type: "required", message: "form.validations.numberOfTokensRequired" },
      {
        type: "min",
        message: "form.validations.theNumberOfTokensMustBeGreaterThan0",
      },
    ],
  }; // validaciones del formulario de inversiones

  public collections: any[] = []; // variable para las propiedades
  public submitted = false; // variable del submit  del formulario de inversion
  private sub$!: Subscription; // variable para la subscription
  public listCard!: any; // variable para guardar las propiedades en el carrito
  public totalGananciasList: number = 0; // variable para guardar las ganancias totales del carrito
  public parseListCard: any = [];

  /* variables para La compra de propiedad */

  public formBuy!: FormGroup; // variable de formulario de compra

  public vmBuy: any = {
    whiteListToken: [
      { type: "required", message: "form.validations.paymentMethodIsRequired" },
    ],
  }; // validaciones del formulario de comprar

  //variables para la white list
  public nativeCurrency = environment.chain.nativeCurrency;
  public blockExplorerUrls = environment.chain.blockExplorerUrls[0];
  public tokenSelected: any; //variables para el token seleccionado para la compra
  public whitelist: any[] = []; //variables para guardar la lista de tokens

  public propertie: any; //variables para guardar la propieda a comprar
  public submit = false; // variable del submit  del formulario de comprar
  public loading: boolean = false; // variable de carga

  // variables para el usuario
  public userDoc$!: Observable<null | any>;
  private idUser: any;
  public user: any;
  public uid$!: Observable<null | string>;
  public walletBuy: any;

  //variables para la wallets
  public dataStatus$!: Observable<any>;

  private indexPropertieSelect: any; // variable para guardar el index de la propiedad en el carrito seleccionado para comprar

  constructor(
    private collectionSrv: CollectionService,
    private fb: FormBuilder,
    private localCartSrv: LocalCartService,
    private spinner: NgxSpinnerService,
    private sweetAlert2StepSrv: Sweetalert2stepsService,
    private sweetAlert2Srv: Sweetalert2Service,
    private web3Service: Web3Service,
    private authSrv: AuthenticationService,
    private purchaseService: PurchaseService,
    private usersCartService: UsersCartService,
    private purchaseApiSrv: PurchaseApiService
  ) {
    /* contruccion del formulario de inversiones */
    this.form = this.fb.group({
      collectionId: ["", [Validators.required]],
      profit: 0,
      price: 0,
      nroTokens: [0, [Validators.required, Validators.min(1)]],
      totales: 0,
      gananciaAnual: 0,
      gananciaMensual: 0,
      gananciaAnualFive: 0,
    });

    /* contruccion del formulario de compra */
    this.buildForm();
  }

  ngOnInit() {
    /* obtener tokens */
    this.getTokens();

    /* obtener usuario */
    // this.getUser();

    this.sub$ = this.authSrv.userDoc$
      .pipe(distinctUntilChanged())
      .subscribe((user: any) => {
        // console.log('user', user);
        /** Capturar usuario */
        this.user = user;

        /** Cargar lista de deseos */
        this.loadInfo();
      });

    /* obetener status de la cuenta de wallet */
    this.web3Service.accountStatus$
      .pipe(
        map((data: any[]) => (data.length > 0 ? data[0] : null)),
        map((addr: any) => {
          if (!addr) return null;
          return addr;
        }),
        catchError((err) => {
          return of(null);
        })
      )
      .subscribe((data) => {
        this.walletBuy = data;
        // console.log(this.walletBuy);
      });

    this.dataStatus$;

    /** Obtener listado de colecciones */
    this.sub$ = this.collectionSrv
      .getDynamic([
        { field: "status", condition: "in", value: ["preview", "opening"] },
      ])
      .subscribe((data: any[]) => {
        this.collections = data;
      });

    /** Escuchar evento de cambio de colección */
    this.sub$.add(
      this.form
        .get("collectionId")
        ?.valueChanges.subscribe((collection: string) => {
          // console.log("collection", collection);

          const nroTokens = this.form.get("nroTokens")?.value;
          // console.log("nroTokens", nroTokens);

          if (collection.length == 0) {
            this.form.patchValue({
              profit: 0,
              price: 0,
              nroTokens: 0,
              totales: 0,
            });
            return;
          }

          const collectionDoc = this.collections.find(
            (item: any) => item._id == collection
          );
          // console.log("collectionDoc", collectionDoc);

          this.form.patchValue({
            profit: collectionDoc?.estimatedProfitability,
            price: Number(fromWei(collectionDoc?.price)),
            nroTokens: nroTokens,
            totales: Number(fromWei(collectionDoc?.price)) * Number(nroTokens),
          });
        })
    );

    /** escuchar evento de cambio en nro de tokens */
    this.sub$.add(
      this.form
        .get("nroTokens")
        ?.valueChanges.subscribe((nroTokens: string) => {
          // console.log("nroTokens", nroTokens);

          const collectionId = this.form.get("collectionId")?.value;
          // console.log("collectionId", nroTokens);

          if (collectionId.length == 0) {
            this.form.patchValue({ totales: 0 });
            return;
          }

          const price = this.form.get("price")?.value;
          // console.log("price", nroTokens);
          // total invertido
          const priceTotal = Number(price) * Number(nroTokens);

          const profit = this.form.get("profit")?.value;
          let costoInversion = priceTotal;
          let porcentajeGanancia = profit;
          //ganancia anual
          const gananciaAnual = (costoInversion * porcentajeGanancia * 1) / 100;
          //ganancia mensual
          const gananciaMensual: number =
            (costoInversion * porcentajeGanancia * 0.1) / 100;
          //ganancia anual 5
          const gananciaAnualFive: number =
            (costoInversion * porcentajeGanancia * 5) / 100;

          this.form.patchValue({
            totales: priceTotal,
            gananciaAnual: gananciaAnual + priceTotal,
            gananciaMensual: gananciaMensual + priceTotal,
            gananciaAnualFive: gananciaAnualFive + priceTotal,
          });
        })
    );
  }

  /* codigo para la inversion */

  // obtener datos del formulaio de inversiones
  get f() {
    return this.form.controls;
  }

  /**
   * Incrementar Valor de input de números
   * @returns
   */
  addTokens() {
    const _v = isNaN(this.form.get("nroTokens")?.value)
      ? 1
      : Number(this.form.get("nroTokens")?.value);
    const nroTokens = _v + 1;

    this.form.patchValue({
      nroTokens: nroTokens,
    });
  }

  /**
   * Decrementar Valor de input de números
   */
  restTokens() {
    const _v = isNaN(this.form.get("nroTokens")?.value)
      ? 1
      : Number(this.form.get("nroTokens")?.value);
    const nroTokens = _v - 1 <= 0 ? 0 : _v - 1;

    this.form.patchValue({
      nroTokens: nroTokens,
    });
  }

  // guardar propieda en el carrito de propiedades
  async onSubmit() {
    try {
      this.submitted = true;
      const formData = this.form.value;

      /** Si esl formulario tiene algun error - Mostrar error con una alerta */
      if (!this.form.valid) {
        const formErrors = getFormErrors(this.form);
        const { key, errors } = formErrors[0];
        const ferr = Object.keys(errors)[0];
        // console.log("formErrors", formErrors);

        const findMessage = this.vm[key].find((item: any) => item.type == ferr);
        // console.log("findMessage", findMessage);

        this.sweetAlert2Srv.showWarning(findMessage.message);
        return;
      }

      /** Crear documento por si no existe */
      this.localCartSrv.buildAndStore();

      /** Obtener documento de la colección */
      const collectionDoc = this.collections.find(
        (item: any) => item._id == formData.collectionId
      );
      // console.log("collectionDoc", collectionDoc);
      //ganancia anual
      let costoInversion = formData.totales;
      let porcentajeGanancia = formData.profit;
      const gananciaAnual: number = (porcentajeGanancia / 100) * costoInversion;
      /** Capturar tiempo actual */
      const createdAt = moment().valueOf();
      const data = {
        // ...collectionDoc,
        collectionId: formData.collectionId,
        priceParsed: Number(fromWei(collectionDoc.price)),
        nroTokens: formData.nroTokens,
        totales: formData.totales,
        gananciaAnual: formData.gananciaAnual,
        gananciaMensual: formData.gananciaMensual,
        gananciaAnualFive: formData.gananciaAnualFive,
        gananciaTotal: formData.totales + formData.gananciaAnual,
        userId: this.user._id,
        createdAt: createdAt,
      };

      let result = await this.usersCartService.store(data);
      // console.log(result);

      this.getTotalList(this.listCard);

      this.submitted = false;
      this.form.patchValue({
        collectionId: "",
        profit: 0,
        price: 0,
        nroTokens: 0,
        totales: 0,
        gananciaAnual: 0,
        gananciaAnualFive: 0,
      });

      // console.log("Try to buy tokens", formData);
      this.sweetAlert2Srv.showSuccess(
        "projectYourInvestment.calculatedInvestment"
      );
      return;
    } catch (err) {
      console.log("Error on FormProjectYourInvestmentComponent.onSubmit", err);
      return;
    }
  }

  // editar propiedad en el carrito de propiedades
  async edit(propertie: any) {
    try {
      await this.usersCartService.remove(propertie._id);
      this.form.patchValue({
        collectionId: propertie.addr,
        profit: propertie.estimatedProfitability,
        price: Number(fromWei(propertie.price)),
        nroTokens: propertie.nroTokens,
        totales: Number(fromWei(propertie.price)) * Number(propertie.nroTokens),
      });
    } catch (error) {
      console.log(error);
      this.sweetAlert2Srv.showError("errors.errorWhileEditing");
    }
  }

  // eliminar propiedad en el carrito de propiedades
  async clear(propertie: any) {
    try {
      this.usersCartService.remove(propertie._id);
      this.getTotalList(this.listCard);
      this.indexPropertieSelect = "";
    } catch (error) {
      console.log(error);
      this.sweetAlert2Srv.showError("errors.errorWhileDeleting");
    }
  }

  // obtener el total invetidos de las propiedades en el carrito de propiedades
  getTotalList(list: any) {
    // console.log(list);

    const propertiesPricesUSD = list
      .map((item: any) => {
        return item.gananciaAnual;
      })
      .reduce((a: any, b: any) => a + b);
    this.totalGananciasList = propertiesPricesUSD;

    // console.log(this.totalGananciasList);
  }

  /* codigo para la compra de propiedades */

  // funcion obetener tokens TODO: corregir ejecución
  async getTokens() {
    try {
      this.sub$ = from(this.web3Service.vendor_whitelist_tokensListoc())
        .pipe(
          map((data: any[]) =>
            data
              .map((item: any, index: number) => ({ ...item, cId: index }))
              .filter((item: any) => item.active)
          ),
          catchError((err) => of([]))
        )
        .subscribe((data: any[]) => {
          this.whitelist = data;
        });
    } catch (error) {
      this.sweetAlert2Srv.showError("errors.errorWhenDisplayingTokens");
      console.log(error);
    }
  }

  /**
   * @dev Cargar lista de deseos
   */
  loadInfo() {
    this.sub$.add(
      combineLatest([
        /** Cargar listado de wishlist del usuario */
        this.usersCartService
          .getDynamic([
            { field: "userId", condition: "==", value: this.user._id },
          ])
          .pipe(catchError((err) => of([]))),
        // this.collectionSrv.getDynamic([ { field: "status", condition: "in", value: ["preview", "opening"]} ])

        /** Cargar listado de colecciones registradas */
        this.collectionSrv.getDynamic([]).pipe(catchError((err) => of([]))),
      ])
        .pipe(
          map(([wishList, collections]) => {
            const wishListParsed =
              wishList.length > 0
                ? wishList
                    .map((item: any) => {
                      const find = collections.find(
                        (collection) => collection._id === item.collectionId
                      );
                      item.propertie = find ? find : { status: "closed" };
                      return item;
                    })
                    .filter((item: any) =>
                      ["preview", "opening"].includes(item.propertie.status)
                    )
                    .map((item: any) => {
                      const { propertie, nroTokens } = item;
                      const { price: priceWei, estimatedProfitability } =
                        propertie;

                      const profit = estimatedProfitability;
                      const price = fromWei(priceWei);
                      const totales = new BigNumber(nroTokens)
                        .multipliedBy(price)
                        .toNumber();
                      const gananciaAnual = new BigNumber(totales)
                        .multipliedBy(profit)
                        .dividedBy(100)
                        .plus(totales)
                        .toNumber();
                      const gananciaMensual = new BigNumber(gananciaAnual)
                        .dividedBy(12)
                        .plus(totales)
                        .toNumber();
                      const gananciaAnualFive = new BigNumber(gananciaAnual)
                        .multipliedBy(5)
                        .plus(totales)
                        .toNumber();

                      return {
                        ...item,
                        totales,
                        gananciaAnual,
                        gananciaMensual,
                        gananciaAnualFive,
                      };
                    })
                : [];

            return {
              wishList: wishListParsed,
              collections,
            };
          })
          // tap((data: any) => console.log('data', data))
        )
        .subscribe((data) => {
          /** Cargar listado de wishlist */
          this.listCard = data.wishList;
          if (this.listCard.length > 0) {
            this.getTotalList(this.listCard);
          } else {
            this.listCard = null;
          }
        })
    );
  }

  //funcion obtener usuario
  async getUser() {
    try {
      /** Escuchar si existe uid en la sesión */
      this.uid$ = this.authSrv.uid$;
      this.sub$ = this.authSrv.uid$.subscribe(async (res: any) => {
        // console.log(res);
        /** Obtener id usuario */
        if (res) {
          this.idUser = res;
          /** Obtener usuario */
          this.user = await this.authSrv.getByUID(this.idUser);

          if (this.idUser) {
            /** obtener lista de propiedades el carrito de la base de datos */
            let userCart = await this.usersCartService
              .getDynamic([
                {
                  field: "userId",
                  condition: "==",
                  value: this.idUser,
                },
              ])

              .subscribe((data) => {
                this.listCard = data;

                console.log(data);

                if (this.listCard.length > 0) {
                  this.getTotalList(this.listCard);
                } else {
                  this.listCard = null;
                }
              });
            // let userCart;
          }
        } else {
          this.idUser = null;
        }
      });
    } catch (error) {
      this.sweetAlert2Srv.showError("errors.errorGettingTheUser");
      console.log(error);
    }
  }

  //seleccionar token
  onChangeWhitelist(e: Event) {
    const paymentMethod = this.formBuy.value.whiteListToken;
    // console.log(paymentMethod);

    if (paymentMethod === "TDC") {
      this.sweetAlert2Srv.showWarning(
        "Metodo de pago no disponible de momento"
      );
      this.form.patchValue({ whiteListToken: "" });
      return;
    }

    this.tokenSelected = this.whitelist.find(
      (item: any) => item.addr === paymentMethod
    );
  }

  // seleccionar la propiedad a comprar
  buySelect(propertie: any) {
    this.indexPropertieSelect = propertie;
    this.propertie = propertie;
    // console.log(this.propertie);
  }

  // obtener datos del formulaio de comprar
  get fBuy() {
    return this.formBuy.controls;
  }

  /**
   * Construir formulario de compra
   */
  buildForm() {
    this.formBuy = this.fb.group({
      whiteListToken: ["", [Validators.required]],
    });
  }

  //  comprar propiedad
  async buy() {
    // console.log(this.propertie);

    try {
      this.submit = true;
      this.loading = true;
      const formData = this.formBuy.value;
      // console.log("formData", formData);
      if (!this.formBuy.valid) {
        this.form.markAllAsTouched();
        return;
      }

      await this.spinner.show();

      const collectionPrice = fromWei(this.propertie.price);
      // console.log("collectionPrice", collectionPrice);

      /** Calcular Amount to Pay - Monto a Pagar */
      const atp = await this.web3Service.vendor_oracle_parseUSDtoToken_OFFCHAIN(
        // toWei(Number(fromWei(this.propertie.price)) * Number(formData.amount)),
        toWei(Number(collectionPrice) * Number(this.propertie.nroTokens)),
        this.tokenSelected.addr
      );
      // console.log("atp", atp);

      /** Obtener UID de la sesión actual */
      const uid = await this.authSrv.getUIDPromise();
      // console.log("uid", uid);

      /** Obtener documento de usuario */
      const userDoc = await this.authSrv.getByUID(uid);
      // console.log("userDoc", userDoc);

      /** Válidar si posee wallet registrada */
      if (!userDoc || !userDoc.walletAddress) {
        this.sweetAlert2Srv.showWarning("errors.userNoWalletRegister");
        return;
      }

      /** Válidar wallet conectada con wallet registrada */
      if (userDoc.walletAddress !== this.web3Service.accounts[0]) {
        this.sweetAlert2Srv.showWarning(
          "errors.userWalletConnectingIsNoRegister"
        );
        return;
      }

      this.spinner.hide();

      // console.log("this.propertie", this.propertie);
      // console.log("this.tokenSelected", this.tokenSelected);

      /** Construir datos para la compra */
      const data = {
        cIdx: this.propertie.collectionId,
        token: this.tokenSelected.addr,
        code: userDoc.referralCode,
        amount: Number(this.propertie.nroTokens),
      };
      // console.log("data", data);

      //seleccionar el token elegido
      const method = this.tokenSelected.isNative
        ? "vendor_buy_buyNative"
        : "vendor_buy_buyWithToken";
      // console.log("Method", method);

      const params = this.tokenSelected.isNative
        ? [data.cIdx, this.tokenSelected.addr, data.amount, data.code, atp]
        : [data.cIdx, this.tokenSelected.addr, data.amount, data.code];
      // console.log("params", params);

      const message = "Comprar";
      const result = this.tokenSelected.isNative
        ? await this.sweetAlert2StepSrv.showStepsNative({
            actionMessage: `${message} ${data.amount} NFTs?`,
            checkBalanceParams: { amount: atp },
            contractParams: { method: method, params: params },
          })
        : await this.sweetAlert2StepSrv.showStepsWithApproved({
            actionMessage: `${message} ${data.amount} NFTs?`,
            checkBalanceParams: {
              contract: this.tokenSelected.addr,
              amount: atp,
            },
            approvedParams: [
              this.tokenSelected.addr,
              environment.vendorAddress,
              atp,
            ],
            contractParams: { method: method, params: params },
          });

      if (!result.status) {
        return await this.sweetAlert2StepSrv.showBasicAlert(
          result.data.message,
          "error"
        );
      } else {
        /** Capturar hora de creación */
        const createdAt = moment().valueOf();

        /** Construir datos de la compra para la base de datos */
        const data = {
          collectionId: this.propertie.collectionId,
          collectionName: this.propertie.name,
          CollectionDescription: this.propertie.description,
          collectionSymbol: this.propertie.symbol,
          collectionAddress: this.propertie.addr,
          collectionNumberTokens: Number(this.propertie.nroTokens),
          collectionPrice: this.propertie.price,
          collectionPriceToPay: atp,
          collectionPriceUSD: this.propertie.totales,
          collectionEstimatedProfitability:
            this.propertie.estimatedProfitability,
          collectionImg: this.propertie.gallery[0].url,
          codeReferedBuy: "codeprueba",
          userBuyId: this.idUser,
          userBuyName: this.user.name,
          userBuyEmail: this.user.email,
          userBuyWallet: this.user.walletAddress,
          userBuyPhoneNumber: this.user.phoneNumber,
          txHash: result.data.transactionHash,
          createdAt: createdAt,
        };

        //guardar compra en la base de datos
        let idPurchase = await this.purchaseService.store(data);
        // console.log(idPurchase);

        /** Desconectar sesión web3 */
        this.web3Service.logout(false);

        await this.sweetAlert2StepSrv
          .showAlertWithTxHash({ transactionHash: result.data.transactionHash })
          .then((result) => {
            this.submit = false;
            this.form.patchValue({
              numberTokens: "",
              amount: 0,
              whiteListToken: "",
            });
            this.tokenSelected = null;
          });
        return await this.clear(this.indexPropertieSelect);
      }
    } catch (err: any) {
      console.log("Error on createtask.onSubmit", err);
      await this.sweetAlert2Srv.showWarning(err.message);

      this.spinner.hide();
      return;
    } finally {
      this.loading = false;
      this.spinner.hide();
    }
  }

  //transformar el monto de wei a usd
  async fromWei(price: any) {
    let amount = fromWei(price, 18);
    return amount;
  }

  //multiplicar el monto por el numero de tokens
  async multiTokensAmount(token: any, price: any) {
    let amount = token * price;
    return amount;
  }

  //Conectar wallet
  async launchOpenConnection() {
    await this.web3Service.launchAskConnectionType();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
