<div class="modal fade" [id]="mId" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable modal-blue">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Listado de Usuarios</h5>
                <button type="button" class="btn-close" (click)="closeModal()">
                    <img src="assets/img/close-icon.png" alt="" />
                </button>
            </div>
            <div class="modal-body">

                <form [formGroup]="form" novalidate>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="searchInput" formControlName="search" placeholder="name@example.com">
                        <label for="searchInput">Buscar por correo</label>
                    </div>
                </form>

                <div class="table-responsive">
                    <table class="table table-striped table-hover table-dark">
                        <thead>
                            <tr>
                                <th scope="col" class="text-center">#</th>
                                <th scope="col" class="text-center">Name</th>
                                <th scope="col" class="text-center">Email</th>
                                <th scope="col" class="text-center">Option</th>
                            </tr>
                        </thead>
                        <tbody>

                            <ng-container *ngIf="records.length > 0; then renderRecordList; else renderNotFoundRecords"></ng-container>

                        </tbody>
                    </table>
                </div>

               <ng-container [ngTemplateOutlet]="renderPaginator" ></ng-container>

            </div>
        </div>
    </div>
</div>


<ng-template #renderPaginator>
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
            <li 
                class="page-item"
                [ngClass]="{'disabled': previous.length === 0}"
                (click)="onPreviousPage(paginator.page - 1)"
            >
                <a class="page-link">Previous</a>
            </li>
            <!-- <li class="page-item" *ngFor="let pag of paginator.pages" [ngClass]="{'disabled': pag === paginator.page}">
                <a class="page-link" href="javascript:void(0);">
                    {{ pag }}
                </a>
            </li> -->
            <li 
                class="page-item" 
                [ngClass]="{'disabled': paginator.page === paginator.totalPages || paginator.totalPages === 0}"
                (click)="onNextPage(paginator.page + 1)"
            >
                <a class="page-link" href="javascript:void(0);">Next</a>
            </li>
        </ul>
    </nav>
</ng-template>

<ng-template #renderRecordList>
    <tr *ngFor="let item of records; index as idx">
        <th scope="row">{{ (paginator.page - 1 ) + (idx + 1) }}</th>
        <td>{{item.name | titlecase}} {{item.lastName | titlecase}}</td>
        <td>{{item.email | lowercase}}</td>
        <td>
            <button type="button" class="btn btn-sm btn-secondary" (click)="onSubmit(item)">
                <i class="bi bi-hand-index"></i>
            </button>
        </td>
    </tr>
</ng-template>

<ng-template #renderNotFoundRecords>
    <tr>
        <th scope="row" colspan="4">
            <h5 class="text-center">
                No hay usuarios registrados
            </h5>
        </th>
    </tr>
</ng-template>

<ng-template #renderLoader>
    <tr *ngFor="let item of [1,1,1,1]">
        <th scope="row" colspan="4" class="placeholder-glow">
            <span class="placeholder col-12"></span>
        </th>
    </tr>
</ng-template>