<div class="card-verefication-user mt-5">
  <div class="card-verefication-user-header">
    <p>
      <i class="bi bi-wallet2"></i> &nbsp;
      {{ "walletRegister.title" | translate }}
    </p>
  </div>

  <div class="card-verefication-user-body pb-3">
    <p class="text-yellow text-small">
      <small [innerHTML]="'walletRegister.textOne' | translate"></small>
    </p>
    <p class="text-yellow text-small mb-2">
      <small [innerHTML]="'walletRegister.textTwo' | translate"></small>
    </p>

    <ng-container [ngSwitch]="optionSelected">
      <ng-container
        *ngSwitchCase="'own'"
        [ngTemplateOutlet]="renderOwnWallet"></ng-container>
      <ng-container
        *ngSwitchCase="'new'"
        [ngTemplateOutlet]="renderNewWallet"></ng-container>
      <ng-container
        *ngSwitchDefault
        [ngTemplateOutlet]="renderSelectOption"></ng-container>
    </ng-container>
  </div>
</div>

<!-- Renderizar - Registrar wallet propia -->
<ng-template #renderOwnWallet>
  <ng-container
    *ngIf="dataStatus$ | async as account; else renderConnectWalletButton">
    <p class="text-yellow mb-4 text-center">
      <small>
        {{ "walletRegister.theWalletThatIsConnected" | translate }}:
        <span class="badge bg-secondary"
          ><strong>{{ account | truncateWalletAddress }}</strong></span
        >
      </small>
    </p>

    <div class="d-flex justify-content-evenly">
      <button
        type="button"
        class="btn btn-main"
        (click)="setOwnWallet(account)"
        [disabled]="blockButtons ? true : null"
        [innerHTML]="'general.confirm' | translate"></button>
      <button
        type="button"
        class="btn btn-main"
        (click)="disconnectAccount()"
        [disabled]="blockButtons ? true : null"
        [innerHTML]="'general.cancel' | translate"></button>
    </div>
  </ng-container>

  <ng-template #renderConnectWalletButton>
    <div class="d-flex justify-content-evenly">
      <button
        type="button"
        class="btn btn-main"
        (click)="connectAccount()"
        [innerHTML]="'general.connectYourWallet' | translate"></button>
      <button
        type="button"
        class="btn btn-main"
        (click)="optionSelected = null"
        [innerHTML]="'general.cancel' | translate"></button>
    </div>
  </ng-template>
</ng-template>

<!-- Renderizar - Registrar nueva wallet -->
<ng-template #renderNewWallet>
  <div class="d-flex justify-content-evenly">
    <button
      type="button"
      class="btn btn-main"
      (click)="setNewWallet()"
      [innerHTML]="'general.create' | translate"></button>
    <button
      type="button"
      class="btn btn-main"
      (click)="optionSelected = null"
      [innerHTML]="'general.cancel' | translate"></button>
  </div>
</ng-template>

<!-- Renderizar - Selecionar una opción -->
<ng-template #renderSelectOption>
  <div class="d-flex justify-content-evenly">
    <button
      type="button"
      class="btn btn-main"
      (click)="optionSelected = 'new'"
      [innerHTML]="'walletRegister.iHaveNoWallet' | translate"></button>
    <button
      type="button"
      class="btn btn-main"
      (click)="optionSelected = 'own'"
      [innerHTML]="'walletRegister.iAlreadyHaveAWallet' | translate"></button>
  </div>
</ng-template>
