import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription, catchError, map, of, tap } from "rxjs";
import { CollectionService } from "src/app/services/firebase/collection.service";

@Component({
  selector: "app-properties-items-card",
  templateUrl: "./properties-items-card.component.html",
  styleUrls: ["./properties-items-card.component.css"],
})
export class PropertiesItemsCardComponent implements OnInit, OnDestroy {
  public propertiesList!: any[];
  public proyectsList$!: Observable<any[]>;
  private sub$!: Subscription;
  properties = [
    {
      title: "Nombre de la propiedad",
      description: "10th Floor OceanFront Luxury",
      address: "Apartment in Miami",
      img: "assets/img/properties-img.png",
      new: true,
    },
    {
      title: "Portafolio de la propiedad",
      description: " 10th Floor OceanFront Luxury",
      address: " Apartment in Miami",
      img: "assets/img/properties-img.png",
      new: false,
    },
  ];

  constructor(private collectionSrv: CollectionService) {}

  ngOnInit(): void {
    //obtener Propiedades
    this.proyectsList$ = this.collectionSrv
      .getDynamic([
        { field: "status", condition: "in", value: ["opening", "preview"] },
        // { field: 'status', condition: 'in', value: ['preview', 'opening', 'closed', 'soldOut'] }
      ])
      .pipe(
        map((data: any[]) =>
          data.map((item: any) => {
            let gallery: any[] = Array.isArray(item.gallery)
              ? item.gallery
              : [];
            // find thumbnails if exists or set defautl value
            const thumbnails =
              gallery.find((item: any) => item.thumbnails)?.url ||
              this.collectionSrv.deafultThumbnails;
            /** Si no tiene archivos de */
            if (gallery.length == 0) {
              gallery = new Array(3).fill({
                type: "image/png",
                url: this.collectionSrv.defaultImage,
                order: 0,
                thumbnails: false,
                size: 0,
              });
              gallery[0].thumbnails = true;
            }
            return {
              ...item,
              statusParsed: this.collectionSrv.collectionStatus.find(
                (status: any) => status.value === item.status
              ),
              thumbnails,
              gallery,
            };
          })
        ),
        // tap((data) => console.log('ProyectsViewListDesktopComponent.proyectsList$', data)),
        catchError((err) => {
          console.log(
            "Error on ProyectsViewListDesktopComponent.proyectsList$",
            err
          );
          return of([]);
        })
      );
    this.sub$ = this.proyectsList$.subscribe((res) => {
      this.propertiesList = res;
      // console.log(this.propertiesList);

      if (this.propertiesList.length > 0) {
        this.collectionSrv.onViewSelectedCollection$.next({
          action: "selectProject",
          data: this.propertiesList[0],
        });
      }
    });
  }

  onSelectProject(item: any) {
    // console.log('onSelectProject', item);
    this.collectionSrv.onViewSelectedCollection$.next({
      action: "selectProject",
      data: item,
    });
  }

  async openBuyForm(button: any, form: any) {
    let formContent: any = document.getElementById(form);
    button.classList.toggle("active");
    formContent.classList.toggle("d-block");
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
