<div>
  <form class="form-admin-user-invesment" [formGroup]="form" novalidate>
    <div class="row align-items-center">
      <div class="col-12">
        <label
          for="exampleFormControlInput1"
          class="form-label"
          [innerHTML]="'general.seeProjects' | translate"></label>
        <select class="form-select mb-3" formControlName="collectionId">
          <option
            value=""
            selected
            [innerHTML]="'general.selectAProject' | translate"></option>

          <ng-container *ngIf="collections">
            <option *ngFor="let item of collections" [value]="item._id">
              {{ item.projectName | titlecase }}
            </option>
          </ng-container>
        </select>
      </div>

      <div class="col-12 col-lg-6">
        <p
          class="text-yellow f-20"
          [innerHTML]="
            'projectYourInvestment.percentageOfYield' | translate
          "></p>
        <input
          type="number"
          class="form-control mb-3"
          formControlName="profit"
          readonly />
      </div>

      <div class="col-12 col-lg-6">
        <p
          class="text-yellow f-20"
          [innerHTML]="'projectYourInvestment.tokenPrice' | translate"></p>
        <input
          type="number"
          class="form-control mb-3"
          formControlName="price"
          readonly />
      </div>

      <div class="col-12 col-lg-6">
        <p
          class="text-yellow f-20"
          [innerHTML]="
            'projectYourInvestment.howManyTokensDoYouWant?' | translate
          "></p>
        <input
          type="text"
          appIntegerInput
          formControlName="nroTokens"
          class="form-control mb-3" />
      </div>

      <div class="col-12 col-lg-6">
        <p
          class="text-yellow f-20"
          [innerHTML]="
            'projectYourInvestment.amountOfInvestment' | translate
          "></p>
        <input
          type="number"
          class="form-control mb-3"
          formControlName="totales"
          readonly />
      </div>

      <div class="col-12">
        <ng-container
          *ngIf="
            !showSubmitLoaderButton;
            then renderSubmitButton;
            else renderSubmitLoaderButton
          "></ng-container>
      </div>
    </div>
  </form>
</div>

<div class="row mt-4">
  <div class="col-12 col-lg-6 mb-5">
    <p
      class="text-yellow f-20"
      [innerHTML]="'projectYourInvestment.one-yearYield' | translate"></p>
    <div class="card-dashboard-user-yellow">
      {{ f["gananciaAnual"].value | currency }} USD
    </div>
  </div>

  <div class="col-12 col-lg-6 mb-5">
    <p
      class="text-yellow f-20"
      [innerHTML]="'projectYourInvestment.five-YearYield' | translate"></p>
    <div class="card-dashboard-user-yellow">
      {{ f["gananciaAnualFive"].value | currency }} USD
    </div>
  </div>
</div>

<ng-template #renderSubmitButton>
  <button
    class="btn btn-main"
    (click)="onSubmit()"
    type="submit"
    [innerHTML]="'general.save' | translate"></button>
</ng-template>

<ng-template #renderSubmitLoaderButton>
  <button class="btn btn-main" type="button" disabled>
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"></span>
    {{ "general.loading" | translate }}
  </button>
</ng-template>
