<div class="modal fade" [id]="mId" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable modal-blue">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h5 class="modal-title" id="staticBackdropLabel">Listado de Usuarios</h5> -->
                <button type="button" class="btn-close" (click)="closeModal()">
                    <img src="assets/img/close-icon.png" alt="" />
                </button>
            </div>
            <div class="modal-body">
                <app-form-contact (onSubmitFormEvent)="onFormSubmit($event)"></app-form-contact>
            </div>
        </div>
    </div>
</div>
