<footer class="footer">
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-12 col-md-4">
        <h5>Miami - Florida</h5>
        <p class="mb-4 mb-md-0">8326 NW 68TH ST.</p>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <h5 class="text-center">Newsletter</h5>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
          <input
            class="form-control"
            type="text"
            [placeholder]="'general.email' | translate"
            formControlName="email" />
          <ng-container
            [ngTemplateOutlet]="renderVM"
            [ngTemplateOutletContext]="{ field: 'email' }"></ng-container>

          <div class="d-flex justify-content-center">
            <ng-container
              *ngIf="
                showSubmitLoaderButton;
                then renderSubmitLoaderButton;
                else renderSubmitButton
              "></ng-container>
          </div>
        </form>
      </div>
      <div class="col-12 col-md-4">
        <div class="footer__social">
          <a href="http://" target="_blank" rel="noopener noreferrer">
            <img
              class="img-fluid"
              src="assets/img/linkedin.png"
              alt="linkedin" />
          </a>
          <a
            href="https://www.youtube.com/@Investokco"
            target="_blank"
            rel="noopener noreferrer">
            <img class="img-fluid" src="assets/img/youtube.png" alt="youtube" />
          </a>
          <a
            href="https://www.instagram.com/investok.co/"
            target="_blank"
            rel="noopener noreferrer">
            <img
              class="img-fluid"
              src="assets/img/instagram.png"
              alt="instagram" />
          </a>
        </div>
      </div>
      <div class="col-12 mt-4">
        <p class="footer__copyright">Copyright INVESTOK 2024</p>
      </div>
    </div>
  </div>
</footer>

<ng-template #renderSubmitButton>
  <button
    type="submit"
    class="btn btn-new-d text-uppercase"
    [innerHTML]="'general.send' | translate"></button>
</ng-template>

<ng-template #renderSubmitLoaderButton>
  <button type="button" class="btn btn-new-d" disabled>
    <span
      class="spinner-border spinner-border-sm me-2"
      role="status"
      aria-hidden="true"></span>
    {{ "general.sending" | translate }}
  </button>
</ng-template>

<ng-template #renderVM let-field="field">
  <div class="mt-2" *ngIf="submitted">
    <ng-container *ngFor="let val of vm[field]">
      <span class="text-danger" *ngIf="f[field].hasError(val.type)">
        {{ val.message | translate }}
      </span>
    </ng-container>
  </div>
</ng-template>
