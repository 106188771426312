<div class="row mt-5 align-items-lg-stretch pb-5">
  <div class="col-12 col-lg-8">
    <!-- <app-form-profile></app-form-profile> -->

    <div class="d-flex flex-column">
      <ng-container *ngIf="walletVerification">
        <div class="item-border">
          {{ "profile.names" | translate }}: {{ profile?.name }}
        </div>
        <div class="item-border">
          {{ "profile.surname" | translate }}: {{ profile?.lastName }}
        </div>
        <div class="item-border">
          {{ "profile.email" | translate }}: {{ profile?.email }}
        </div>
        <div class="item-border">
          {{ "profile.phone" | translate }}: +{{ profile?.prefix }}
          {{ profile?.phoneNumber }}
        </div>
        <!-- <div class="item-border">Link Referido: 1111111111</div> -->
      </ng-container>
    </div>
  </div>

  <div class="col-12 col-lg-4">
    <app-profile-verification-list></app-profile-verification-list>
  </div>

  <ng-container *ngIf="userDoc$ | async as userDoc">
    <div class="col-12 mb-4" *ngIf="userDoc.exist && !userDoc.walletAddress">
      <app-profile-register-wallet-form></app-profile-register-wallet-form>
    </div>

    <div
      class="col-12 mb-4"
      *ngIf="userDoc.exist && userDoc.walletAddress && userDoc.createWallet">
      <app-profile-request-wallet-custodio-info-form></app-profile-request-wallet-custodio-info-form>
    </div>

    <div
      class="col-12 mb-4"
      *ngIf="userDoc.exist && userDoc.walletAddress && !userDoc.userCode">
      <app-profile-register-referral-code-form></app-profile-register-referral-code-form>
    </div>

    <div class="col-12 mb-4" *ngIf="userDoc.exist && !userDoc.kyc">
      <div class="card-verefication-user mt-5">
        <div class="card-verefication-user-header">
          <i class="bi bi-shield-lock"></i> KYC
        </div>
        <div class="card-verefication-user-body">
          <p class="text-yellow mb-2">
            Un paso fundamental para poder realizar transacciones en la
            plataforma es completar tu KYC. Por favor haz clic en el botón para
            completar tu KYC.
          </p>
          <button
            type="button"
            class="btn btn-main"
            *ngIf="!userDoc?.kycStatus"
            (click)="createKYCLink(userDoc.email)">
            ¡Comenzar KYC!
          </button>

          <button
            type="button"
            class="btn btn-main"
            *ngIf="userDoc?.kycStatus == 'started'"
            (click)="openKYCLink(userDoc.kycLink)">
            Continuar KYC
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!-- templates para los botones para conectar wallet -> -->
<ng-template #noConnected>
  <div class="item-border" (click)="connectWallet()">Conectar tu wallet</div>
</ng-template>

<ng-template #connected>
  <div class="item-border">
    Verificación Dirección
    <img
      src="assets/img/check-green.png"
      class="img-fluid ms-3"
      width="25px"
      alt="" />
  </div>
</ng-template>

<ng-template #loading>
  <div *ngFor="let item of [1]">
    <p class="placeholder-wave">
      <span class="placeholder col-12"></span>
    </p>
  </div>
</ng-template>

<!-- <ng-template #loadingData>
  <div class="item-border" *ngFor="let item of [1, 1, 1, 1, 1]">
    <p class="placeholder-glow">
      <span class="placeholder col-12 bg-warning"></span>
    </p>
  </div>
</ng-template> -->
