import { Pipe, PipeTransform } from '@angular/core';
import { CollectionService } from '../services/firebase/collection.service';
import { Observable, catchError, map, of } from 'rxjs';

@Pipe({
  name: 'propertie'
})
export class PropertiePipe implements PipeTransform {

  constructor(
    private collectionSrv: CollectionService
  ) {}

  transform(id: string, field: any = null): Observable<any> {
    return this.collectionSrv.getById(id)
    .pipe(
      map((res: any) => {
        if(!res) { return null; }
        return (field) ? res[field] : res;
      }),
      catchError((err) => of(null)),
    );
  }

}
