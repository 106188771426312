import { Component, OnInit } from "@angular/core";
import { Web3Service } from "src/app/services/contract/web3.service";
import { catchError, map, Observable, of, switchMap, Subscription } from "rxjs";
import { TemplateService } from "src/app/services/template.service";

@Component({
  selector: "app-dashboard-user-layout",
  templateUrl: "./dashboard-user-layout.component.html",
  styleUrls: ["./dashboard-user-layout.component.css"],
})
export class DashboardUserLayoutComponent implements OnInit {
  public dataStatus$!: Observable<any>;

  public showNavbar = true;
  private sub$!: Subscription;

  constructor(
    private web3Srv: Web3Service,
    private templateSrv: TemplateService
  ) {}

  ngOnInit() {
    this.dataStatus$ = this.web3Srv.accountStatus$.pipe(
      map((data: any[]) => (data.length > 0 ? data[0] : null)),
      switchMap(async (addr: any) => {
        if (!addr)
          return {
            addr: null,
            access: false,
          };
        const [vendorRoles] = await Promise.all([
          this.web3Srv.vendor_administered_getRoles(addr),
        ]);
        return {
          addr,
          access: Object.values(vendorRoles).some((v) => v), // [true, false] => [true, true] => true
          ...vendorRoles,
        };
      }),
      catchError((err) => {
        return of({
          addr: null,
          access: false,
        });
      })
    );
    this.sub$ = this.templateSrv.events$.subscribe((res: any) => {
      const { type, data } = res;
      if (type === "sidebarUser") {
        this.showNavbar = data;
      }
    });
  }
}
