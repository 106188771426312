import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { catchError, map, Observable, of, switchMap, Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Web3Service } from "src/app/services/contract/web3.service";
import { CollectionService } from "src/app/services/firebase/collection.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  @ViewChild("menuMobile") menuMobile!: ElementRef<any>;
  @ViewChild("listProyects") listProyects!: ElementRef<any>;
  @ViewChild("listProyectsBtn") listProyectsBtn!: ElementRef<any>;
  public uid$!: Observable<null | string>;
  public profile: any;
  private sub$!: Subscription;
  public dataStatus$!: Observable<any>;

  //variables para las propiedades
  public proyectsList!: any[];
  public proyectsList$!: Observable<any[]>;

  public currentLanguage: string = "";
  public selectLanguageCache!: any;

  constructor(
    private authSrv: AuthenticationService,
    private web3Srv: Web3Service,
    private collectionSrv: CollectionService,
    private router: Router,
    private elem: ElementRef,
    public translateSrv: TranslateService
  ) {
    this.dataStatus$ = this.web3Srv.accountStatus$.pipe(
      map((data: any[]) => (data.length > 0 ? data[0] : null)),
      switchMap(async (addr: any) => {
        if (!addr)
          return {
            addr: null,
            access: false,
          };
        return {
          addr,
          access: true,
        };
      }),
      catchError((err) => {
        return of({
          addr: null,
          access: false,
        });
      })
    );

    /** Intentar reestablecer conexión si la misma existe */
    this.web3Srv.checkAlreadyConnected();
    this.profile = this.authSrv.getLocalProfile();
    this.selectLanguageCache = localStorage.getItem("language");
    if (this.selectLanguageCache) {
      this.translateSrv.use(this.selectLanguageCache);
    }
    this.currentLanguage = this.translateSrv.currentLang;
  }

  async ngOnInit() {
    /** Escuchar si existe uid en la sesión */
    this.uid$ = await this.authSrv.uid$;

    this.proyectsList$ = this.collectionSrv
      .getDynamic([
        { field: "status", condition: "in", value: ["opening", "preview"] },
        // { field: 'status', condition: 'in', value: ['preview', 'opening', 'closed', 'soldOut'] }
      ])
      .pipe(
        map((data: any[]) =>
          data.map((item: any) => {
            let gallery: any[] = Array.isArray(item.gallery)
              ? item.gallery
              : [];
            // find thumbnails if exists or set defautl value
            const thumbnails =
              gallery.find((item: any) => item.thumbnails)?.url ||
              this.collectionSrv.deafultThumbnails;

            /** Si no tiene archivos de */
            if (gallery.length == 0) {
              gallery = new Array(3).fill({
                type: "image/png",
                url: this.collectionSrv.defaultImage,
                order: 0,
                thumbnails: false,
                size: 0,
              });
              gallery[0].thumbnails = true;
            }

            return {
              ...item,
              statusParsed: this.collectionSrv.collectionStatus.find(
                (status: any) => status.value === item.status
              ),
              thumbnails,
              gallery,
            };
          })
        ),
        // tap((data) => console.log('ProyectsViewListDesktopComponent.proyectsList$', data)),
        catchError((err) => {
          console.log(
            "Error on ProyectsViewListDesktopComponent.proyectsList$",
            err
          );
          return of([]);
        })
      );

    this.sub$ = this.proyectsList$.subscribe((res) => {
      this.proyectsList = res;
      if (this.proyectsList.length > 0) {
        this.collectionSrv.onViewSelectedCollection$.next({
          action: "selectProject",
          data: this.proyectsList[0],
        });
      }
    });
  }

  async logOut() {
    return await this.authSrv.logout();
  }

  async connectWallet() {
    return this.web3Srv.launchAskConnectionType();
  }

  async logOutWallet() {
    return this.web3Srv.logout(true);
  }

  scrollIntoViewWithOffset = (selector: any, offset: any) => {
    window.scrollTo({
      behavior: "smooth",
      top:
        document.querySelector(selector).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    });
  };

  scrollToElement(element: string, page: string): void {
    this.router.navigate(["/", page]).then(
      (nav) => {
        setTimeout(() => {
          this.scrollIntoViewWithOffset(element, "250");
        }, 700);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getListProyects() {
    this.listProyects.nativeElement.classList.toggle("show");
    this.listProyectsBtn.nativeElement.classList.toggle("show");
  }

  closeMenu() {
    this.menuMobile.nativeElement.classList.toggle("show");
  }

  @HostListener("document:click", ["$event"])
  DocumentClick(event: Event) {
    if (this.elem.nativeElement.contains(event.target)) {
    } else {
      if (this.menuMobile.nativeElement.classList.contains("show")) {
        this.closeMenu();
      }
    }
  }

  @HostListener("window:scroll", ["$event"])
  onScroll(event: Event) {
    if (this.elem.nativeElement.contains(event.target)) {
    } else {
      let navbar: any = document.getElementById("navbarScroll");

      let sticky = navbar.offsetTop;
      if (window.scrollY > sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    }
  }

  changeLanguage(lang: string) {
    localStorage.setItem("language", lang);
    console.log(lang);
    this.translateSrv.use(lang);
    this.currentLanguage = lang;
    let vsubMenu2 = document.getElementById("languaje");
    vsubMenu2?.classList.toggle("menu-languaje-active");
    let vsubMenu3 = document.getElementById("languaje-mobile");
    vsubMenu3?.classList.toggle("menu-languaje-active");
  }
  menuLanguaje() {
    let vsubMenu2 = document.getElementById("languaje");
    console.log(vsubMenu2);
    vsubMenu2?.classList.toggle("menu-languaje-active");
    let vsubMenu3 = document.getElementById("languaje-mobile");
    vsubMenu3?.classList.toggle("menu-languaje-active");
  }
}
