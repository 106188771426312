<div class="row">
  <ng-container
    *ngIf="propertiesList; else renderLoaderList"
    [ngTemplateOutlet]="renderCheckList"
    [ngTemplateOutletContext]="{ list: propertiesList }"></ng-container>
</div>

<ng-template #renderProjectList let-list="list">
  <ng-container
    *ngFor="let item of list"
    [ngTemplateOutlet]="
      item.status !== 'preview' ? renderRegularCardItem : renderDraftCardItem
    "
    [ngTemplateOutletContext]="{ item: item }"></ng-container>
</ng-template>

<ng-template #renderRegularCardItem let-item="item">
  <div class="col-12 col-lg-6">
    <div class="card-properties mb-4">
      <div class="card-properties-img">
        <div class="new-property" *ngIf="item.isNew">Nuevo</div>
        <!-- <img [src]="item.thumbnails" class="img-fluid" alt="" /> -->
        <!-- <img src="assets/img/properties-img.png" class="img-fluid" alt="" /> -->

        <img
          *ngIf="!item.gallery[0]"
          src="assets/img/properties-img.png"
          class="img-fluid"
          alt="" />
        <img
          *ngIf="item.gallery[0].type != 'video/mp4'"
          src="{{ item.gallery[0].url }}"
          class="img-fluid"
          alt="" />

        <video
          controls
          *ngIf="item.gallery[0].type == 'video/mp4'"
          [src]="item.gallery[0].url"
          class="img-fluid rounded galleryaMedia"></video>
      </div>
      <div class="card-properties-body">
        <div>
          <h4>{{ item.projectName | uppercase }}</h4>
          <p>
            {{ item.shortDescription }}
            <br />
            {{ item.address }}
          </p>
        </div>
        <button
          class="btn btn-view"
          [routerLink]="['/property', item.addr, 'details']">
          Ver Más
          <img
            src="assets/img/arrow-right-black.png"
            class="img-fluid"
            alt="" />
        </button>
        <div>
          <button
            type="button"
            class="btn btn-blue"
            (click)="openBuyForm($event.target, item.addr)">
            Comprar
          </button>

          <div class="form-content" id="{{ item.addr }}">
            <app-form-buy-propertie
              [dataId]="item.addr"></app-form-buy-propertie>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #renderDraftCardItem let-item="item">
  <div class="col-12 col-lg-6">
    <div class="card-properties mb-4 coming-soon">
      <div class="card-properties-img">
        <div class="new-property">Coming Soon</div>
        <!-- <img [src]="item.thumbnails" class="img-fluid" alt="" /> -->

        <img
          *ngIf="!item.gallery[0]"
          src="assets/img/properties-img.png"
          class="img-fluid"
          alt="" />
        <img
          *ngIf="item.gallery[0].type != 'video/mp4'"
          src="{{ item.gallery[0].url }}"
          class="img-fluid"
          alt="" />

        <video
          controls
          *ngIf="item.gallery[0].type == 'video/mp4'"
          [src]="item.gallery[0].url"
          class="img-fluid rounded galleryaMedia"></video>
      </div>
      <div class="card-properties-body">
        <div>
          <h4>{{ item.projectName | uppercase }}</h4>
          <p>
            {{ item.shortDescription }}
            <br />
          </p>
        </div>
        <button
          class="btn btn-view"
          disabled
          [routerLink]="['/property', item.addr, 'details']">
          Ver Más
          <img
            src="assets/img/arrow-right-black.png"
            class="img-fluid"
            alt="" />
        </button>
        <div>
          <button
            class="btn btn-blue"
            (click)="openBuyForm($event.target, item.addr)"
            disabled>
            Coming Soon
          </button>

          <div class="form-content" id="{{ item.addr }}">
            <app-form-buy-propertie
              [dataId]="item.addr"></app-form-buy-propertie>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #renderCheckList let-list="list">
  <ng-container
    *ngIf="list.length > 0; else renderNoRecordsJet"
    [ngTemplateOutlet]="renderProjectList"
    [ngTemplateOutletContext]="{ list: list }"></ng-container>
</ng-template>

<ng-template #renderNoRecordsJet>
  <!-- <div class="proyect-list-item mb-4">
      <img src="assets/img/loaderListItem.png" class="img-fluid proyect-list-img" alt="" />
      <div class="proyect-list-item-texts">
          <h5>No hay registros</h5>
          <p>&nbsp;</p>
          <div class="d-flex align-items-center justify-content-between">
              <span>&nbsp;</span>

              <img src="assets/img/Icon_11.png" class="img-fluid icon-list" alt="" />
          </div>
      </div>
  </div> -->
  <div class="proyect-list-item draft mb-4">
    <div class="d-flex align-items-center">
      <img src="assets/img/hourglass.png" alt="" />
      <span>Coming Soon</span>
    </div>
    <p>Obra de arte Picasso</p>
  </div>
</ng-template>

<ng-template #renderLoaderList>
  <ng-container
    [ngTemplateOutlet]="renderLoaderItem"
    *ngFor="let item of [1, 1, 1]"></ng-container>
</ng-template>

<ng-template #renderLoaderItem>
  <div class="proyect-list-item mb-4">
    <!-- <img src="assets/img/loaderListItem.png" class="img-fluid proyect-list-img" alt="" /> -->
    <div class="proyect-list-item-texts">
      <h5 class="placeholder-glow">
        <span class="placeholder col-10" style="height: 20px"></span>
      </h5>
      <p class="placeholder-glow">
        <span class="placeholder col-8" style="height: 16px"></span>
      </p>
      <div class="placeholder-glow">
        <span class="placeholder col-10" style="height: 16px"></span>
      </div>
    </div>
  </div>
</ng-template>
