import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { DataService } from "src/app/services/data.service";
import { checkEmailStored } from "src/app/helpers/custom-validations.helper";
import { TemporalTokenService } from "src/app/services/temporal-token.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-form-profile",
  templateUrl: "./form-profile.component.html",
  styleUrls: ["./form-profile.component.css"],
})
export class FormProfileComponent implements OnInit {
  public id!: any;
  public profile: any;

  public form!: FormGroup;
  public vm: any = {
    name: [
      { type: "required", message: "form.validations.isRequired" },
      { type: "pattern", message: "form.validations.lettersOnly" },
      { type: "minlength", message: "form.validations.minimum2Letters" },
    ],
    lastName: [
      { type: "required", message: "form.validations.isRequired" },
      { type: "pattern", message: "form.validations.lettersOnly" },
      { type: "minlength", message: "form.validations.minimum2Letters" },
    ],
    email: [
      { type: "required", message: "form.validations.isRequired" },
      { type: "pattern", message: "form.validations.email" },
      {
        type: "emailStored",
        message:
          "form.validations.EmailAlreadyExistsOrIsInUsePleaseEnterAnotherAddress",
      },
    ],
    phoneNumber: [
      { type: "required", message: "form.validations.isRequired" },
      { type: "pattern", message: "form.validations.onlyNumbersAreAllowed" },
      {
        type: "minlength",
        message: "form.validations.theTelephoneNumberMustHaveAtLeast9Numbers",
      },
      {
        type: "maxlength",
        message: "form.validations.thePhoneNumberMustHaveAMaximumOf10Numbers",
      },
    ],

    prefix: [{ type: "required", message: "form.validations.isRequired" }],
  };

  public submit = false;
  public loading: boolean = false;
  public country!: any[];

  constructor(
    public dataService: DataService,
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private authSrv: AuthenticationService,
    private sweetAlert2Srv: Sweetalert2Service
  ) {
    this.id = this.authSrv.getLocalUID();

    this.buildForm();
    this.getCountry();
  }

  ngOnInit() {
    this.getUser(this.id);
  }

  async getUser(id: any) {
    this.profile = await this.authSrv.getByUID(id);
    console.log(this.profile);

    /** Cargar información en el formulario */
    this.form.patchValue({
      name: this.profile.name,
      lastName: this.profile.lastName,
      email: this.profile.email,
      phoneNumber: this.profile.phoneNumber,
      prefix: this.profile.prefix,
    });
  }

  get f() {
    return this.form.controls;
  }
  /**
   * Obtener listado de ciudades
   */
  getCountry() {
    this.country = this.dataService.getCountry();
  }

  /**
   * Construir formulario
   */
  buildForm() {
    this.form = this.fb.group({
      name: [
        "",
        [
          Validators.required,
          Validators.pattern("[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*"),
          Validators.minLength(2),
        ],
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.pattern("[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*"),
          Validators.minLength(2),
        ],
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
        [checkEmailStored(this.authSrv)],
      ],

      phoneNumber: [
        "",
        [
          Validators.required,
          Validators.pattern(/^\d+$/),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      prefix: ["", [Validators.required]],
    });
  }

  /**
   * Al enviar formulario
   * @returns
   */
  async onSubmit() {
    try {
      this.submit = true;
      this.loading = true;
      const formData = this.form.value;
      console.log("formData", formData);
      if (!this.form.valid) {
        this.form.markAllAsTouched();
        return;
      }

      /** Construir documento de usuario */
      const data = {
        name: `${formData.name}`.trim().toLowerCase(),
        lastName: `${formData.lastName}`.trim().toLowerCase(),
        email: `${formData.email}`.trim().toLowerCase(),
        phoneNumber: `${formData.phoneNumber}`.trim(),
        prefix: formData.prefix,
      };
      console.log("data", data);

      const ask = await this.sweetAlert2Srv.askConfirm(
        "profile.areYouSureYouWantToUpdateYourProfile"
      );
      if (!ask) {
        return;
      }

      await this.spinner.show();

      console.log("formData", formData);

      await this.authSrv.update(this.id, data);
      this.sweetAlert2Srv.showSuccess(
        "profile.profileHasBeenUpdatedSuccessfully"
      );

      /** Obtener documento actualizado del usuario */
      const userDoc = await this.authSrv.getByUID(this.id);
      const toParse = {
        email: userDoc.email,
        name: userDoc.name,
        avatar: userDoc.avatar,
        phoneNumber: userDoc.phoneNumber,
        wallet: userDoc.walletAddress,
      };

      localStorage.setItem("profile", JSON.stringify(toParse));

      return;
    } catch (err: any) {
      /**
       * todo:
       *
       * no funciona el beta se logea el usuario
       */
      console.log("Error on SignUpComponent.onSubmit", err);

      await this.sweetAlert2Srv.showWarning(err.message);
      this.authSrv.logout();
      return;
    } finally {
      this.loading = false;
    }
  }
}
