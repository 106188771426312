import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  public events$ = new Subject<TemplateEvent>();

  public sidebarOptions = [
    {
      label: "Mis Propiedades",
      value: "my-properties",
      default: true,
    },
    {
      label: "Perfil",
      value: "profile",
      default: false,
    },
    {
      label: "Configurar Código",
      value: "config-code",
      default: false,
    },
  ];
  public currentSidebarOption = 'my-properties';

  constructor() {
    if(!this.getLocalSidebarOption()) {
      this.setSidebarOption(this.getDefaultSidebarOption());
    } else {
      this.currentSidebarOption = this.getLocalSidebarOption();
    }
  }

  getLocalSidebarOption() {
    return window.localStorage.getItem("sidebarOption") || this.getDefaultSidebarOption();
  }

  getDefaultSidebarOption() : string{
    return this.sidebarOptions.find((option) => option.default)?.value || 'my-properties';
  }

  setDefaultSidebarOption() {
    this.setSidebarOption(this.getDefaultSidebarOption());
  }

  setSidebarOption(option: any) {
    this.currentSidebarOption = option;
    window.localStorage.setItem("sidebarOption", option);
  }
}


export interface TemplateEvent {
  type: string;
  data: any
}
