import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "./api.service";

export interface CreateStriperOrder {
  userId: string;
  username: string;
  useremail: string;
  userphone: string;
  userWallet: string;
  userCodeRefered: string;
  propertieId: string;
  propertiesTokensNumberBuy: number;
  propertiesPriceUSD: number;
  currency: string;
  methodBuy: string;
  createdAt: number;
  statusBuy: string;
  success_url: string;
  cancel_url: string;
}

@Injectable({
  providedIn: "root",
})
export class StripeService {
  public prefix = "/stripe";

  constructor(private http: HttpClient, private apiSrv: ApiService) {}

  /**
   * @dev Llamada a la api de topPay para crear una nueva orden
   * @param client
   * @returns
   */
  async createOrder(client: CreateStriperOrder) {
    try {
      return await this.apiSrv.post(`${this.prefix}/checkout/create`, client);
    } catch (err) {
      console.log("Error on StripeService.createOrder", err);
      throw err;
    }
  }
}
