import { Component, OnInit } from "@angular/core";
import { Observable, catchError, from, of } from "rxjs";
import { Web3Service } from "src/app/services/contract/web3.service";

@Component({
  selector: "app-dashboard-user",
  templateUrl: "./dashboard-user.component.html",
  styleUrls: ["./dashboard-user.component.css"],
})
export class DashboardUserComponent implements OnInit {
  // public account: string;

  public roles$!: Observable<any>;
  public rol: boolean = true;
  constructor(public web3Srv: Web3Service) {
    // this.account = this.web3Srv.accounts[0];
  }

  ngOnInit() {
    // this.roles$ = from(this.web3Srv.getUserRoles(this.account)).pipe(
    //   catchError((err) => {
    //     return of({
    //       vendorIsAdmin: false,
    //       vendorIsUser: false,
    //     });
    //   })
    // );
  }
}
