import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { getFormErrors } from "src/app/helpers/formErrors.helper";
import { fromWei } from "src/app/helpers/utils";
import { CollectionService } from "src/app/services/firebase/collection.service";
import { LocalCartService } from "src/app/services/local-cart.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";

@Component({
  selector: "app-form-project-your-investment",
  templateUrl: "./form-project-your-investment.component.html",
  styleUrls: ["./form-project-your-investment.component.css"],
})
export class FormProjectYourInvestmentComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public vm: any = {
    collectionId: [
      { type: "required", message: "form.validations.ownershipIsRequired" },
    ],
    nroTokens: [
      { type: "required", message: "form.validations.numberOfTokensRequired" },
      {
        type: "min",
        message: "form.validations.theNumberOfTokensMustBeGreaterThan0",
      },
    ],
  };
  public submitted = false;

  public collections: any[] = [];

  private sub$!: Subscription;
  public listCard!: any;

  public totalGananciasList: number = 0;

  constructor(
    private collectionSrv: CollectionService,
    private fb: FormBuilder,
    private sweetAlert2Srv: Sweetalert2Service,
    private localCartSrv: LocalCartService
  ) {
    /** Construir formulario */
    this.form = this.fb.group({
      collectionId: ["", [Validators.required]],
      profit: 0,
      price: 0,
      nroTokens: [0, [Validators.required, Validators.min(1)]],
      totales: 0,
      gananciaAnual: 0,
    });

    /** Cargar información del localStorage */
    this.loadLocalStorageData();
  }

  ngOnInit() {
    /** Obtener listado de colecciones */
    this.sub$ = this.collectionSrv
      .getDynamic([
        { field: "status", condition: "in", value: ["preview", "opening"] },
      ])
      .subscribe((data: any[]) => {
        this.collections = data;
      });

    /** Escuchar evento de cambio de colección */
    this.sub$.add(
      this.form
        .get("collectionId")
        ?.valueChanges.subscribe((collection: string) => {
          // console.log("collection", collection);

          const nroTokens = this.form.get("nroTokens")?.value;
          // console.log("nroTokens", nroTokens);

          if (collection.length == 0) {
            this.form.patchValue({
              profit: 0,
              price: 0,
              nroTokens: 0,
              totales: 0,
            });
            return;
          }

          const collectionDoc = this.collections.find(
            (item: any) => item._id == collection
          );
          // console.log("collectionDoc", collectionDoc);

          this.form.patchValue({
            profit: collectionDoc?.estimatedProfitability,
            price: Number(fromWei(collectionDoc?.price)),
            nroTokens: nroTokens,
            totales: Number(fromWei(collectionDoc?.price)) * Number(nroTokens),
          });
        })
    );

    /** escuchar evento de cambio en nro de tokens */
    this.sub$.add(
      this.form
        .get("nroTokens")
        ?.valueChanges.subscribe((nroTokens: string) => {
          // console.log("nroTokens", nroTokens);

          const collectionId = this.form.get("collectionId")?.value;
          // console.log("collectionId", nroTokens);

          if (collectionId.length == 0) {
            this.form.patchValue({ totales: 0 });
            return;
          }

          const price = this.form.get("price")?.value;
          // console.log("price", nroTokens);
          // total invertido
          const priceTotal = Number(price) * Number(nroTokens);
          //ganancia anual
          const profit = this.form.get("profit")?.value;
          let costoInversion = priceTotal;
          let porcentajeGanancia = profit;
          const gananciaAnual = (porcentajeGanancia / 100) * costoInversion;

          this.form.patchValue({
            totales: priceTotal,
            gananciaAnual: gananciaAnual + priceTotal,
          });
        })
    );
  }

  /**
   * @dev Cargar información del localStorage en caso de existir
   */
  loadLocalStorageData() {
    const parseListCard: any = localStorage.getItem("cart");

    if (parseListCard) {
      this.listCard = JSON.parse(parseListCard);
      this.getTotalList(this.listCard.product);
    }
  }

  get f() {
    return this.form.controls;
  }

  /**
   * Incrementar Valor de input de números
   * @returns
   */
  addTokens() {
    const _v = isNaN(this.form.get("nroTokens")?.value)
      ? 1
      : Number(this.form.get("nroTokens")?.value);
    const nroTokens = _v + 1;

    this.form.patchValue({
      nroTokens: nroTokens,
    });
  }

  /**
   * Decrementar Valor de input de números
   */
  restTokens() {
    const _v = isNaN(this.form.get("nroTokens")?.value)
      ? 1
      : Number(this.form.get("nroTokens")?.value);
    const nroTokens = _v - 1 <= 0 ? 0 : _v - 1;

    this.form.patchValue({
      nroTokens: nroTokens,
    });
  }

  async onSubmit() {
    try {
      this.submitted = true;
      const formData = this.form.value;

      /** Si esl formulario tiene algun error - Mostrar error con una alerta */
      if (!this.form.valid) {
        const formErrors = getFormErrors(this.form);
        const { key, errors } = formErrors[0];
        const ferr = Object.keys(errors)[0];
        // console.log("formErrors", formErrors);

        const findMessage = this.vm[key].find((item: any) => item.type == ferr);
        // console.log("findMessage", findMessage);

        this.sweetAlert2Srv.showWarning(findMessage.message);
        return;
      }

      /** Crear documento por si no existe */
      this.localCartSrv.buildAndStore();

      /** Obtener documento de la colección */
      const collectionDoc = this.collections.find(
        (item: any) => item._id == formData.collectionId
      );
      // console.log("collectionDoc", collectionDoc);

      //ganancia anual
      let costoInversion = formData.totales;
      let porcentajeGanancia = formData.profit;
      const gananciaAnual: number = (porcentajeGanancia / 100) * costoInversion;

      const data = {
        ...collectionDoc,
        priceParsed: Number(fromWei(collectionDoc.price)),
        nroTokens: formData.nroTokens,
        totales: formData.totales,
        gananciaAnual: formData.gananciaAnual,
      };

      /**
       * TODO: revisar uso e implementación
       * - Esta función no maneja un origen absoluto, por lo que no es posible saber si el index es el mismo que el del localStorage
       */
      this.localCartSrv.addOnCart([data], "product");

      let parseListCard: any = localStorage.getItem("cart");

      this.listCard = JSON.parse(parseListCard);

      this.getTotalList(this.listCard.product);

      this.submitted = false;
      this.form.patchValue({
        collectionId: "",
        profit: 0,
        price: 0,
        nroTokens: 0,
        totales: 0,
        gananciaAnual: 0,
      });

      // console.log("Try to buy tokens", formData);
      this.sweetAlert2Srv.showSuccess(
        "projectYourInvestment.calculatedInvestmentAddedToTheList"
      );
      return;
    } catch (err) {
      console.log("Error on FormProjectYourInvestmentComponent.onSubmit", err);
      return;
    }
  }

  edit(index: any) {
    let item = this.listCard.product[index];
    // console.log(item);
    this.form.patchValue({
      collectionId: item._id,
      profit: item.estimatedProfitability,
      price: Number(fromWei(item.price)),
      nroTokens: item.nroTokens,
      totales: Number(fromWei(item.price)) * Number(item.nroTokens),
    });
    this.localCartSrv.removeOnCart(index, "product");
    let parseListCard: any = localStorage.getItem("cart");
    this.listCard = JSON.parse(parseListCard);
    this.getTotalList(this.listCard.product);
  }

  /**
   * TODO: revisar uso e implementación
   * - Esta función no maneja un origen absoluto, por lo que no es posible saber si el index es el mismo que el del localStorage
   * @param index
   */
  clear(index: any) {
    this.localCartSrv.removeOnCart(index, "product");
    let parseListCard: any = localStorage.getItem("cart");
    this.listCard = JSON.parse(parseListCard);
    this.getTotalList(this.listCard.product);
  }

  /**
   * TODO: revisar uso e implementación
   * - Ver si es posible hacer uso de la función getTotalList o utilizar un `getter` para obtener el valor
   * @param list
   */
  getTotalList(list: any[]) {
    const propertiesPricesUSD =
      list.length > 0
        ? list
            .map((item: any) => item.gananciaAnual)
            .reduce((a: any, b: any) => a + b)
        : 0;

    this.totalGananciasList = propertiesPricesUSD;
    // console.log(propertiesPricesUSD);

    // console.log(this.totalGananciasList);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
