<div class="card-verefication-user">
  <div class="card-verefication-user-header">
    <p [innerHTML]="'verifications.title' | translate"></p>
  </div>
  <div class="card-verefication-user-body">
    <ng-container
      *ngIf="userProfile$ | async as profile; else renderLoader"
      [ngTemplateOutlet]="renderList"
      [ngTemplateOutletContext]="{ item: profile }"></ng-container>
  </div>
</div>

<ng-template #renderList let-item="item">
  <!-- <div class="item-border">
        Verificación Perfil
        &nbsp;
        <ng-container [ngTemplateOutlet]="renderMark" [ngTemplateOutletContext]="{val: item?.avatar ?? false}"></ng-container>
    </div> -->
  <div class="item-border">
    {{ "verifications.kyc" | translate }} &nbsp;
    <ng-container
      [ngTemplateOutlet]="renderMark"
      [ngTemplateOutletContext]="{ val: item?.kyc ?? false }"></ng-container>
  </div>

  <div class="item-border">
    {{ "verifications.email" | translate }} &nbsp;
    <ng-container
      [ngTemplateOutlet]="renderMark"
      [ngTemplateOutletContext]="{
        val: item.email ? true : false
      }"></ng-container>
  </div>

  <div class="item-border">
    {{ "verifications.wallet" | translate }} &nbsp;
    <ng-container
      [ngTemplateOutlet]="renderMark"
      [ngTemplateOutletContext]="{
        val: item.walletAddress ? true : false
      }"></ng-container>
  </div>
</ng-template>

<ng-template #renderMark let-val="val">
  <img
    [src]="val ? 'assets/img/check-green.png' : 'assets/img/cancel-red.png'"
    class="img-fluid ms-3"
    width="25px"
    alt="" />
</ng-template>

<ng-template #renderLoader>
  <div class="item-border">
    <div class="d-flex justify-content-between align-items-center">
      <span [innerHTML]="'verifications.profile' | translate"></span>
      &nbsp;
      <ng-template [ngTemplateOutlet]="renderSpinner"></ng-template>
    </div>
  </div>
  <div class="item-border">
    <div class="d-flex justify-content-between align-items-center">
      <span [innerHTML]="'verifications.kyc' | translate"></span>
      &nbsp;
      <ng-template [ngTemplateOutlet]="renderSpinner"></ng-template>
    </div>
  </div>
  <div class="item-border">
    <div class="d-flex justify-content-between align-items-center">
      <span [innerHTML]="'verifications.email' | translate"></span>
      &nbsp;
      <ng-template [ngTemplateOutlet]="renderSpinner"></ng-template>
    </div>
  </div>
  <div class="item-border">
    <div class="d-flex justify-content-between align-items-center">
      <span [innerHTML]="'verifications.wallet' | translate"></span>
      &nbsp;
      <ng-template [ngTemplateOutlet]="renderSpinner"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #renderSpinner>
  <div
    class="spinner-border spinner-border-sm ms-auto"
    role="status"
    aria-hidden="true"></div>
</ng-template>
