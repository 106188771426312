<div class="container-login-logout-mobile">
  <ng-container
    *ngIf="
      uid$ | async as data;
      then renderLoggedInMobile;
      else renderLoggedOutMobile
    "></ng-container>
</div>

<!-- template de la lista de propiedades -->

<ng-template #renderProjectList let-list="list">
  <ng-container
    *ngFor="let item of list"
    [ngTemplateOutlet]="
      item.status !== 'draft' ? renderRegularList : renderDraftCardItem
    "
    [ngTemplateOutletContext]="{ item: item }"></ng-container>
</ng-template>

<ng-template #renderDraftCardItem let-item="item">
  <li>
    <a class="dropdown-item">
      {{ "general.comingSoon" | translate }} {{ item.name }}
    </a>
  </li>
</ng-template>

<ng-template #renderCheckList let-list="list">
  <ng-container
    *ngIf="list.length > 0; else renderNoRecordsJet"
    [ngTemplateOutlet]="renderProjectList"
    [ngTemplateOutletContext]="{ list: list }"></ng-container>
</ng-template>

<ng-template #renderLoaderList>
  <ng-container
    [ngTemplateOutlet]="renderLoaderItem"
    *ngFor="let item of [1, 1, 1]"></ng-container>
</ng-template>

<ng-template #renderLoaderItem>
  <li>
    <a class="placeholder"> </a>
  </li>
</ng-template>

<ng-template #renderRegularList let-item="item">
  <li>
    <a class="dropdown-item" [routerLink]="['/property', item.addr, 'details']">
      {{ item.name | titlecase }}
    </a>
  </li>
</ng-template>

<ng-template #renderNoRecordsJet>
  <li>
    <a class="dropdown-item" [innerHTML]="'projects.noProperties' | translate">
    </a>
  </li>
</ng-template>

<!-- templates para los botones para conectar wallet -> -->
<ng-template #noConnected>
  <button
    class="btn btn-blue"
    (click)="connectWallet()"
    [innerHTML]="'general.connectYourWallet' | translate"></button>
</ng-template>

<ng-template #connected>
  <button
    class="btn btn-blue"
    (click)="logOutWallet()"
    [innerHTML]="'general.disconnectYourWallet' | translate"></button>
</ng-template>

<!-- templates para los botones de inicio de sesion y registro desktop -> -->
<ng-template #renderLoggedIn>
  <button
    class="btn btn-transparent me-3"
    [routerLink]="'/dashboard-user/home'">
    Dashboard
  </button>
  <button
    class="btn btn-transparent"
    (click)="logOut()"
    [innerHTML]="'general.logOut' | translate"></button>
</ng-template>

<ng-template #renderLoggedOut>
  <button
    class="btn btn-transparent me-3"
    [routerLink]="'/login'"
    [innerHTML]="'general.signIn' | translate"></button>
  <button
    class="btn btn-transparent"
    [routerLink]="'/register'"
    [innerHTML]="'general.signUp' | translate"></button>
</ng-template>

<!-- templates para los botones de inicio de sesion y registro mobile -> -->
<ng-template #renderLoggedInMobile>
  <div class="container-login-logout-mobile-item">
    <button class="btn btn-transparent" [routerLink]="'/dashboard-user/home'">
      Dashboard
    </button>
  </div>
  <div class="line"></div>
  <div class="container-login-logout-mobile-item">
    <button
      class="btn btn-transparent"
      (click)="logOut()"
      [innerHTML]="'general.logOut' | translate"></button>
  </div>
</ng-template>

<ng-template #renderLoggedOutMobile>
  <div class="container-login-logout-mobile-item">
    <button
      class="btn btn-transparent"
      [routerLink]="'/login'"
      [innerHTML]="'general.signIn' | translate"></button>
  </div>

  <div class="line"></div>
  <div class="container-login-logout-mobile-item">
    <button
      class="btn btn-transparent"
      [routerLink]="'/register'"
      [innerHTML]="'general.signUp' | translate"></button>
  </div>
</ng-template>

<nav class="navbar navbar-expand-lg fixed-top" id="navbarScroll">
  <div class="container">
    <a
      class="navbar-brand"
      (click)="scrollToElement('#homeJumbotronSection', 'home')">
      <img src="assets/img/logo-new-3.png" alt="" class="img-fluid" />
    </a>
    <button class="navbar-toggler" type="button" (click)="closeMenu()">
      <img
        class="img-fluid"
        src="assets/img/menu-mobile-icon.png"
        alt="menu icon" />
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01" #menuMobile>
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            #listProyectsBtn
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            (click)="getListProyects()"
            [innerHTML]="'projects.title' | translate">
          </a>
          <ul
            class="dropdown-menu"
            #listProyects
            aria-labelledby="navbarDropdown">
            <ng-container
              *ngIf="proyectsList; else renderLoaderList"
              [ngTemplateOutlet]="renderCheckList"
              [ngTemplateOutletContext]="{ list: proyectsList }"></ng-container>
          </ul>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            (click)="
              [scrollToElement('#investalkSection', 'home'), closeMenu()]
            ">
            InvesTalk
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active"
            [routerLink]="'/about-us'"
            (click)="closeMenu()"
            [innerHTML]="'general.aboutUs' | translate">
          </a>
        </li>
        <li class="nav-item d-block d-lg-none">
          <div class="btn-languaje mt-2" id="languaje-mobile">
            <button class="btn" type="button" (click)="menuLanguaje()">
              <img
                *ngIf="currentLanguage === 'en'"
                class="img-fluid"
                src="assets/img/estados-unidos.png"
                alt="bandera estado unidos" />
              <img
                *ngIf="currentLanguage === 'es'"
                class="img-fluid"
                src="assets/img/espana.png"
                alt="bandera espana" />
            </button>
            <ul class="menu-languaje">
              <li id="es" class="li-lgj" (click)="changeLanguage('es')">
                <img
                  class="img-fluid"
                  src="assets/img/espana.png"
                  alt="bandera espana" />
              </li>
              <li id="pt" class="li-lgj" (click)="changeLanguage('en')">
                <img
                  class="img-fluid"
                  src="assets/img/estados-unidos.png"
                  alt="bandera estado unidos" />
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <div class="d-flex d-none d-lg-flex align-items-center">
        <ng-container
          *ngIf="
            uid$ | async as data;
            then renderLoggedIn;
            else renderLoggedOut
          "></ng-container>
        <div class="btn-languaje mb-1" id="languaje">
          <button class="btn" type="button" (click)="menuLanguaje()">
            <img
              *ngIf="currentLanguage === 'en'"
              class="img-fluid"
              src="assets/img/estados-unidos.png"
              alt="bandera estado unidos" />
            <img
              *ngIf="currentLanguage === 'es'"
              class="img-fluid"
              src="assets/img/espana.png"
              alt="bandera espana" />
          </button>
          <ul class="menu-languaje">
            <li id="es" class="text-white" (click)="changeLanguage('es')">
              <img
                class="img-fluid"
                src="assets/img/espana.png"
                alt="bandera espana" />
            </li>
            <li id="pt" class="text-white" (click)="changeLanguage('en')">
              <img
                class="img-fluid"
                src="assets/img/estados-unidos.png"
                alt="bandera estado unidos" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
