import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class EmailNotificationApiService {

  public prefix = '/emailNotification';

  constructor(
    private apiSrv: ApiService
  ) { }

  async quickNotification(params: EmailNotification){
    try {
      return await this.apiSrv.post(`${this.prefix}/quick-notification`, params);
    } catch (err) {
      console.log('Error on EmailNotificationApiService.quickNotification', err);
      throw err;
    }
  }

}

export interface NotificationBodyLine {
  type: 'line',
  text: string
}

export interface NotificationBodyButton {
  type: 'action',
  action: string,
  url: string
}

export interface NotificationBodyHTML {
  type: 'html',
  html: string
}

export interface EmailNotification {
  subject: string,
  email: string | string[],
  cc?: string | string[],
  bcc?: string | string[],
  greeting?: string,
  messageBody: (NotificationBodyLine | NotificationBodyButton | NotificationBodyHTML)[],
  salutation?: string
}
