import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardUserComponent } from "./dashboard-user.component";
import { DashboardUserProfileComponent } from "./pages/dashboard-user-profile/dashboard-user-profile.component";
import { DashboardUserMyInvestmentComponent } from "./pages/dashboard-user-my-investment/dashboard-user-my-investment.component";
import { DashboardUserPortfolioComponent } from "./pages/dashboard-user-portfolio/dashboard-user-portfolio.component";
import { AuthGuard } from "../guards/auth.guard";
import { DashboardUserInvestalkComponent } from "./pages/dashboard-user-investalk/dashboard-user-investalk.component";

const routes: Routes = [
  {
    path: "home",
    component: DashboardUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "profile",
    component: DashboardUserProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "portfolio",
    component: DashboardUserPortfolioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "my-investment",
    component: DashboardUserMyInvestmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "investalk",
    component: DashboardUserInvestalkComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "/dashboard-user/home",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardUserRoutingModule {}
