<h3
  class="titles mb-4 mb-lg-5"
  [innerHTML]="'general.contactUs' | translate"></h3>

<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
  <div class="row">
    <div class="col-12">
      <input
        type="text"
        class="form-control mb-3"
        formControlName="names"
        [placeholder]="'form.firstAndLastName' | translate"
        aria-label="Nombre y Apellido" />
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'names' }"></ng-container>
    </div>

    <div class="col-12">
      <input
        type="email"
        class="form-control mb-3"
        formControlName="email"
        [placeholder]="'form.email' | translate"
        aria-label="Correo Electrónico" />
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'email' }"></ng-container>
    </div>

    <div class="col-12">
      <textarea
        class="form-control mb-4"
        formControlName="description"
        [placeholder]="'form.leaveAMessageHere' | translate"
        id="floatingTextarea"
        cols="10"></textarea>
      <ng-container
        [ngTemplateOutlet]="renderVM"
        [ngTemplateOutletContext]="{ field: 'description' }"></ng-container>
    </div>

    <div class="col-12">
      <ng-container
        *ngIf="
          showSubmitLoaderButton;
          then renderSubmitLoaderButton;
          else renderSubmitButton
        "></ng-container>
    </div>
  </div>
</form>

<ng-template #renderSubmitButton>
  <button
    type="submit"
    class="btn btn-main"
    [innerHTML]="'general.send' | translate"></button>
</ng-template>

<ng-template #renderSubmitLoaderButton>
  <button type="button" class="btn btn-main" disabled>
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"></span>
    {{ "general.loading" | translate }}
  </button>
</ng-template>

<ng-template #renderVM let-field="field">
  <div class="mt-1 mx-2 mb-3" *ngIf="submitted">
    <ng-container *ngFor="let val of vm[field]">
      <span class="text-danger" *ngIf="f[field].hasError(val.type)">
        {{ val.message | translate }}
      </span>
    </ng-container>
  </div>
</ng-template>
