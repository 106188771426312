<ng-container [ngSwitch]="step">
  <ng-container *ngIf="step == 1; then renderStartCard"></ng-container>
  <ng-container *ngIf="step == 2; then renderCodeFormCard"></ng-container>
  <ng-container *ngIf="step == 3; then renderWeb3FormCard"></ng-container>
</ng-container>

<ng-template #renderWeb3FormCard>
  <div class="card-verefication-user mt-5">
    <div class="card-verefication-user-header">
      <i class="bi bi-person-square"></i>
      &nbsp; {{ "referralCodeRegister.getYourReferralCode" | translate }}
    </div>
    <div class="card-verefication-user-body pb-3">
      <p
        class="text-yellow mb-0"
        [innerHTML]="'referralCodeRegister.textOne' | translate"></p>
      <p class="text-yellow mb-0">
        {{ "referralCodeRegister.theCodeYouChoseIs" | translate }}:
        <strong>{{ codeParsed }}</strong>
      </p>
      <p
        class="text-yellow"
        [innerHTML]="'referralCodeRegister.textTwo' | translate"></p>

      <ng-container
        *ngIf="
          dataStatus$ | async as data;
          then renderGetCodeBtn;
          else renderConnectAccountBtn
        "></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #renderCodeFormCard>
  <div class="card-verefication-user mt-5">
    <div class="card-verefication-user-header">
      <i class="bi bi-person-square"></i>
      &nbsp; {{ "referralCodeRegister.getYourReferralCode" | translate }}
    </div>
    <div class="card-verefication-user-body pb-3">
      <form [formGroup]="form" novalidate>
        <div class="mb-3">
          <label
            for="exampleFormControlInput1"
            class="form-label text-yellow"
            [innerHTML]="'referralCodeRegister.textThree' | translate">
          </label>
          <input
            type="text"
            class="form-control"
            formControlName="code"
            placeholder="" />

          <ng-container
            *ngIf="submitted"
            [ngTemplateOutlet]="renderVM"
            [ngTemplateOutletContext]="{ field: 'code' }"></ng-container>
        </div>
        <button
          type="button"
          class="btn btn-main"
          (click)="onSubmit()"
          [innerHTML]="'general.continue' | translate"></button>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #renderStartCard>
  <div class="card-verefication-user mt-5">
    <div class="card-verefication-user-header">
      <i class="bi bi-person-square"></i>
      &nbsp; {{ "referralCodeRegister.getYourReferralCode" | translate }}
    </div>
    <div class="card-verefication-user-body pb-3">
      <p
        class="text-yellow"
        [innerHTML]="'referralCodeRegister.textFour' | translate"></p>
      <button type="button" class="btn btn-main" (click)="step = 2">
        ¡{{ "general.letsStart" | translate }}!
      </button>
    </div>
  </div>
</ng-template>

<ng-template #renderGetCodeBtn>
  <button
    type="button"
    class="btn btn-main"
    (click)="getReferralCode()"
    [innerHTML]="'general.end' | translate"></button>
</ng-template>

<ng-template #renderConnectAccountBtn>
  <button
    type="button"
    class="btn btn-main"
    (click)="connectAccount()"
    [innerHTML]="'general.connectYourWallet' | translate"></button>
</ng-template>

<ng-template #renderVM let-field="field">
  <ng-container *ngFor="let val of vm[field]">
    <div class="form-text text-danger" *ngIf="f[field].hasError(val.type)">
      {{ val.message | translate }}
    </div>
  </ng-container>
</ng-template>
