import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { addressIsValid } from "./utils";
import { AuthenticationService } from "../services/authentication.service";
import { Observable, from, map, tap } from "rxjs";
import { Web3Service } from "../services/contract/web3.service";

/**
 * Validar si una dirección es correcta
 * @param control
 * @returns
 */
export function cmIsValidAddress(control: AbstractControl) {
  return addressIsValid(control.value) ? null : { isValidAddress: true };
}

/**
 * TODO: aun en desarrollo
 * @param fieldToCompare 
 * @returns 
 */
export function greatThat(fieldToCompare: string): ValidatorFn  {
  return (control: AbstractControl): ValidationErrors | null => {
    const parent = control.parent;
    const fieldToCompareValue = (parent) ?  parent.get(fieldToCompare)?.value : 0;
    const controlValue = control.value || 0;
    console.log('greatThat', fieldToCompareValue, control.value);
    return (controlValue > fieldToCompareValue) ? null : { greatThat: true};
  };
}

/**
 * TODO: aun en desarrollo
 * @param fieldToCompare 
 * @returns 
 */
export function lowerThat(fieldToCompare: string): ValidatorFn  {
  return (control: AbstractControl): ValidationErrors | null => {
    const parent = control.parent;
    const fieldToCompareValue = (parent) ?  parent.get(fieldToCompare)?.value : 0;
    const controlValue = control.value || 0;
    console.log('lowerThat', fieldToCompareValue, control.value);
    return (controlValue < fieldToCompareValue) ? null : { lowerThat: true};
  };
}

/**
 * Válidar si el correo ya se encuentra registrado o esta en uso
 */
export function checkEmailStored(
  service: AuthenticationService
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return service.afs
      .collection("users", (ref) =>
        ref.where("email", "==", `${control.value}`.trim()).limit(1)
      )
      .get()
      .pipe(
        // tap((data: any) => console.log('checkEmailForExists', data) ),
        map((data: any) => (data.empty ? null : { emailStored: true }))
      );
  };
}

export function checkReferralCodeStored(
  service: Web3Service
): AsyncValidatorFn {
  return async (control: AbstractControl): Promise<ValidationErrors | null> => {
    const snapshot = await service.vendor_referral_codeExist_OC(control.value);
    console.log('checkReferralCodeStored', snapshot);
    return (snapshot) ? { referralCodeStored: true} : null;
  };
}
