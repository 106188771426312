<div
  class="modal fade"
  [id]="mId"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true">
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-blue">
    <div class="modal-content">
      <div class="modal-header">
        <h5
          class="modal-title"
          id="staticBackdropLabel"
          [innerHTML]="'projectYourInvestment.buyProperty' | translate"></h5>
        <button type="button" class="btn-close" (click)="closeModal()">
          <img src="assets/img/close-icon.png" alt="" />
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="form" novalidate autocomplete="off">
          <div class="row align-items-center">
            <div class="col-12">
              <label
                class="form-label"
                [innerHTML]="'general.methodOfPayment' | translate">
              </label>
              <select
                class="form-select mb-3"
                formControlName="whiteListToken"
                (change)="onChangeWhitelist()">
                <option
                  value=""
                  selected
                  [innerHTML]="
                    'general.selectAPaymentMethod' | translate
                  "></option>
                <!-- <option value="topPay" *ngIf="isProduction">
                  Tarjeta de Crédito/Débito
                </option> -->
                <option *ngFor="let item of whitelist" [value]="item.addr">
                  <ng-container
                    [ngTemplateOutlet]="
                      !item.isNative ? renderERC20Name : renderNativeName
                    "
                    [ngTemplateOutletContext]="{ item: item }"></ng-container>
                </option>
                <option value="stripe">STRIPE</option>
              </select>

              <ng-container
                [ngTemplateOutlet]="renderVM"
                [ngTemplateOutletContext]="{
                  field: 'whiteListToken'
                }"></ng-container>
            </div>

            <div class="col-12 d-flex justify-content-center">
              <ng-container
                [ngTemplateOutlet]="renderCheckSubmitButton"></ng-container>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Botón para compra con Web3 -->
<ng-template #renderWeb3SubmitButton>
  <ng-container *ngIf="form.valid">
    <button
      type="button"
      class="btn btn-main w-75"
      *ngIf="!walletBuy"
      (click)="launchOpenConnection()"
      [innerHTML]="'general.buyNow' | translate"></button>

    <ng-container
      *ngIf="walletBuy"
      [ngTemplateOutlet]="renderSubmitButton"></ng-container>
  </ng-container>

  <ng-container *ngIf="!form.valid">
    <ng-container [ngTemplateOutlet]="renderSubmitButton"></ng-container>
  </ng-container>
</ng-template>

<ng-template #renderSubmitButton>
  <button
    type="submit"
    class="btn btn-main w-75"
    (click)="onSubmit()"
    [innerHTML]="'general.buyNow' | translate"></button>
</ng-template>

<ng-template #renderCheckSubmitButton>
  <ng-container
    *ngIf="
      f['whiteListToken'].value === 'stripe';
      then renderSubmitButton;
      else renderWeb3SubmitButton
    "></ng-container>
</ng-template>

<ng-template #renderERC20Name let-item="item">
  {{ item.addr | erc20 : "symbol" | async | uppercase }} (Polygon)
</ng-template>

<ng-template #renderNativeName>
  {{ nativeCurrency.name | uppercase }} (Polygon)
</ng-template>

<ng-template #renderVM let-field="field">
  <div class="mt-1 mx-2 mb-3" *ngIf="submitted">
    <ng-container *ngFor="let val of vm[field]">
      <span class="text-danger" *ngIf="f[field].hasError(val.type)">
        {{ val.message | translate }}
      </span>
    </ng-container>
  </div>
</ng-template>
