import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "./api.service";

const URL_ROOT: any = environment.API_URL;
@Injectable({
  providedIn: "root",
})
export class TopPayService {

  public prefix = '/topPayTransaction';

  constructor(
    private http: HttpClient,
    private apiSrv: ApiService
  ) { }

  /**
   * @dev Llamada a la api de topPay para crear una nueva orden
   * @param client 
   * @returns 
   */
  async createOrder(client: CreateTopPayOrder){
    try {
      return await this.apiSrv.post(`${this.prefix}/create`, client);
    } catch (err) {
      console.log('Error on TopPayService.createOrder', err);
      throw err;
    }
  }

  // /**
  //  * Llamada a la api de open pay par un nuevo cliente
  //  */
  // async buy(client: any) {
  //   try {
  //     const result = await lastValueFrom(
  //       this.http.post(`${URL_ROOT}/topPayTransaction`, client)
  //     );

  //     return result;
  //   } catch (err) {
  //     console.log("Error on openpayService.newClient", err);
  //     throw err;
  //   }
  // }

}

export interface CreateTopPayOrder {
  userId: string;
  numdoc: string;
  username: string;
  userphone: string;
  useremail: string;
  userWallet: string;
  amount: string,
  propertieId: string;
  propertiesTokensNumberBuy: number;
  propertiesPriceUSD: number;
  propertiesPriceToPay: string;
  currency: string;
  expiration: string;
  typetransaction: string;
  txHash: string | null;
  method: string;
  methodBuy: string;
  returnUrl: string;
  createdAt: number;
  statusBuy: string;
}
