export const environment = {
  production: false,
  dbPrefix: "prod_",
  seedKey: "PROD_INVESTOK_2023$$**$%&",
  projectName: "INVESTOK",
  API_KEY_BSC: "4S2UBP9NC7GS85BUV4HIJ76HNV73AY8YNH",
  // API_URL: "http://localhost:4000",
  API_URL: "https://apiback.investok.co",
  urlWeb: "https://dev.investok.co",
  urlLogo: "https://firebasestorage.googleapis.com/v0/b/investok-prod.appspot.com/o/INVESTOK_LOGO.png?alt=media&token=573335ae-839f-44e6-9e07-c7608351db45",
  configUrlAbi: "/assets/abi/erc721s.json",
  contactEmail: "hola@investok.co",
  
  firebase: {
    apiKey: "AIzaSyCbFAtDrXVzAJ2YHQ6ICcRN9-4seSPDyoE",
    authDomain: "investok-prod.firebaseapp.com",
    projectId: "investok-prod",
    storageBucket: "investok-prod.appspot.com",
    messagingSenderId: "469621688200",
    appId: "1:469621688200:web:a66f80aadfa55734ca9c3d",
    measurementId: "G-5BK7Q2RCS9"
  },

  vendorAddress: "0xFFDb53fA7dc6a8b257Df2890C65127Da77251FD0",
  distributionAddress: "0xfE0E75d099268587d61ADf0054A8BBAD0D850063",
  saleDistributionAddress: "0x3232026f75b801A75b250295dD8F23766980467b",
  addressdead: "0x000000000000000000000000000000000000dEaD",

  infuraId: "",

  
  mainToken: {
    contract: "#",
    name: "#",
    symbol: "#",
    decimals: 18,
  },
  appDefaultReferralCode: "investok",
  chain: {
    walletConnectID: "4af18cacaeb09f42b4d325033743f639",
    infuraId: "356256bc3fcf42de88d2bc2e129ea5d9",

    chainId: 137,
    chainIdMetamask: "0x89",
    chainName: "Polygon Mainnet",
    // rpc: "https://polygon-mainnet.core.chainstack.com/6a4fc50f0daa1c701fcd0637976b3a19/",
    // rpcUrls: ["https://polygon-mainnet.core.chainstack.com/6a4fc50f0daa1c701fcd0637976b3a19/"],
    rpc: "https://polygon-rpc.com/",
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],

    nativeCurrency: {
      web3ModalNetwork: "polygon",
      network: "MATIC",
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
  }
}
