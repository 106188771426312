import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import Swal from "sweetalert2";

@Component({
  selector: "app-calculator",
  templateUrl: "./calculator.component.html",
  styleUrls: ["./calculator.component.css"],
})
export class CalculatorComponent implements OnInit {
  @ViewChild("mostrarOperciones", { static: true })
  mostrarOperaciones!: ElementRef;

  public container: any;
  public arrayOperations: any[] = [];
  public arrayResults: any[] = [];
  public viewOperations: any = [];

  public calValue: number = 0;
  public funcT: any = false;
  public calNumber: string = "noValue";
  public firstNumber: number = 0;
  public secondNumber: number = 0;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    //vereficar de las variables del localstorage
    this.viewOperations = localStorage.getItem("operations") || [];

    // crear html history con localstorage
    this.createStorage();
  }

  // calculadora

  // identificar que boton clickea de la calculadora
  onClickValue(val: string, type: any) {
    if (type == "number") {
      this.onNumberClick(val);
    } else if (type == "function") {
      this.onFunctionClick(val);
    }
  }

  //  clickea un numero de la calculadora
  onNumberClick(val: string) {
    if (this.calNumber != "noValue") {
      this.calNumber = this.calNumber + val;
    } else {
      this.calNumber = val;
    }

    this.calValue = parseFloat(this.calNumber);
  }

  //  clickea una operacion de la calculadora
  onFunctionClick(val: string) {
    if (val == "c") {
      this.clearAll();
    } else if (!this.funcT) {
      this.firstNumber = this.calValue;
      this.calValue = 0;
      this.calNumber = "noValue";
      this.funcT = val;
    } else if (this.funcT) {
      this.secondNumber = this.calValue;
      this.valueCalculate(val);
    }
  }

  // calcular las operaciones de la calculadora
  valueCalculate(val: string) {
    if (this.funcT == "+") {
      const Total = this.firstNumber + this.secondNumber;

      const Operation = `${this.firstNumber} ${this.funcT} ${this.secondNumber}`;
      const Results = Total.toString();
      this.createHtml(Operation, Results);
      this.totalAssingValues(Total, val);
    }

    if (this.funcT == "-") {
      const Total = this.firstNumber - this.secondNumber;
      const Operation = `${this.firstNumber} ${this.funcT} ${this.secondNumber}`;
      const Results = Total.toString();
      this.createHtml(Operation, Results);
      this.totalAssingValues(Total, val);
    }

    if (this.funcT == "*") {
      const Total = this.firstNumber * this.secondNumber;
      const Operation = `${this.firstNumber} ${this.funcT} ${this.secondNumber}`;
      const Results = Total.toString();
      this.createHtml(Operation, Results);
      this.totalAssingValues(Total, val);
    }

    if (this.funcT == "/") {
      const Total = this.firstNumber / this.secondNumber;
      const Operation = `${this.firstNumber} ${this.funcT} ${this.secondNumber}`;
      const Results = Total.toString();
      this.createHtml(Operation, Results);
      this.totalAssingValues(Total, val);
    }

    if (this.funcT == "%") {
      const Total = this.firstNumber % this.secondNumber;
      const Operation = `${this.firstNumber} ${this.funcT} ${this.secondNumber}`;
      const Results = Total.toString();
      this.createHtml(Operation, Results);
      this.totalAssingValues(Total, val);
    }
  }

  //asignar el total del a operacion
  totalAssingValues(Total: number, val: string) {
    this.calValue = Total;
    this.firstNumber = Total;
    this.secondNumber = 0;
    this.calNumber = "noValue";
    this.funcT = val;
    if (val == "=") {
      this.onEqualPress();
    }
  }

  //limpiar cuando termina la operacion
  onEqualPress() {
    this.firstNumber = 0;
    this.secondNumber = 0;
    this.funcT = false;
    this.calNumber = "noValue";
  }

  // limpiar calculador
  clearAll() {
    this.firstNumber = 0;
    this.secondNumber = 0;
    this.calValue = 0;
    this.funcT = false;
    this.calNumber = "noValue";
  }

  // Guardar en el localstorage
  setLocalStorage() {
    localStorage.setItem("operations", JSON.stringify(this.arrayOperations));
  }

  //crear history html
  createHtml(operation: string, result: string) {
    const view = {
      operation,
      result,
    };

    let containerCard = document.createElement("div");
    let viewOperation: any = document.createElement("p");
    let viewResult = document.createElement("p");

    containerCard.classList.add("containerCard");
    viewOperation.classList.add("operation");
    viewResult.classList.add("resultOperation");

    containerCard.appendChild(viewOperation);
    containerCard.appendChild(viewResult);
    this.renderer.appendChild(
      this.mostrarOperaciones.nativeElement,
      containerCard
    );

    this.container = containerCard;

    this.arrayOperations = [...this.arrayOperations, view];

    this.arrayOperations.forEach((element: any) => {
      this.container.querySelector(".operation").innerText = element.operation;
      this.container.querySelector(".resultOperation").innerText =
        element.result;
    });

    this.setLocalStorage();
  }

  //crear history html con el localstorage
  createStorage() {
    if (this.viewOperations.length > 0) {
      let containerCard = document.createElement("div");
      let viewOperation: any = document.createElement("p");
      let viewResult = document.createElement("p");

      containerCard.classList.add("containerCard");
      viewOperation.classList.add("operation");
      viewResult.classList.add("resultOperation");

      containerCard.appendChild(viewOperation);
      containerCard.appendChild(viewResult);
      this.renderer.appendChild(
        this.mostrarOperaciones.nativeElement,
        containerCard
      );

      this.container = containerCard;
      this.arrayResults = JSON.parse(this.viewOperations);
      this.arrayResults.forEach((element: any) => {
        this.createHtml(element.operation, element.result);
      });
    }
  }

  // limpiar el localstorage
  clearHistory() {
    if (this.arrayOperations.length > 0) {
      Swal.fire({
        icon: "question",
        title: "Desea limpiar el historial",
        showCancelButton: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("operations");
          Swal.fire({
            icon: "success",
            title: "El historial se limpio correctamente",
          }).then((result) => {
            if (result.isConfirmed) {
              let containerCard: any =
                document.getElementsByClassName("containerCard");

              Array.from(containerCard).forEach((item: any) => {
                item.remove();
              });
            }
          });
        }
      });
    } else {
      Swal.fire({
        icon: "info",
        title: "El historial esta limpio",
      });
    }
  }
}
