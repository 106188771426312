import { AfterViewInit, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable, Subject, Subscription, catchError, combineLatest, debounce, debounceTime, filter, forkJoin, map, of, tap } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BsModalService } from 'src/app/services/bs-modal.service';

@Component({
  selector: 'app-modal-find-user-form',
  templateUrl: './modal-find-user-form.component.html',
  styleUrls: ['./modal-find-user-form.component.css']
})
export class ModalFindUserFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() public onSubmitEvent = new Subject<any>();

  @Input() public mId = "modalSelectPaymentMethodForm";

  public mi: any;

  public previous: any[] = [];

  public records: any[] = [];

  public paginator = {
    total: 0,
    page: 1,
    totalPages: 0,
    pages: [],
  };

  public currentSearch = '';

  public form = this.fb.group({
    search: [''],
  });

  private sub$!: Subscription;

  constructor(
    private fb: FormBuilder,
    private bsModalSrv: BsModalService,
    private authSrv: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.form.get('search')?.valueChanges
      .pipe(
        debounceTime(300),
        map((value: any) => value.trim().toLowerCase()),
        /// take only value is different to empty string or currentSearch
        // filter((value: any) => value.length > 0 && value !== this.currentSearch)
      )
      .subscribe((value) => {
        // console.log('value', value);

        if (this.currentSearch === value) { return; }

        if (this.currentSearch !== value) {
          this.currentSearch = value;
          this.paginator = {
            total: 0,
            page: 1,
            totalPages: 0,
            pages: [],
          };
          this.previous = [];
          this.records = [];
          this.loadData({ search: value, page: 1 });
        }

      });
  }

  ngAfterViewInit(): void {
    this.buildModal();
  }

  ngOnDestroy(): void {
    if (this.sub$) { this.sub$.unsubscribe(); }
  }


  buildModal() { this.mi = this.bsModalSrv.buildModal(this.mId, {}); }

  openModal() {
    this.mi.show();
    this.loadData({});
  }

  closeModal(data = null, role = 'cancel') {
    this.mi.hide();
    this.onSubmitEvent.next({ data, role });

    this.records = [];
    this.paginator = {
      total: 0,
      page: 1,
      totalPages: 0,
      pages: [],
    };
    this.previous = [];
    this.form.patchValue({ search: '' });
  }


  loadData({ page = 1, limit = 10, search = '', field = 'email', order = 'asc', startAfter = null, endAt = null }) {

    let where: any[] = [];
    let options: any = {
      limit: limit,
      orderBy: [{ field, order }]
    };

    let paginator$: Observable<any>;
    let records$: Observable<any[]>;

    if (search.length > 0) {

      where = [
        { field: 'email', condition: '>=', value: search },
        { field: 'email', condition: '<=', value: search + '\uf8ff' }
      ];
      options = {
        limit: limit,
        orderBy: [{ field, order }]
      };

      paginator$ = this.authSrv.getDynamicCount(where)
        .pipe(
          map(count => {
            const totalPages = Math.ceil(count / limit);
            return {
              total: count,
              page: page,
              totalPages: totalPages,
              pages: Array.from({ length: totalPages }, (_, i) => i + 1),
            }
          }),
        );

      records$ = this.authSrv.getDynamic(where, options);

    } else {

      if (startAfter) {
        options['startAfter'] = startAfter;
      }

      if (endAt) {
        options['endAt'] = endAt;
        delete options.limit;
        options['limitToLast'] = limit;
      }

      paginator$ = this.authSrv.getDynamicCount(where)
        .pipe(
          map(count => {
            const totalPages = Math.ceil(count / limit);
            return {
              total: count,
              page: page,
              totalPages: totalPages,
              pages: Array.from({ length: totalPages }, (_, i) => i + 1),
            }
          }),
        );

      records$ = this.authSrv.getDynamic(where, options);
    }

    this.sub$ = combineLatest({
      records: records$,
      paginator: paginator$
    })
      .pipe(
        map(({ records, paginator }) => {
          return { records, paginator }
        }),

        catchError((err) => {
          return of({ records: [], paginator: 0 });
        }),
        // tap(console.log)
      )
      .subscribe(({ records, paginator }) => {
        this.records = records;
        this.paginator = paginator;
      });

  }

  onPreviousPage(page: number) {
    if (page < 1) { return; }
    const lastRecord = this.previous.pop();
    this.loadData({ page, endAt: lastRecord });
  }

  onNextPage(page: number) {
    if (page > this.paginator.totalPages) { return; }
    const lastRecord = this.records[this.records.length - 1];
    this.previous.push(lastRecord['email']);
    this.loadData({ page, startAfter: lastRecord['email'] });
  }

  onSubmit(item: any) {
    this.closeModal(item, 'submit');
  }

}
