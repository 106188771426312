import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appIntegerInput]'
})
export class IntegerInputDirective {

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const key = event.key;

    // Permitir teclas de navegación, retroceso, etc.
    const allowedKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter'];

    if (allowedKeys.includes(key)) {
      return; // Permitir la acción predeterminada
    }

    // Permitir solo números
    if (!key.match(/^\d$/)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevenir la acción de pegado predeterminada

    const clipboardData = event.clipboardData;
    
    if (clipboardData) {
      const pastedInput: string = clipboardData.getData('text/plain').replace(/\D/g, ''); // Eliminar todo lo que no sea dígito
      document.execCommand('insertText', false, pastedInput); // Insertar texto filtrado
    }
  }

  @HostListener('blur') onBlur() {
    let value = this.el.nativeElement.value;
    value = parseInt(value, 10);
    if (isNaN(value)) {
      this.el.nativeElement.value = ''; // Limpiar el campo si el resultado no es un número
    } else {
      this.el.nativeElement.value = value.toString(); // Actualizar el campo con el valor normalizado
    }
  }

}
