import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { MediaFileService } from 'src/app/services/media-file.service';
import { Sweetalert2Service } from 'src/app/services/sweetalert2.service';

@Component({
  selector: 'app-input-single-image',
  templateUrl: './input-single-image.component.html',
  styleUrls: ['./input-single-image.component.scss'],
})
export class InputSingleImageComponent implements OnInit {

  @ViewChild('fileUploadInputImage') fileUploadInputImage!: ElementRef<HTMLInputElement>;


  @Input() galleries: any[] = [];
  @Input() folder = 'multimedia';
  @Input() ext = 'png';
  @Input() accept = 'image/png, image/jpeg';
  @Input() size = 5242880;  // 1MB = 1048576 - Default 5 MB bytes
  @Input() multiple = false;

  @Output() onpUpdateImages = new Subject<any[]>();

  constructor(
    // private commonService: CommonService,
    // private actionSheetController: ActionSheetController,
    private sweetAlert2Srv: Sweetalert2Service,
    private mediaFileSrv: MediaFileService,
  ) { }

  ngOnInit() {
    // if (!this.avatar) { this.avatar = ''; }
    // console.log({ avatar: this.avatar });
  }


  /**
   * Selecionar origen de la imagen
   */
  async take() {
    // const device = (this.commonService.isCordova() || this.commonService.isCordovaIOS()) ? true : false;
    // const device = false;
    // let actionSheetOpts = [];

    // if (device) {
    //   actionSheetOpts = [].concat([
    //     {
    //       text: 'Camera',
    //       icon: 'camera-outline',
    //       role: 'native',
    //       handler: () => { this.selectImageFromCamera(); }
    //     },
    //     {
    //       text: 'Galery',
    //       icon: 'images-outline',
    //       role: 'native',
    //       handler: () => { this.selectImageFromDevice(); }
    //     },
    //   ]);
    // } else {
    //   actionSheetOpts = [].concat([
    //     {
    //       text: 'Document',
    //       icon: 'folder-outline',
    //       role: 'web',
    //       handler: () => { this.fileUploadInputImage.nativeElement.click(); }
    //     },
    //   ]);
    // }

    // actionSheetOpts.push({
    //   text: 'Cancel',
    //   icon: 'close',
    //   role: 'cancel',
    // });

    // const actionSheet = await this.actionSheetController.create({
    //   header: 'From',
    //   cssClass: 'my-custom-class',
    //   buttons: actionSheetOpts,
    // });

    // await actionSheet.present();
    this.fileUploadInputImage.nativeElement.click();
  }


  /**
   * Tomar desde la camara
   */
  async selectImageFromCamera() { }


  /**
   * Seleccionar desde el dispositivo
   */
  async selectImageFromDevice() { }


  /**
   * Capturar información del input file y retornar
   * @returns 
   */
  getInputDataFilesList(): FileList | null{
    const files = this.fileUploadInputImage.nativeElement.files;
    return files;
  }




  /**
   * Seleccionar desde el navegador
   * @param e
   */
  async selectImageFromBrowser(e: Event) {
    const files: any = this.getInputDataFilesList()

    /** Si no hay archivo seleccionado */
    if (files?.length === 0) { return; }

    const toBase64: any[] = [];
    let canContinue = true;

    for (const file of files) {

      if(file.size > this.size) { 
        this.sweetAlert2Srv.showError('El archivo seleccionado es muy grande, el tamaño máximo es de 5 MB');
        this.fileUploadInputImage.nativeElement.value = '';
        canContinue = false;;
        break;
      }
    
      toBase64.push( this.mediaFileSrv.getBase64(file) )
    }

    /** Los archivos no cumplen con el limite de tamaño */
    if(!canContinue) { return; }

    try {

      const result = await Promise.all(toBase64);
      this.galleries = result;
      this.fileUploadInputImage.nativeElement.value = '';
      this.onpUpdateImages.next(this.galleries);
      
      return;
    } catch (err) {
      console.log('Error on InputSingleImageComponent.selectImageFromBrowser', err);
      return;
    }

    // /** Validar si coincide el tamaño */
    // if(file.size > this.size){
    //   await this.commonService.presentAlert('El archivo excede el tamaña máximo permitido');
    //   this.fileUploadInputImage.nativeElement.value = null;
    //   return;
    // }

    // try {
    //   await this.commonService.presentLoading()
    //   const toBase64 = await this.mediaFileService.getBase64(file);
    //   this.avatar = toBase64 as string;

    //   /** Si posee identificador de documento cargamos archivo al servidor reactivamente*/
    //   if (this.upload) { await this.uploadReactive(); }

    //   this.fileUploadInputImage.nativeElement.value = null;
    //   this.onpUpdateImages.next(this.avatar);
    // } catch (err) {
    //   console.log('Error on InputSingleImage@selectImageFromBrowser', err);
    // } finally {
    //   this.commonService.dismissLoading();
    // }
  }


  /**
   * Cargar avatar de forma reactiva
   */
  async uploadReactive() {
    // const avatarUrl = await this.mediaFileService.uploadFileWithCustomFoler(this.avatar, this.folder, this.ext);
    // this.avatar = `${avatarUrl}`;
  }


  /**
   * Remover Avatar
   */
  async remove() {

    // /** Si posee identificador de documento eliminar reactivamente*/
    // if (this.upload) { await this.removeReactive(); }
    // this.avatar = '';

    // this.onpUpdateImages.next(this.avatar);
  }


  /**
   * Remover avatar de forma reactiva
   */
  async removeReactive() {
    // await this.mediaFileService.removeFileByUrl(this.avatar);
    // console.log('eliminar reactivo');
  }


  /**
   * Expandir imagen
   */
  show() { }

}
