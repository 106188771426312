<nav class="navbar navbar-expand-lg p-3">
  <div class="container-fluid">
    <a class="navbar-brand" href="/">
      <img src="assets/img/logo-new-3.png" alt="Bootstrap" width="130" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
        <li class="nav-item">
          <ng-container
            *ngIf="dataStatus$ | async as dataStatus; else noConnected"
            [ngTemplateOutlet]="connected"
            [ngTemplateOutletContext]="{ data: dataStatus }"></ng-container>
        </li>
        <li class="nav-item">
          <div class="btn-languaje" id="languaje-mobile">
            <button class="btn" type="button" (click)="menuLanguaje()">
              <img
                *ngIf="currentLanguage === 'en'"
                class="img-fluid"
                src="assets/img/estados-unidos.png"
                alt="bandera estado unidos" />
              <img
                *ngIf="currentLanguage === 'es'"
                class="img-fluid"
                src="assets/img/espana.png"
                alt="bandera espana" />
            </button>
            <ul class="menu-languaje">
              <li id="es" class="li-lgj" (click)="changeLanguage('es')">
                <img
                  class="img-fluid"
                  src="assets/img/espana.png"
                  alt="bandera espana" />
              </li>
              <li id="pt" class="li-lgj" (click)="changeLanguage('en')">
                <img
                  class="img-fluid"
                  src="assets/img/estados-unidos.png"
                  alt="bandera estado unidos" />
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<ng-template #noConnected>
  <button
    class="btn btn-transparent"
    (click)="launch()"
    [innerHTML]="'general.connectYourWallet' | translate"></button>
</ng-template>

<ng-template #connected>
  <button
    class="btn btn-transparent"
    (click)="logOut()"
    [innerHTML]="'general.logOut' | translate"></button>
</ng-template>
