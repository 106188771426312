<div class="modal fade" [id]="mId" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-blue">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title" [id]="mId + 'Label'">Tarjeta de Crédito</h4>
                <button type="button" class="btn-close" (click)="closeModal()">
                    <img src="assets/img/close-icon.png" alt="" />
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-12 align-items-center">

                            <h5 class="title text-yellow text-center text-uppercase mb-1">
                                datos del cliente
                            </h5>

                            <p class="text-center text mb-3 text-yellow">
                                A continuación te solicitamos amablemente una información, que
                                nos <br> permitirá crea a lo orden de compra.
                            </p>

                            <form class="form-blue" [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <label class="form-label">
                                            Nombre Completo<span class="text-danger">*</span>
                                        </label>
                                        <input class="mb-3 form-control" type="text" placeholder="Nombre Completo" formControlName="username" />
                                        <ng-container [ngTemplateOutlet]="renderVM" [ngTemplateOutletContext]="{ field: 'username' }"></ng-container>
                                    </div>

                                    <div class="col-12 col-lg-6">
                                        <label class="form-label">
                                            Correo electrónico<span class="text-danger">*</span>
                                        </label>
                                        <input class="mb-3 form-control" type="text" placeholder="Correo electrónico" formControlName="useremail" />
                                        <ng-container [ngTemplateOutlet]="renderVM" [ngTemplateOutletContext]="{ field: 'useremail' }"></ng-container>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <label class="form-label">
                                            Teléfono<span class="text-danger">*</span>
                                        </label>
                                        <input class="mb-3 form-control" type="text" appIntegerInput placeholder="Teléfono" formControlName="userphone" />
                                        <ng-container [ngTemplateOutlet]="renderVM" [ngTemplateOutletContext]="{ field: 'userphone' }"></ng-container>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <label class="form-label">
                                            Número de documento<span class="text-danger">*</span>
                                        </label>
                                        <input class="mb-3 form-control" type="text" appIntegerInput placeholder="Número de documento" formControlName="numdoc" />
                                        <ng-container [ngTemplateOutlet]="renderVM" [ngTemplateOutletContext]="{ field: 'numdoc' }"></ng-container>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <label class="form-label">
                                            Dirección<span class="text-danger">*</span>
                                        </label>
                                        <!-- <input class="mb-3 form-control" type="text" placeholder="Dirección" formControlName="address" /> -->
                                        <textarea class="form-control" formControlName="address" cols="30" rows="2"></textarea>
                                        <ng-container [ngTemplateOutlet]="renderVM" [ngTemplateOutletContext]="{ field: 'address' }"></ng-container>
                                    </div>

                                    <div class="col-12">
                                        <label class="form-label">Adicional</label>
                                        <textarea class="form-control" formControlName="additional" cols="30" rows="3"></textarea>
                                        <ng-container [ngTemplateOutlet]="renderVM" [ngTemplateOutletContext]="{ field: ' additional' }"></ng-container>
                                    </div>

                                    <div class="col-12 mt-3">
                                        <label class="check">
                                            Acepto los Términos & Condiciones<span class="text-danger">*</span>
                                            <input type="checkbox" formControlName="check" />
                                            <span class="checkmark"></span>
                                        </label>
                                        <ng-container [ngTemplateOutlet]="renderVM" [ngTemplateOutletContext]="{ field: 'check' }"></ng-container>
                                    </div>

                                    <div class="col-12 d-flex justify-content-center mt-4">
                                        <ng-container *ngIf="(showSubmitLoaderButton); then renderSubmitLoaderButton; else renderSubmitButton"></ng-container>
                                    </div>

                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #renderSubmitButton>
    <button type="submitted" class="btn-main font-bold btn">
        <span>Enviar</span>
    </button>
</ng-template>

<ng-template #renderSubmitLoaderButton>
    <button type="button" class="btn-main font-bold btn" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Loading...
    </button>
</ng-template>

<ng-template #renderVM let-field="field">
    <div class="mb-3 mx-2" *ngIf="submitted">
        <ng-container *ngFor="let val of vm[field]">
            <span class="text-danger" *ngIf="f[field].hasError(val.type)">
                {{ val.message | titlecase }}
            </span>
        </ng-container>
    </div>
</ng-template>