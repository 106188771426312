import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, map } from "rxjs";
import { checkReferralCodeStored } from "src/app/helpers/custom-validations.helper";
import { getFormErrors } from "src/app/helpers/formErrors.helper";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Web3Service } from "src/app/services/contract/web3.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { Sweetalert2stepsService } from "src/app/services/sweetalert2steps.service";

@Component({
  selector: "app-profile-register-referral-code-form",
  templateUrl: "./profile-register-referral-code-form.component.html",
  styleUrls: ["./profile-register-referral-code-form.component.css"],
})
export class ProfileRegisterReferralCodeFormComponent implements OnInit {
  /**
   * Pasos para completar el registro
   * 1. Formulario de comenzar
   * 2. Formulario para tipar el código de referido
   */
  public step = 1;

  public userDoc$!: Observable<null | any>;
  public dataStatus$!: Observable<any>;

  public form: FormGroup;
  public vm: any = {
    code: [
      { type: "required", message: "form.validations.referralCodeIsRequired" },
      {
        type: "pattern",
        message:
          "form.validations.theReferralCodeCanOnlyContainLettersAndNumbers",
      },
      {
        type: "minlength",
        message: "form.validations.theReferralCodeMustBeAtLeast3CharactersLong",
      },
      {
        type: "maxlength",
        message:
          "form.validations.theReferralCodeMustHaveAMaximumOf12Characters",
      },
      {
        type: "referralCodeStored",
        message:
          "form.validations.theReferralCodeIsAlreadyRegisteredInTheSystem",
      },
    ],
  };
  public submitted = false;

  constructor(
    private authSrv: AuthenticationService,
    private web3Srv: Web3Service,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private sweetAlert2Srv: Sweetalert2Service,
    private alertStepsSrv: Sweetalert2stepsService
  ) {
    this.form = this.fb.group({
      code: [
        "",
        [
          Validators.required,
          Validators.pattern(/^[a-zA-Z0-9]+$/),
          Validators.minLength(3),
          Validators.maxLength(12),
        ],
        [checkReferralCodeStored(this.web3Srv)],
      ],
    });
  }

  ngOnInit(): void {
    this.userDoc$ = this.authSrv.userDoc$;

    /** Escuchar wallet */
    this.dataStatus$ = this.web3Srv.accountStatus$.pipe(
      map((data: any[]) => (data && data.length > 0 ? data[0] : null))
    );
  }

  get f() {
    return this.form.controls;
  }

  get codeParsed() {
    const code = this.f["code"].value || "";
    return `${code}`.trim().toLowerCase();
  }

  async onSubmit() {
    try {
      this.submitted = true;

      if (!this.form.valid) {
        const formErros = getFormErrors(this.form);
        console.log("formErros", formErros);
        return;
      }

      const formData = this.form.value;
      console.log("Try to store", formData);

      /** Mostrar formulario web3 */
      this.step = 3;
      return;
    } catch (err) {
      console.log(
        "Error on ProfileRegisterReferralCodeFormComponent.onSubmit",
        err
      );
      return;
    }
  }

  async connectAccount() {
    return this.web3Srv.launchAskConnectionType();
  }

  async getReferralCode() {
    try {
      await this.spinner.show();

      /** Obtener UID de la sesión */
      const uid: any = await this.authSrv.getUIDPromise();
      console.log("UID", uid);

      if (!uid) {
        this.sweetAlert2Srv.showError("errors.unableToObtainSessionUID");
        return;
      }

      /** Obtener documento de usuario */
      const userDoc = await this.authSrv.getByUID(uid);
      console.log("userDoc", userDoc);

      if (!userDoc) {
        this.sweetAlert2Srv.showError(
          "errors.theUsersDocumentCouldNotBeObtained"
        );
        return;
      }

      /** Obtener wallet conectada */
      const account = this.web3Srv.accounts[0];
      console.log("account", account);

      if (!account) {
        this.sweetAlert2Srv.showError(
          "errors.unableToObtainTheConnectedWallet"
        );
        return;
      }

      /** Válidar si la wallet registrada es la conectada */
      if (account !== userDoc.walletAddress) {
        this.sweetAlert2Srv.showError(
          "errors.theConnectedWalletDoesNotMatchTheOneRegisteredInTheSystem"
        );
        return;
      }

      const walletStored = await this.web3Srv.vendor_referral_addressExist_OC(
        userDoc.walletAddress
      );
      console.log("walletStored", walletStored);

      if (walletStored) {
        this.sweetAlert2Srv.showError(
          "errors.theWalletIsAlreadyRegisteredInTheSystem"
        );
        return;
      }

      /** Disparar evento web3 */
      return this.launchWeb3Transaction({ uid, userDoc });
    } catch (err) {
      console.log(
        "Error on ProfileRegisterReferralCodeFormComponent.getReferralCode",
        err
      );
      return;
    } finally {
      this.spinner.hide();
    }
  }

  async launchWeb3Transaction(params: any = {}) {
    try {
      const { uid, userDoc } = params;

      const result = await this.alertStepsSrv.showStepsGeneral({
        askMessage: `referralCodeRegister.transactionWithYourWallet`,
        contractParams: {
          method: "vendor_referral_store",
          params: [userDoc.walletAddress, this.codeParsed, true],
        },
      });

      if (!result.status) {
        this.alertStepsSrv.showBasicAlert(result.data.message, "error");
        return;
      }
      /** Actualizar documento de usuario */
      await this.authSrv.update(uid, {
        userCode: this.codeParsed,
        statusCode: true,
      });

      /** Cerrar sesión web3 */
      this.web3Srv.logout(false);
      this.submitted = false;

      this.alertStepsSrv.showBasicAlert(
        `referralCodeRegister.yourCodeWasSuccessfullyRegisteredAndGenerated!`,
        "success"
      );
      return;
    } catch (err) {
      console.log(
        "Erorr on ProfileRegisterReferralCodeFormComponent.launchWeb3Transaction",
        err
      );
      return;
    }
  }
}
