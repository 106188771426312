import { Inject, Injectable, OnInit } from "@angular/core";
import { WEB3 } from "../../core/web3";
import { from, map, Observable, Subject, tap } from "rxjs";
// import Web3Modal from "web3modal";
// import WalletConnectProvider from "@walletconnect/web3-provider";

import { provider } from "web3-core";
import { AbiService } from "src/app/services/contract/abi.service";
import { environment } from "src/environments/environment";
import {
  chunkArray,
  customArrayNumber,
  fromWei,
  getCurrentDateTimeBlock,
  toGwei,
  toWei,
} from "src/app/helpers/utils";
import Swal from "sweetalert2";
import BigNumber from "bignumber.js";
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from "@angular/forms";

/// @dev walletconnect
import {
  EthereumProvider,
  EthereumProviderOptions,
} from "@walletconnect/ethereum-provider";
import Web3 from "web3";
import { BscGasService } from "../bsc-gas.service";
import { PolygonGasService } from "../polygon-gas.service";
declare let window: any;
const METHOD_CONNECT = "__eth_connect";
const WALLET_CONNECT = "__eth_wallet";

@Injectable({
  providedIn: "root",
})
export class Web3Service implements OnInit {
  /** Instancia para obtener wallets conectadas */
  public accountStatusSource = new Subject<any>();
  accountStatus$ = this.accountStatusSource.asObservable();

  /** Instancia del provider */
  public provider: any;

  /** Tipo de provider utilizado para conectar */
  private providerType: any;

  /** Arreglo de wallets conectadas */
  public accounts: any | undefined;

  /** web3 Instance */
  private web3js: any;

  /** web3Modal Instance */
  private web3Modal: any;

  /** ERC20 ABI */
  public erc20ABI = "/assets/abi/erc20.json";

  /** ERC721 ABI */
  public erc721ABI = "/assets/abi/erc721f.json";

  /** Chainlink Oracle ABI */
  public oracleABI = "/assets/abi/OracleABI.json";

  /** ERC721 ABI */
  public mainRedABI = "/assets/abi/MainRed.json";

  /** MarketPlace */
  public marketplaceAbi = "/assets/abi/MarketplaceElysiumObject.json";

  /** Factory */
  public factoryAbi = "/assets/abi/factoryObject.json";

  /** vendor */
  public vendorABI = "/assets/abi/Vendor.json";

  /** distribution ABI */
  public distributionABI = "/assets/abi/Distribution.json";

  public elysiumERC721aABI = "/assets/abi/ElysiumNFTCollection.json";

  /** Contrato provisional de retiro ABI */
  public ncwABI = "/assets/abi/NCW.json";

  /** Fee Interno por transacción */
  public internalTxFee = 0.5;

  ethereumProvider: any;

  constructor(
    @Inject(WEB3) private web3: Web3,
    public abiService: AbiService,
    private bscGasSrv: BscGasService,
    private polygonGasSrv: PolygonGasService
  ) {}

  async ngOnInit(): Promise<void> {
    if (window.ethereum) {
      this.web3 = new Web3(window.ethereum);
      // Request account access if needed
      window.ethereum.enable().catch(console.error);
    } else if (window.web3) {
      // Use Mist/MetaMask's provider
      this.web3 = new Web3(window.web3.currentProvider);
    } else {
      console.log(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  async launchAskConnectionType() {
    const swalPromise = new Promise((resolve, reject) => {
      Swal.fire({
        // title: "Connect With",
        showCancelButton: false,
        showConfirmButton: false,
        focusConfirm: false,
        html:
          '<div class="d-grid gap-2 alert-connect">' +
          '<h5 class="title-alert"> Conectar con</h5>' +
          '<button id="metamask-button" class="btn btn-main swCT">' +
          '<img src="assets/img/metamask_logo.png" alt="Metamask" style="max-height: 20px;">' +
          "</button>" +
          '<button id="walletconnect-button" class="btn btn-main swCT">' +
          '<img src="assets/img/walletconnect_logo.png" alt="WalletConnect" style="max-height: 20px;">' +
          "</button>" +
          '<button id="cancel-button" class="btn btn-main swCT"> Cancelar</button>' +
          "</div>",
        didOpen: () => {
          document
            .getElementById("metamask-button")
            .addEventListener("click", () => {
              Swal.close();
              resolve("metamask");
            });

          document
            .getElementById("walletconnect-button")
            .addEventListener("click", () => {
              Swal.close();
              resolve("walletconnect");
            });

          document
            .getElementById("cancel-button")
            .addEventListener("click", () => {
              Swal.close();
              resolve("cancel");
            });
        },
      });
    });

    const selectedOption = await swalPromise;
    // console.log('selectedOption', selectedOption);
    switch (selectedOption) {
      case "metamask":
        return this.connectAccountMetaMask();
      case "walletconnect":
        return this.connectAccountWalletConnect();
      default:
        // console.log('cancel');
        return;
    }
  }

  checkAlreadyConnected() {
    const method = localStorage.getItem(METHOD_CONNECT);

    switch (method) {
      case "1":
        this.connectAccountWalletConnect();
        break;

      case "2":
        this.connectAccountMetaMask();
        break;

      default:
        console.log("No session found");
        break;
    }
  }

  /**
   * TODO: funcion temapopral para conectar con walletconnect
   * hasta que solucione el problema de la sesion de walletconnect
   * @returns
   */
  restartingConnection() {
    const walletConnect = localStorage.getItem(WALLET_CONNECT) || null;
    console.log("restartingConnection....");
    console.log("this.web3js", this.web3js);
    console.log("this.ethereumProvider", this.ethereumProvider);

    if (!this.web3js || !this.ethereumProvider || !walletConnect) {
      console.error("no hay conexion");
      localStorage.setItem(METHOD_CONNECT, "0");

      /// @DEV des
      setTimeout(() => {
        this.logout();
      }, 300);

      return;
    }

    console.log("si hay conexion", walletConnect);

    setTimeout(() => {
      this.accounts = [walletConnect];

      ///
      this.accountStatusSource.next([walletConnect]);
    }, 300);
  }

  /**
   * @dev conectar con metamask
   * @returns
   */
  connectAccountMetaMask() {
    console.log("connectAccountMetaMask....");
    return new Promise(async (resolve, reject) => {
      try {
        /// @dev cargar provider in web3
        this.web3js = new Web3(window.ethereum);

        /// @dev check if metamask is installed
        await window.ethereum.request({ method: "eth_requestAccounts" });

        /// @dev get accounts
        this.accounts = await this.web3js.eth.getAccounts();

        http: console.warn("this.accounts", this.accounts);

        /// @dev cuando se conecta con metamask send accounts
        this.accountStatusSource.next(this.accounts);

        /// @dev check network
        await this.checkNetworkLocal();

        /// @dev initialize events
        this.initializeEventsMetamask();

        /// @dev guardar metodo de conexion
        localStorage.setItem(WALLET_CONNECT, this.accounts[0]);

        /// @dev guardar metodo de conexion
        localStorage.setItem(METHOD_CONNECT, "2");

        /// @dev get report
        /**
         * TODO:
         */
        if (!environment.production) {
          // this.getReport()
        }

        resolve(this.accounts);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  }

  /**
   * TODO: nueva version de walletconnect
   */
  async connectAccountWalletConnect() {
    console.log("connectAccount  wallet conect....");
    return new Promise(async (resolve, reject) => {
      try {
        // @dev inicializar provider
        this.ethereumProvider = await this.ethereumProviderInit();

        // @sesion de wallet conectada
        console.log("this.ethereumProvider", this.ethereumProvider.signer);

        // @dev enable provider
        await this.ethereumProvider.enable();

        /// @dev cargar provider in web3
        this.web3js = new Web3(this.ethereumProvider);

        this.accounts = await this.web3js.eth.getAccounts();

        console.warn("this.accounts", this.accounts);

        console.log("this.accounts", this.accounts);
        this.accountStatusSource.next(this.accounts);

        this.initializeEventsWalletconnect();

        /// @dev guardar metodo de conexion
        localStorage.setItem(METHOD_CONNECT, "1");

        /// @dev
        localStorage.setItem(WALLET_CONNECT, this.accounts[0]);

        resolve(this.accounts);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  }

  /**
   * @dev check network
   */
  private async initializeEventsWalletconnect() {
    this.ethereumProvider.on("connect", async () => {
      console.log("connect");
    });

    // @dev cuando se desconecta
    this.ethereumProvider.on("disconnect", async () => {
      console.log("disconnect");
      window.location.reload();
    });

    /// @dev cuando se actualiza la sesion
    this.ethereumProvider.on(
      "session_update",
      async (error: any, payload: any) => {
        console.log("session_update");
      }
    );

    /// @dev cuando se actualiza la sesion
    this.ethereumProvider.on("accountsChanged", async (accounts: any) => {
      console.log("accountsChanged");
      // window.location.reload();
    });

    /// @dev cuando se actualiza la sesion
    this.ethereumProvider.on("chainChanged", async (chainId: any) => {
      console.log("chainChanged");
    });
  }

  /**
   * @dev check network
   */
  private initializeEventsMetamask() {
    window.ethereum.on("accountsChanged", (accounts: string[]) => {
      console.log("Accounts changed:", accounts);
      // Implement logic for handling account changes
      window.location.reload();
    });

    window.ethereum.on("chainChanged", (chainId: string) => {
      console.log("Chain changed:", chainId);
      // Implement logic for handling chain changes
      window.location.reload();
    });

    window.ethereum.on("connect", (connectInfo: { chainId: string }) => {
      console.log("Connected:", connectInfo.chainId);
      // Implement logic for handling connection
      window.location.reload();
    });

    window.ethereum.on(
      "disconnect",
      (error: { code: number; message: string }) => {
        console.log("Disconnected:", error.message);
        // Implement logic for handling disconnection
        window.location.reload();
      }
    );
  }

  /**
   *
   * @returns
   */
  async ethereumProviderInit() {
    const providerConfig: EthereumProviderOptions = {
      projectId: environment.chain.walletConnectID,
      showQrModal: true,
      qrModalOptions: { themeMode: "dark" },
      chains: [environment.chain.chainId],
      rpcMap: { [environment.chain.chainId]: environment.chain.rpc },
      methods: ["eth_sendTransaction", "personal_sign"],
      events: ["chainChanged", "accountsChanged"],
      metadata: {
        name: "Investok",
        description:
          "Financial Innovation  decentralized and transparent A market of movements in high speed PROTOCOLS that provide liquidity and generate automatic, secure, and sustainable daily rewards.",
        url: environment.urlWeb,
        icons: [environment.urlLogo],
      },
    };
    // console.log('providerConfig', providerConfig);

    const result = await EthereumProvider.init(providerConfig);

    return result;
  }

  /**
   *
   * @param reload
   */
  async logout(reload = true) {
    const method = localStorage.getItem(METHOD_CONNECT);

    if (method == "1") {
      await this.ethereumProvider.disconnect();
    }

    /**
     * TODO:  de momento no se puede habilitar
     * - Ejecutar esto acarrea que se borre el wishlist del usuario
     */
    // window.localStorage.clear();

    this.accounts = null;
    this.provider = null;
    this.accountStatusSource.next(null);

    /** Eliminar variables de conexión anterior */
    localStorage.removeItem(METHOD_CONNECT);
    localStorage.removeItem(WALLET_CONNECT);

    if (reload) {
      window.location.reload();
    }
  }

  /**
   * TODO: se debe actuializar a esta version a la nueva version de walletconnect
   */
  async checkWalletConnectProviderConnection() {
    /** Validar si corresponde al provider */
    if (this.providerType !== "walletconnect") return true;

    /** Validar si es la red correcta */
    const providerChainId = await this.web3js.eth.net.getId();
    if (providerChainId == environment.chain.chainId) return true;

    await this.web3Modal.clearCachedProvider();
    await this.provider.close();
    this.provider = null;

    Swal.fire({
      title: environment.projectName,
      icon: "error",
      text: `Network error, please connect to ${environment.chain.chainName}`,
    });
    return false;
  }

  /**
   * TODO: se debe actuializar a esta version a la nueva version de walletconnect
   */
  async checkNetworkLocal() {
    const chainId = await this.web3js.eth.net.getId();
    if (chainId != environment.chain.chainId) {
      const modalChangeChain = await Swal.fire({
        title: environment.projectName,
        icon: "warning",
        text: `Please switch to ${environment.chain.chainName}`,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: "Change",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const runChange = await this.changeChainIdOrAdd();
          } catch (err: any) {
            // console.log('modal error', err);
            Swal.showValidationMessage(`${err.message}`);
          }
        },
      });

      // console.log({modalChangeChain});
    }
  }

  /**
   *  TODO: se debe actuializar a esta version a la nueva version de walletconnect
   * @returns
   */
  async changeChainIdOrAdd() {
    try {
      const tryChange = await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: environment.chain.chainIdMetamask }],
      });

      // console.log({tryChange});

      return tryChange;
    } catch (err: any) {
      /** Si no tiene la red registrada */
      if (err.code === 4902) {
        return await this.addChainId();
      }

      /** Si tiene trasacciones pendientes por ejecutar */
      if (err.code === 4001) {
        err.message = "Please, confirm the request for change of network";
      }

      /** Si tiene trasacciones pendientes por ejecutar */
      if (err.code === -32002) {
        err.message =
          "You have pending requests on your wallet. Please, check on your wallet before continuing";
      }

      throw err;
    }
  }

  /**
   * TODO: se debe actuializar a esta version a la nueva version de walletconnect
   * @returns
   */
  async addChainId() {
    try {
      const wasAdded = await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: environment.chain.chainIdMetamask,
            rpcUrls: environment.chain.rpcUrls,
            chainName: environment.chain.chainName,
            nativeCurrency: environment.chain.nativeCurrency.symbol,
            blockExplorerUrls: environment.chain.blockExplorerUrls,
          },
        ],
      });

      // console.log({wasAdded});
      return wasAdded;
    } catch (err: any) {
      alert(`Please connect to ${environment.chain.chainName}`);
    }
  }

  /**
   * Validar estado de la red
   */
  async checkNetwork() {
    const networkId = await this.web3js.eth.net.getId();
    console.log("networkId", networkId);
    if (environment.chain.chainId != networkId) {
      alert("Please connect to Binance Smart Chain");
      return false;
    }

    return true;
  }

  /** ===============================================================
   *                      Native Methods
   ================================================================ */

  /**
   * Obtiene el balance de token nativo
   * @param account
   * @returns
   */
  async balanceOfNative(account: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.web3js.eth.getBalance(account, (err: any, res: any) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve(res);
        }
      });
    });
  }

  /**
   * Válida balance de usuario en token nativo
   * @param amount
   * @returns
   */
  async checkUserBalanceNative(amount: string) {
    const balance = await this.balanceOfNative(this.accounts[0]);
    const balanceParse = new BigNumber(`${balance}`);
    return balanceParse.isGreaterThanOrEqualTo(amount);
  }

  /* =======================================================
   *                     ERC20 Methods
   * ===================================================== */

  async erc20_approve(
    erc20Contract: string,
    contractAddress: string,
    amount: string
  ) {
    const [account] = this.accounts;
    return await this.calculateAndCallCustomABI({
      contractAddress: erc20Contract,
      method: "approve",
      params: [contractAddress, amount],
      callType: "send",
      optionals: { from: account },
      urlABI: this.erc20ABI,
    });
  }

  /**
   * Consultar balance de token ERC20
   * @param contractAddress               Dirección del contrato
   * @param account                       Dirección de la wallet
   * @returns
   */
  async erc20_balanceOf(contractAddress: string, account: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: contractAddress,
      method: "balanceOf",
      params: [account],
      callType: "call",
      urlABI: this.erc20ABI,
    });
  }

  /**
   * Verificar balance de usuario
   * @param contractAddress               Dirección del contrato
   * @param amount                        Cantidad a transferir en WEI
   * @returns
   */
  async erc20_checkUserBalance(contractAddress: string, amount: any) {
    const [account] = this.accounts;
    const balance = await this.erc20_balanceOf(contractAddress, account);
    const balanceParse = new BigNumber(balance);
    return balanceParse.isGreaterThanOrEqualTo(amount);
  }

  /** ===============================================================
   *                       ERC721A METHODS
   * ================================================================ */

  async erc721_MAX_SUPPLY_OFFCHAIN(contract: string) {
    return await this.callDinamyContractOffLine({
      contractAddress: contract,
      method: "MAX_SUPPLY",
      params: null,
      callType: "call",
      urlABI: this.erc721ABI,
    });
  }

  async erc721_totalSupply_OFFCHAIN(contract: string) {
    return await this.callDinamyContractOffLine({
      contractAddress: contract,
      method: "totalSupply",
      params: null,
      callType: "call",
      urlABI: this.erc721ABI,
    });
  }

  async erc721_availableSupply_OFFCHAIN(contract: string) {
    try {
      const [maxSupply, totalSupply] = await Promise.all([
        this.erc721_MAX_SUPPLY_OFFCHAIN(contract),
        this.erc721_totalSupply_OFFCHAIN(contract),
      ]);

      return Number(maxSupply) - Number(totalSupply);
    } catch (err) {
      console.log("Error on Web3Service.erc721_availableSupply_OFFCHAIN", err);
      return 0;
    }
  }

  async erc721_getAllInfo(addr: string) {
    try {
      const [name, symbol, totalSupply, supply, baseTokenURI, owner] =
        await Promise.all([
          this.callDinamyContractOffLine({
            contractAddress: addr,
            method: "name",
            callType: "call",
            urlABI: this.elysiumERC721aABI,
          }),
          this.callDinamyContractOffLine({
            contractAddress: addr,
            method: "symbol",
            callType: "call",
            urlABI: this.elysiumERC721aABI,
          }),
          this.callDinamyContractOffLine({
            contractAddress: addr,
            method: "MAX_SUPPLY",
            callType: "call",
            urlABI: this.elysiumERC721aABI,
          }),
          this.callDinamyContractOffLine({
            contractAddress: addr,
            method: "totalSupply",
            callType: "call",
            urlABI: this.elysiumERC721aABI,
          }),
          this.callDinamyContractOffLine({
            contractAddress: addr,
            method: "baseTokenURI",
            callType: "call",
            urlABI: this.elysiumERC721aABI,
          }),
          this.callDinamyContractOffLine({
            contractAddress: addr,
            method: "owner",
            callType: "call",
            urlABI: this.elysiumERC721aABI,
          }),
        ]);

      return {
        addr: addr,
        name,
        symbol,
        totalSupply,
        supply,
        baseTokenURI,
        owner,
      };
    } catch (err) {
      console.log("Error on Web3Service.erc721_getAllInfo", err);

      return {
        addr: addr,
        name: "",
        symbol: "",
        totalSupply: 0,
        supply: 0,
        baseTokenURI: "#",
        owner: "",
      };
    }
  }

  async erc721_addRole(collection: string, addr: string) {
    return this.calculateAndCallCustomABI({
      contractAddress: collection,
      method: "addRole",
      params: [addr],
      callType: "send",
      urlABI: this.elysiumERC721aABI,
    });
  }

  /** ===============================================================
   *                 VENDOR Buy METHODS
   * ================================================================ */

  vendor_buy_buyWithToken(
    token: string,
    id: number,
    amount: string,
    code: string
  ) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "buyWithToken",
      params: [token, id, amount, code],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  /**
   *
   * @param SCtoken
   * @param collectionID
   * @param listing_id
   * @param price
   * @returns
   */
  vendor_buy_buyNative(
    id: number,
    token: string,
    amount: string,
    code: string,
    price: string
  ) {
    return this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "buyNative",
      params: [id, token, amount, code],
      callType: "send",
      optionals: { value: price },
      urlABI: this.vendorABI,
    });
  }

  /** ===============================================================
   *                 VENDOR Oracle METHODS
   * ================================================================ */

  async vendor_oracle_parseUSDtoToken_OFFCHAIN(amount: string, addr: string) {
    console.log("vendor_oracle_parseUSDtoToken_OFFCHAIN", amount, addr);
    return await this.callDinamyContractOffLine({
      contractAddress: environment.vendorAddress,
      method: "parseUSDtoToken",
      params: [amount, addr],
      callType: "call",
      urlABI: this.vendorABI,
    });
  }

  /** ===============================================================
   *                 VENDOR Administered METHODS
   * ================================================================ */

  async vendor_administered_addAdmin(wallet: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "addAdmin",
      params: [wallet],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  async vendor_administered_addUser(wallet: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "addUser",
      params: [wallet],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  async vendor_administered_renounceAdmin() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "renounceAdmin",
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  async vendor_administered_removeUser(wallet: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "removeUser",
      params: [wallet],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  async vendor_administered_isAdmin(wallet: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "isAdmin",
      params: [wallet],
      callType: "call",
      urlABI: this.vendorABI,
    });
  }

  async vendor_administered_isUser(wallet: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "isUser",
      params: [wallet],
      callType: "call",
      urlABI: this.vendorABI,
    });
  }

  async vendor_administered_getRoles(addr: string) {
    try {
      const [vendorIsAdmin, vendorIsUser] = await Promise.all([
        this.vendor_administered_isAdmin(addr),
        this.vendor_administered_isUser(addr),
      ]);

      return {
        vendorIsAdmin,
        vendorIsUser,
      };
    } catch (err) {
      console.log("Error on Web3Service.vendor_administered_getRoles", err);

      return {
        vendorIsAdmin: false,
        vendorIsUser: false,
      };
    }
  }

  /** ===============================================================
   *                   VENDOR Whitelist METHODS
   * ================================================================ */

  async vendor_whitelist_addToken(
    addr: string,
    orc: string,
    act: boolean,
    ntv: boolean
  ) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "addToken",
      params: [addr, orc, act, ntv],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  async vendor_whitelist_updateToken(
    id: any,
    type: number,
    addr: string,
    bool: boolean
  ) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "updateToken",
      params: [id, type, addr, bool],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  async vendor_whitelist_tokensList() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "tokensList",
      callType: "call",
      urlABI: this.vendorABI,
    });
  }

  async vendor_whitelist_tokensListoc() {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.vendorAddress,
      method: "tokensList",
      callType: "call",
      urlABI: this.vendorABI,
    });
  }

  /** ===============================================================
   *                 VENDOR Code METHODS
   * ================================================================ */

  async vendor_code_addToken(
    addr: string,
    code: string,
    pg: number,
    status: boolean
  ) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "store",
      params: [addr, code, pg, status],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  async vendor_code_update(id: any, tp: number, pg: number, status: boolean) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "update",
      params: [id, tp, pg, status],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  async vendor_code_collectionCount() {
    const snapshot = await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "referralCount",
      callType: "call",
      urlABI: this.vendorABI,
    });
    return Number(snapshot);
  }

  async vendor_code_codeList(from: number, to: number) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "getReferralList",
      params: [from, to],
      callType: "call",
      urlABI: this.vendorABI,
    });
  }

  async vendor_code_getAllCollections() {
    try {
      const collectionCount = await this.vendor_code_collectionCount();
      return await this.vendor_code_codeList(0, collectionCount);
    } catch (err) {
      console.log(
        "Error on Web3Service.vendor_whitelist_getAllCollections",
        err
      );
      return [];
    }
  }

  /** ===============================================================
   *                 VENDOR Collection METHODS
   * ================================================================ */

  async vendor_collection_getCollectionByAddr(addr: string) {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.vendorAddress,
      method: "getCollectionByAddr",
      params: [addr],
      callType: "call",
      urlABI: this.vendorABI,
    });
  }

  async vendor_collection_addCollection(
    name: string,
    symbol: string,
    maxSupply: number,
    baseTokenURI: string,
    price: string,
    active: boolean
  ) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "addCollection",
      params: [name, symbol, maxSupply, baseTokenURI, price, active],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  async vendor_collection_importCollection(
    addr: string,
    price: string,
    active: boolean
  ) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "importCollection",
      params: [addr, price, active],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  async vendor_collection_updateCollection(
    id: number,
    type: number,
    price: string,
    active: boolean
  ) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "updateCollection",
      params: [id, type, price, active],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  async vendor_collection_transferReserved(
    idx: number,
    addr: string,
    nro: number
  ) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "transferReserved",
      params: [idx, addr, nro],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  async vendor_collection_collectionCount() {
    const snapshot = await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "collectionCount",
      callType: "call",
      urlABI: this.vendorABI,
    });
    return Number(snapshot);
  }

  async vendor_collection_collectionList(from: number, to: number) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "collectionList",
      params: [from, to],
      callType: "call",
      urlABI: this.vendorABI,
    });
  }

  async vendor_collection_getAllCollections() {
    try {
      const collectionCount = await this.vendor_collection_collectionCount();
      return await this.vendor_collection_collectionList(0, collectionCount);
    } catch (err) {
      console.log(
        "Error on Web3Service.vendor_whitelist_getAllCollections",
        err
      );
      return [];
    }
  }
  async vendor_collection_collectionCount_OC() {
    const snapshot = await this.callDinamyContractOffLine({
      contractAddress: environment.vendorAddress,
      method: "collectionCount",
      callType: "call",
      urlABI: this.vendorABI,
    });
    return Number(snapshot);
  }

  async vendor_collection_collectionList_OC(from: number, to: number) {
    const snapshot = await this.callDinamyContractOffLine({
      contractAddress: environment.vendorAddress,
      method: "collectionList",
      params: [from, to],
      callType: "call",
      urlABI: this.vendorABI,
    });

    return snapshot.map((item: any) => ({
      addr: item.addr,
      price: item.price,
      active: item.active,
    }));
  }

  async vendor_collection_getAllCollections_OC() {
    try {
      const collectionCount = await this.vendor_collection_collectionCount_OC();
      return await this.vendor_collection_collectionList_OC(0, collectionCount);
    } catch (err) {
      console.log(
        "Error on Web3Service.vendor_collection_getAllCollections_OC",
        err
      );
      return [];
    }
  }

  /** ===============================================================
   *                 VENDOR Withdraw METHODS
   * ================================================================ */

  async vendor_withdraw_withdraw(amount: string) {
    const addr = this.accounts[0];
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "withdraw",
      params: [amount, addr],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  async vendor_withdraw_withdrawToken(erc20: string, amount: string) {
    const addr = this.accounts[0];
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "withdrawToken",
      params: [erc20, amount, addr],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  /** ===============================================================
   *                 VENDOR Utils METHODS
   * ================================================================ */

  async vendor_utils_getPGFee() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "getPGFee",
      callType: "call",
      urlABI: this.vendorABI,
    });
  }

  async vendor_utils_setPGFee(pg: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "setPGFee",
      callType: "send",
      params: [pg],
      urlABI: this.vendorABI,
    });
  }

  async vendor_utils_getVaultAddress() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "getVaultAddress",
      callType: "call",
      urlABI: this.vendorABI,
    });
  }

  async vendor_utils_setVaultAddress(addr: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "setVaultAddress",
      callType: "send",
      params: [addr],
      urlABI: this.vendorABI,
    });
  }

  async vendor_utils_getPGDirectBonus() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "REFERRAL_PERCENTAGE_AMOUNT",
      callType: "call",
      urlABI: this.vendorABI,
    });
  }

  async vendor_utils_setPGDirectBonus(pg: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "set_referral_percentage_amount",
      callType: "send",
      params: [pg],
      urlABI: this.vendorABI,
    });
  }

  /** ===============================================================
   *                 VENDOR Referral METHODS
   * ================================================================ */

  async vendor_referral_store(addr: string, code: string, status: boolean) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "store",
      callType: "send",
      params: [addr, code, status],
      urlABI: this.vendorABI,
    });
  }

  async vendor_referral_update(id: number, status: boolean) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "update",
      callType: "send",
      params: [id, status],
      urlABI: this.vendorABI,
    });
  }

  async vendor_referral_directBonus() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "REFERRAL_PERCENTAGE_AMOUNT",
      callType: "call",
      urlABI: this.vendorABI,
    });
  }

  async vendor_referral_updateDirectBonusPercentage(percentage: any) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.vendorAddress,
      method: "set_referral_percentage_amount",
      callType: "send",
      params: [percentage],
      urlABI: this.vendorABI,
    });
  }

  async vendor_referral_codeExist_OC(addr: string): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.vendorAddress,
      method: "codeExist",
      callType: "call",
      params: [addr],
      urlABI: this.vendorABI,
    });
  }

  async vendor_referral_addressExist_OC(addr: string): Promise<boolean> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.vendorAddress,
      method: "addressExist",
      callType: "call",
      params: [addr],
      urlABI: this.vendorABI,
    });
  }

  async vendor_referral_referralList_OC(index: string | number): Promise<any> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.vendorAddress,
      method: "referralList",
      callType: "call",
      params: [index],
      urlABI: this.vendorABI,
    });
  }

  async vendor_referral_referralCodeList_OC(
    code: string | number
  ): Promise<any> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.vendorAddress,
      method: "referralCodeList",
      callType: "call",
      params: [code],
      urlABI: this.vendorABI,
    });
  }

  async vendor_referral_referralAddressList_OC(
    addr: string | number
  ): Promise<any> {
    return await this.callDinamyContractOffLine({
      contractAddress: environment.vendorAddress,
      method: "referralAddressList",
      callType: "call",
      params: [addr],
      urlABI: this.vendorABI,
    });
  }

  async vendor_referral_getInfoByCode_OC(code: string): Promise<any> {
    let snapshot = {
      addr: null,
      code: code,
      index: null,
      status: null,
      exist: false,
      createdAt: null,
      updatedAt: null,
    };
    const find = await this.vendor_referral_referralCodeList_OC(code);
    snapshot.exist = find.exist;

    if (!find.exist) return snapshot;

    const info = await this.vendor_referral_referralList_OC(find.index);

    snapshot = { ...snapshot, ...info };
    return snapshot;
  }

  /** ===============================================================
   *                   DISTRIBUTION Withdraw METHODS
   * ================================================================ */

  async distribution_withdraw_withdraw(amount: string) {
    const addr = this.accounts[0];
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "withdraw",
      params: [amount, addr],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  async distribution_withdraw_withdrawToken(erc20: string, amount: string) {
    const addr = this.accounts[0];
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "withdrawToken",
      params: [erc20, amount, addr],
      callType: "send",
      urlABI: this.vendorABI,
    });
  }

  /** ===============================================================
   *                 DISTRIBUTION Administered METHODS
   * ================================================================ */

  async distribution_administered_addAdmin(wallet: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "addAdmin",
      params: [wallet],
      callType: "send",
      urlABI: this.distributionABI,
    });
  }

  async distribution_administered_addUser(wallet: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "addUser",
      params: [wallet],
      callType: "send",
      urlABI: this.distributionABI,
    });
  }

  async distribution_administered_renounceAdmin() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "renounceAdmin",
      callType: "send",
      urlABI: this.distributionABI,
    });
  }

  async distribution_administered_removeUser(wallet: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "removeUser",
      params: [wallet],
      callType: "send",
      urlABI: this.distributionABI,
    });
  }

  async distribution_administered_isAdmin(wallet: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "isAdmin",
      params: [wallet],
      callType: "call",
      urlABI: this.distributionABI,
    });
  }

  async distribution_administered_isUser(wallet: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "isUser",
      params: [wallet],
      callType: "call",
      urlABI: this.distributionABI,
    });
  }

  /** ===============================================================
   *                 DISTRIBUTION Chainalisys METHODS
   * ================================================================ */

  async distribution_chainalisys_SANCTIONS_CONTRACT() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "SANCTIONS_CONTRACT",
      callType: "call",
      urlABI: this.distributionABI,
    });
  }

  async distribution_chainalisys_setAddressSanctions(addr: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "setAddressSanctions",
      params: [addr],
      callType: "send",
      urlABI: this.distributionABI,
    });
  }

  async distribution_chainalisys_stateSanctions() {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "stateSanctions",
      callType: "call",
      urlABI: this.distributionABI,
    });
  }

  async distribution_chainalisys_setStateSanctions(status: boolean) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "setStateSanctions",
      params: [status],
      callType: "send",
      urlABI: this.distributionABI,
    });
  }

  /** ===============================================================
   *                 DISTRIBUTION Blacklist METHODS
   * ================================================================ */

  async distribution_blacklist_isBlackList(addr: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "isBlackList",
      params: [addr],
      callType: "call",
      urlABI: this.distributionABI,
    });
  }

  async distribution_blacklist_setAddressBlackList(
    addr: string,
    status: boolean
  ) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "setAddressBlackList",
      params: [addr, status],
      callType: "send",
      urlABI: this.distributionABI,
    });
  }

  /** ===============================================================
   *                 DISTRIBUTION Claim METHODS
   * ================================================================ */

  async distribution_claim_runDistributionPaginated(
    from: string,
    to: string,
    nftAddr: string,
    withPercentage: boolean,
    fullDeposit: string,
    amount: string,
    tokenToPay: string
  ) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "runDistributionPaginated",
      params: [
        from,
        to,
        nftAddr,
        withPercentage,
        fullDeposit,
        amount,
        tokenToPay,
      ],
      callType: "send",
      urlABI: this.distributionABI,
    });
  }

  /** ===============================================================
   *                 DISTRIBUTION Collection METHODS
   * ================================================================ */

  async distribution_collection_getCollectionInfo(addr: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "_collection",
      params: [addr],
      callType: "call",
      urlABI: this.distributionABI,
    });
  }

  async distribution_collection_getCollectionClaimRecord(
    addr: string,
    id: string | number
  ) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "_collectionClaimRecords",
      params: [addr, id],
      callType: "call",
      urlABI: this.distributionABI,
    });
  }

  async distribution_collection_getCollectionClaimRecordPaginated(
    addr: string,
    from: number,
    to: number
  ) {
    try {
      const pagination: any = { from: 0, to: 0 };

      if (from) {
        pagination.from = from;
      }
      if (to) {
        pagination.to = to;
      }

      const collectionInfo =
        await this.distribution_collection_getCollectionInfo(addr);

      if (!collectionInfo.exist) {
        return [];
      }

      /** Corregir si el valor "hasta" es mayor al de la colección */
      if (new BigNumber(to).isGreaterThan(Number(collectionInfo.count) - 1)) {
        pagination.to = Number(collectionInfo.count) - 1;
      }

      const recordsList = customArrayNumber(pagination.from, pagination.to);

      const snapshot = await Promise.all(
        recordsList.map(async (id: any) => {
          const record =
            await this.distribution_collection_getCollectionClaimRecord(
              addr,
              id
            );
          return { ...record, _id: id };
        })
      );

      // console.log('snapshot', snapshot);

      return snapshot.sort((a: any, b: any) => a._id - b._id);
    } catch (err) {
      console.log(
        "Error on Web3Service.distribution_collection_getCollectionClaimRecordPaginated",
        err
      );
      return [];
    }
  }

  /** ===============================================================
   *                 DISTRIBUTION UserPayment METHODS
   * ================================================================ */

  async distribution_userPayment_getUserPaymentInfo(addr: string) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "userPaymentInfo",
      params: [addr],
      callType: "call",
      urlABI: this.distributionABI,
    });
  }

  async distribution_userPayment_getUserClaimRecord(
    addr: string,
    id: string | number
  ) {
    return await this.calculateAndCallCustomABI({
      contractAddress: environment.distributionAddress,
      method: "userClaimRecords",
      params: [addr, id],
      callType: "call",
      urlABI: this.distributionABI,
    });
  }

  async distribution_userPayment_getUserClaimRecordPaginated(
    addr: string,
    from: number,
    to: number
  ) {
    try {
      const pagination: any = { from: 0, to: 0 };

      if (from) {
        pagination.from = from;
      }
      if (to) {
        pagination.to = to;
      }

      const collectionInfo =
        await this.distribution_userPayment_getUserPaymentInfo(addr);
      // console.log('collectionInfo', collectionInfo);

      if (!collectionInfo.exist) {
        return [];
      }

      /** Corregir si el valor "hasta" es mayor al de la colección */
      if (new BigNumber(to).isGreaterThan(Number(collectionInfo.claims) - 1)) {
        pagination.to = Number(collectionInfo.claims) - 1;
      }

      const recordsList = customArrayNumber(pagination.from, pagination.to);
      // console.log('recordsList', recordsList);

      const snapshot = await Promise.all(
        recordsList.map(async (id: any) => {
          const record = await this.distribution_userPayment_getUserClaimRecord(
            addr,
            id
          );
          return { ...record, _id: id };
        })
      );

      // console.log('snapshot', snapshot);

      return snapshot.sort((a: any, b: any) => a._id - b._id);
    } catch (err) {
      console.log(
        "Error on Web3Service.distribution_userPayment_getUserClaimRecordPaginated",
        err
      );
      return [];
    }
  }

  /** ====================================================================
   *  Méthodo genérico para llamadas al SC personalizado de manera OFFLINE
   ===================================================================== */
  async getHttpWeb3Provider() {
    const url = `${environment.chain.rpc}`;
    // console.log('url', url);
    const provider = new Web3.providers.HttpProvider(url);
    return provider;
  }

  async getContractInstance(params: any) {
    try {
      const { provider = await this.getHttpWeb3Provider(), contractAddress } =
        params;
      let { abi = null } = params;

      if (!abi) {
        const abiParsed: any = await this.abiService.getABIByUrl(
          this.marketplaceAbi
        );
        abi = Object.values(abiParsed);
      }

      const web3 = new Web3(provider);
      const instance = new web3.eth.Contract(abi, contractAddress, {});
      return instance;
    } catch (err) {
      console.log("Error on Web3Service@getContractInstance", err);
      throw err;
    }
  }

  async callDinamyContractOffLine(data: any) {
    try {
      const {
        contractAddress,
        method,
        params = null,
        urlABI = this.marketplaceAbi,
      } = data;

      // console.log('callDinamyContractOffLine', method);

      /** Construir Provider HTTP */
      const provider = await this.getHttpWeb3Provider();

      // Cargar ABI del contrato
      const contractABI: any = await this.abiService.getABIByUrl(urlABI);
      // console.log('contractABI', contractABI);

      // cargamos la abi de contracto secundarios con el metodo que necesitamos
      const uToken = await this.getContractInstance({
        provider,
        contractAddress: contractAddress,
        abi: Object.values(contractABI),
      });

      const contractMethod = !params
        ? uToken.methods[method]()
        : uToken.methods[method](...params);

      const result = await contractMethod["call"]();
      return result;
    } catch (err) {
      console.log("params", data);
      console.log("Error on Web3Service@callDinamyContractOffLine", err);
      throw err;
    }
  }

  /** ===============================================================
   *       Méthodo genérico para llamadas al SC personalizado
   * ================================================================
   * @param data
   * @param data.contractAddress
   * @param data.method
   * @param data.params
   * @param data.callType           'call' / 'send'
   * @param data.optionals
   * @param data.urlABI
   */
  async calculateAndCallCustomABI(data: any) {
    const {
      contractAddress,
      method,
      params = null,
      callType = "send",
      optionals = {},
      urlABI = this.erc20ABI,
    } = data;

    try {
      // Cargar ABI del contrato
      const contractABI: any = await this.abiService.getABIByUrl(urlABI);

      // cargamos la abi de contracto secundarios con el metodo que necesitamos
      const uToken = this.getAbiContract(
        [contractABI[method]],
        contractAddress
      );

      const contractMethod = !params
        ? uToken.methods[method]()
        : uToken.methods[method](...params);

      if (callType === "send") {
        const [account] = this.accounts;
        optionals.from = account;

        const gasFee = await contractMethod.estimateGas(optionals);
        // console.log("gasFee", gasFee);

        // const gasPriceData: any = await this.bscGasSrv.getGasPrice();
        const gasPriceData: any =
          await this.polygonGasSrv.getCurrentGasPrices();
        // console.log("gasPriceData", gasPriceData);

        // optionals.gasPrice = toGwei(gasPriceData.result.FastGasPrice); //
        // optionals.gasPrice = gasPriceData.FastGasPrice; //
        optionals.gasPrice = gasPriceData.ProposeGasPrice; //
        optionals.gas = gasFee;
      }

      const result = await contractMethod[callType](optionals);

      return result;
    } catch (err: any) {
      console.log({ params: data });
      console.log("Error on ContractService@calculateAndCallCustomABI", err);
      throw new Error(err);
    }
  }

  /**
   * Obteber nueva instancia WEB3 de un SC a través del ABI ingresado
   * @param token_abi             ABI Cargado
   * @param token_address         Dirección del SC
   * @returns
   */
  getAbiContract(token_abi: any, token_address: any) {
    let uToken: any = new this.web3js.eth.Contract(token_abi, token_address);
    return uToken;
  }
}
