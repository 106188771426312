import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Web3Service } from 'src/app/services/contract/web3.service';
import { fromWei } from '../helpers/utils'
@Pipe({
  name: 'balance'
})
export class BalancePipe implements PipeTransform {

  constructor(
    private contractSrv: Web3Service,
  ) { }


  /**
   * 
   * @param address 
   * @param account 
   * @returns 
   */
  async transform(address: string, account: string): Promise<any> {

    let result;
    if (environment.addressdead == address) {
      result = await this.contractSrv.balanceOfNative(account);
    } else {
      result = await this.contractSrv.erc20_balanceOf(address, account);
    }

    console.log
    const _fromWei = fromWei(result, 18);

    return Number(_fromWei).toFixed(4);
  }

}
