import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import BigNumber from "bignumber.js";
import { NgxSpinnerService } from "ngx-spinner";
import {
  Observable,
  Subscription,
  catchError,
  combineLatest,
  distinctUntilChanged,
  from,
  map,
  of,
  tap,
} from "rxjs";
import { fromWei } from "src/app/helpers/utils";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CollectionService } from "src/app/services/firebase/collection.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";
import { UsersCartService } from "src/app/services/usersCart.service";
import { ModalSelectPaymentMethodFormComponent } from "../modal-select-payment-method-form/modal-select-payment-method-form.component";
import { Web3Service } from "src/app/services/contract/web3.service";
import { Sweetalert2stepsService } from "src/app/services/sweetalert2steps.service";
import { environment } from "src/environments/environment";
import { PurchaseService } from "src/app/services/firebase/purchase.service";
import * as moment from "moment";
import { ModalToppayFormComponent } from "../modal-toppay-form/modal-toppay-form.component";
import { OracleService } from "src/app/services/oracle.service";
import {
  CreateTopPayOrder,
  TopPayService,
} from "src/app/services/api/topPay.service";
import { PurchaseApiService } from "src/app/services/api/purchase-api.service";
import { StripeService } from "src/app/services/api/stripe.service";

const DEAFULT_PORFOLIO_VALUE = { uid: null, records: 0, vp: 0, ra: 0 };

@Component({
  selector: "app-user-my-investment-wishlist",
  templateUrl: "./user-my-investment-wishlist.component.html",
  styleUrls: ["./user-my-investment-wishlist.component.css"],
})
export class UserMyInvestmentWishlistComponent implements OnInit, OnDestroy {
  @ViewChild("modalSelectPaymentMethod")
  modalPaymentMethod!: ModalSelectPaymentMethodFormComponent;

  @ViewChild("modalBuyTopPay") modalBuyTopPay!: ModalToppayFormComponent;

  @Output() onEditEvent = new EventEmitter();

  public wishtList!: any[];

  public wishListValue$: Observable<any> = of({ vp: 0, ra: 0 });

  public user: any;

  public itemToBuy: any;

  public userPortfolio = DEAFULT_PORFOLIO_VALUE;

  private sub$!: Subscription;

  constructor(
    private authSrv: AuthenticationService,
    private collectionSrv: CollectionService,
    private usersCartSrv: UsersCartService,
    private sweetAlert2Srv: Sweetalert2Service,
    private spinner: NgxSpinnerService,
    private router: Router,
    private web3Srv: Web3Service,
    private sweetAlert2StepSrv: Sweetalert2stepsService,
    private purchaseSrv: PurchaseService,
    private oracleSrv: OracleService,
    private topPaySrv: TopPayService,
    private purchaseApiSrv: PurchaseApiService,
    private stripeSrv: StripeService
  ) {}

  ngOnInit(): void {
    this.sub$ = this.authSrv.userDoc$
      .pipe(distinctUntilChanged())
      .subscribe((user: any) => {
        // console.log('user', user);
        /** Capturar usuario */
        this.user = user;

        /** Cargar lista de deseos */
        this.loadInfo();
      });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  get wishPortfolioValue() {
    if (!this.wishtList || this.wishtList.length === 0) return { vp: 0, ra: 0 };

    const { vp, ra } = this.wishtList.reduce(
      (acc, item) => {
        const { totales, gananciaAnual } = item;

        acc.vp += totales;
        acc.ra += gananciaAnual;

        return acc;
      },
      { vp: 0, ra: 0 }
    );

    return { vp, ra };
  }

  get userPortfolioValue() {
    if (!this.userPortfolio) return { vp: 0, ra: 0 };
    const { vp, ra } = this.userPortfolio;
    return { vp, ra };
  }

  /**
   * @dev Cargar lista de deseos
   */
  loadInfo() {
    this.sub$.add(
      combineLatest([
        /** Cargar listado de wishlist del usuario */
        this.usersCartSrv
          .getDynamic([
            { field: "userId", condition: "==", value: this.user._id },
          ])
          .pipe(catchError((err) => of([]))),
        // this.collectionSrv.getDynamic([ { field: "status", condition: "in", value: ["preview", "opening"]} ])

        /** Cargar listado de colecciones registradas */
        this.collectionSrv.getDynamic([]).pipe(catchError((err) => of([]))),

        /** Cargar información del portafolio actual */
        from(
          this.purchaseApiSrv.user_getPorfolioValue({ uid: this.user._id })
        ).pipe(catchError((err) => of(DEAFULT_PORFOLIO_VALUE))),
      ])
        .pipe(
          map(([wishList, collections, userPortfolio]) => {
            const wishListParsed =
              wishList.length > 0
                ? wishList
                    .map((item: any) => {
                      const find = collections.find(
                        (collection) => collection._id === item.collectionId
                      );
                      item.propertie = find ? find : { status: "closed" };
                      return item;
                    })
                    .filter((item: any) =>
                      ["preview", "opening"].includes(item.propertie.status)
                    )
                    .map((item: any) => {
                      const { propertie, nroTokens } = item;
                      const { price: priceWei, estimatedProfitability } =
                        propertie;

                      const profit = estimatedProfitability;
                      const price = fromWei(priceWei);
                      const totales = new BigNumber(nroTokens)
                        .multipliedBy(price)
                        .toNumber();
                      const gananciaAnual = new BigNumber(totales)
                        .multipliedBy(profit)
                        .dividedBy(100)
                        .plus(totales)
                        .toNumber();
                      const gananciaMensual = new BigNumber(gananciaAnual)
                        .dividedBy(12)
                        .plus(totales)
                        .toNumber();
                      const gananciaAnualFive = new BigNumber(gananciaAnual)
                        .multipliedBy(5)
                        .plus(totales)
                        .toNumber();

                      return {
                        ...item,
                        totales,
                        gananciaAnual,
                        gananciaMensual,
                        gananciaAnualFive,
                      };
                    })
                : [];

            return {
              wishList: wishListParsed,
              collections,
              userPortfolio,
            };
          })
          // tap((data: any) => console.log('data', data))
        )
        .subscribe((data) => {
          /** Cargar listado de wishlist */
          this.wishtList = data.wishList;

          /** Cargar valor del portafolio actual del usuario */
          this.userPortfolio = data.userPortfolio;
        })
    );
  }

  launchSelectPaymentMethod(item: any) {
    // console.log('buySelect', item);
    this.itemToBuy = item;
    this.modalPaymentMethod.openModal();
  }

  onClosedModalSelectPaymentMethod({ data, role }: { data: any; role: any }) {
    // console.log('onClosedModalSelectPaymentMethod', data, role);
    if (role == "cancel") {
      this.itemToBuy = null;
      return;
    }

    if (data.whiteListToken === "stripe") {
      this.buyStripe(data);
    } else {
      this.buyCrypto(data);
    }
    return;
  }

  async buyTopPay(data: any) {
    console.log("buyTopPay", data);
    this.modalBuyTopPay.openModal();
    return;
  }

  async onCompletedTopPayModal(topPayForm: any) {
    try {
      const ask = await this.sweetAlert2Srv.askConfirm(
        "property.proceedWithThePurchase?"
      );
      if (!ask) {
        return;
      }

      await this.spinner.show();

      // console.log('topPayForm', topPayForm);

      /** Calcular Amount to Pay - Monto a Pagar */
      const atp = await this.oracleSrv.usdToCop(this.itemToBuy.totales);
      // console.log('atp', atp);

      /** Capturar hora de creación */
      const createdAt = moment().valueOf();

      /** Construir datos de la compra para la base de datos */
      const buyDocument: CreateTopPayOrder = {
        userId: this.user._id,
        numdoc: topPayForm.numdoc,
        username: topPayForm.username,
        userphone: topPayForm.userphone,
        useremail: topPayForm.useremail,
        userWallet: this.user.walletAddress,
        amount: atp.results.cop,
        // propertie: this.propertie, // TODO: revisar por que guardar documento de la propiedad
        propertieId: this.itemToBuy.propertie._id,
        propertiesTokensNumberBuy: Number(this.itemToBuy.nroTokens),
        propertiesPriceUSD: Number(this.itemToBuy.totales),
        propertiesPriceToPay: atp.results.cop,
        currency: "COP",
        expiration: "2025-12-31",
        typetransaction: "1",
        txHash: null,
        method: "TUP_GEN",
        methodBuy: "toppay",
        returnUrl: `${environment.urlWeb}`,
        createdAt: createdAt,
        statusBuy: "incompleted",
      };
      // console.log('buyDocument', buyDocument);

      /** Almacenar registro de compra */
      const topPayBuy: any = await this.topPaySrv.createOrder(buyDocument);
      // console.log('topPayBuy', topPayBuy);

      /** Eliminar elemento de la lista de deseos */
      await this.usersCartSrv.remove(this.itemToBuy._id);

      this.sweetAlert2Srv
        .showSuccess("property.redirectedToThePaymentGateway")
        .then(() => {
          window.location.href = topPayBuy.checkout;
        });
      return;
    } catch (err) {
      console.log("Error on onCompletedTopPayModal", err);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  async buyCrypto(data: any) {
    // console.log({ data: data, itemToBuy: this.itemToBuy });
    try {
      /** Válidar wallet conectada con wallet registrada */
      if (this.user.walletAddress !== data.metadata.walletBuy) {
        this.sweetAlert2Srv.showWarning(
          "errors.userWalletConnectingIsNoRegister"
        );
        return;
      }

      /** Calcular Amount to Pay - Monto a Pagar */
      const atp = await this.web3Srv.vendor_oracle_parseUSDtoToken_OFFCHAIN(
        new BigNumber(this.itemToBuy.propertie.price)
          .multipliedBy(this.itemToBuy.nroTokens)
          .toFixed(),
        data.whiteListToken
      );
      // console.log('atp', atp);

      /** Construir datos para la compra */
      const _data = {
        cIdx: this.itemToBuy.propertie.collectionId,
        token: data.whiteListToken,
        code: this.user.referralCode,
        amount: Number(this.itemToBuy.nroTokens),
      };
      // console.log('_data', _data);

      const method = data.metadata.isNative
        ? "vendor_buy_buyNative"
        : "vendor_buy_buyWithToken";
      // console.log("Method", method);

      const params = data.metadata.isNative
        ? [_data.cIdx, _data.token, _data.amount, _data.code, atp]
        : [_data.cIdx, _data.token, _data.amount, _data.code];
      // console.log("params", params);

      const message = "Comprar";
      const result = data.metadata.isNative
        ? await this.sweetAlert2StepSrv.showStepsNative({
            actionMessage: `${message} ${_data.amount} NFTs?`,
            checkBalanceParams: { amount: atp },
            contractParams: { method: method, params: params },
          })
        : await this.sweetAlert2StepSrv.showStepsWithApproved({
            actionMessage: `${message} ${_data.amount} NFTs?`,
            checkBalanceParams: { contract: _data.token, amount: atp },
            approvedParams: [_data.token, environment.vendorAddress, atp],
            contractParams: { method: method, params: params },
          });

      if (!result.status) {
        this.sweetAlert2StepSrv.showBasicAlert(result.data.message, "error");
        return;
      }

      /** Capturar hora de creación */
      const createdAt = moment().valueOf();

      /** Construir datos de la compra para la base de datos */
      const buyDocument = {
        userId: this.user._id,
        username: this.user.name,
        useremail: this.user.email,
        userphone: this.user.phoneNumber,
        userWallet: this.user.walletAddress,
        userCodeRefered: this.user.referralCode,
        // propertie: this.itemToBuy.propertie, // TODO: revisar por que guardar documento de la propiedad
        propertieId: this.itemToBuy.propertie._id,
        propertiesTokensNumberBuy: Number(this.itemToBuy.nroTokens),
        propertiesPriceUSD: Number(this.itemToBuy.totales),
        propertiesPriceToPay: atp,
        txHash: result.data.transactionHash,
        methodBuy: "crypto",
        createdAt: createdAt,
        statusBuy: "success",
        token: {
          addr: data.whiteListToken,
          isNative: data.metadata.isNative,
        },
      };

      /** Guardar documento de compra en la base de datos */
      const purchaseId = await this.purchaseSrv.store(buyDocument);
      console.log("purchaseId", purchaseId);

      /** Eliminar elemento de la lista de deseos */
      await this.usersCartSrv.remove(this.itemToBuy._id);

      this.sweetAlert2StepSrv
        .showAlertWithTxHash({ transactionHash: result.data.transactionHash })
        .then(() => {
          this.web3Srv.logout(true);
        });
      return;
    } catch (err) {
      console.log("Error on UserMyInvestmentWishlistComponent", err);
      return;
    }
  }

  /**
   * @dev Editar elemento de la lista de deseos
   * @param item
   */
  edit(item: any) {
    this.onEditEvent.emit(item);
  }

  /**
   * @dev Eliminar elemento de la lista de deseos
   * @param item      Documento a eliminar
   * @returns
   */
  async clear(item: any) {
    // console.log('clear', item);
    try {
      const ask = await this.sweetAlert2Srv.askConfirm(
        "projectYourInvestment.areYouSureToRemoveThisElement?"
      );
      if (!ask) {
        return;
      }

      await this.spinner.show();

      await this.usersCartSrv.remove(item._id);

      this.sweetAlert2Srv.showToast(
        "projectYourInvestment.theItemWasCorrectlyDeleted",
        "success"
      );
      return;
    } catch (err) {
      console.log("Error on UserMyInvestmentWishlistComponent", err);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  launchCompleteIncompletedAlert() {
    this.sweetAlert2Srv
      .showWarning("property.propertyBuyIncompletedProfile")
      .then(() => this.router.navigate(["/dashboard-user/profile"]));
  }

  /**
   * Al enviar formulario de striper
   * @returns
   */

  async buyStripe(formData: any) {
    try {
      /** Calcular Amount to Pay - Monto a Pagar */
      console.log(formData.amountStripe);
      console.log(formData.amount);

      await this.spinner.show();

      const atp = await this.oracleSrv.usdToCop(this.itemToBuy.totales);

      const avaiableSupply = await this.web3Srv.erc721_availableSupply_OFFCHAIN(
        this.itemToBuy.propertie.addr
      );
      console.log("avaiableSupply", avaiableSupply);

      if (avaiableSupply < this.itemToBuy.nroTokens) {
        this.sweetAlert2Srv.showWarning(
          "property.theNumberOfTokensToBePurchasedExceeds"
        );
        return;
      }

      /** Capturar hora de creación */
      const createdAt = moment().valueOf();

      /** Construir datos de la compra para la base de datos */
      const buyDocument = {
        userId: this.user._id,
        username: this.user.name,
        useremail: this.user.email,
        userphone: this.user.phoneNumber,
        userWallet: this.user.walletAddress,
        userCodeRefered: this.user.referralCode,
        propertieId: this.itemToBuy.propertie._id,
        propertiesTokensNumberBuy: Number(this.itemToBuy.nroTokens),
        propertiesPriceUSD: Number(this.itemToBuy.totales),
        propertiesPriceToPay: atp.results.cop,
        currency: "cop",
        methodBuy: "striper",
        createdAt: createdAt,
        statusBuy: "pending",
        success_url: `${environment.urlWeb}`,
        cancel_url: `${environment.urlWeb}`,
      };
      console.log("buyDocument", buyDocument);

      let response = await this.stripeSrv.createOrder(buyDocument);

      // console.log("response", response);
      this.sweetAlert2Srv.showSuccess("property.buySuccess").then(() => {
        // window.location.href = response.urlCheckout;
        window.open(response.urlCheckout, "_blank");
      });
      return;
    } catch (err) {
      console.log("Error on buyStriper", err);
      return;
    } finally {
      this.spinner.hide();
    }
  }
}
