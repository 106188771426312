import { Component, OnInit } from "@angular/core";
import { Web3Service } from "src/app/services/contract/web3.service";
import { catchError, map, Observable, of, switchMap } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-header-admin",
  templateUrl: "./header-admin.component.html",
  styleUrls: ["./header-admin.component.css"],
})
export class HeaderAdminComponent implements OnInit {
  public dataStatus$!: Observable<any>;
  public currentLanguage: string = "";
  public selectLanguageCache!: any;

  constructor(
    private web3Srv: Web3Service,
    private ngxSpinnerService: NgxSpinnerService,
    public translateSrv: TranslateService
  ) {
    this.selectLanguageCache = localStorage.getItem("language");
    if (this.selectLanguageCache) {
      this.translateSrv.use(this.selectLanguageCache);
    }
    this.currentLanguage = this.translateSrv.currentLang;
  }

  ngOnInit() {
    this.dataStatus$ = this.web3Srv.accountStatus$.pipe(
      map((data: any[]) => (data.length > 0 ? data[0] : null)),
      switchMap(async (addr: any) => {
        if (!addr)
          return {
            addr: null,
            access: false,
          };
        // const [vendorRoles] = await Promise.all([
        //   this.web3Srv.vendor_administered_getRoles(addr),
        // ]);
        return {
          addr,
          access: true,
          // access: Object.values(vendorRoles).some((v) => v), // [true, false] => [true, true] => true
          // ...vendorRoles,
        };
      }),
      catchError((err) => {
        return of({
          addr: null,
          access: false,
        });
      })
    );
  }

  async launch() {
    return this.web3Srv.launchAskConnectionType();
  }

  async logOut() {
    await this.ngxSpinnerService.show();
    this.web3Srv.logout(true);
    return window.location.reload();
  }

  changeLanguage(lang: string) {
    localStorage.setItem("language", lang);
    console.log(lang);
    this.translateSrv.use(lang);
    this.currentLanguage = lang;
    let vsubMenu2 = document.getElementById("languaje");
    vsubMenu2?.classList.toggle("menu-languaje-active");
    let vsubMenu3 = document.getElementById("languaje-mobile");
    vsubMenu3?.classList.toggle("menu-languaje-active");
  }
  menuLanguaje() {
    let vsubMenu2 = document.getElementById("languaje");
    console.log(vsubMenu2);
    vsubMenu2?.classList.toggle("menu-languaje-active");
    let vsubMenu3 = document.getElementById("languaje-mobile");
    vsubMenu3?.classList.toggle("menu-languaje-active");
  }
}
