import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import { Observable, interval, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalCartService {

  public cart$: Observable<any>;

  constructor(
    private afs: AngularFirestore,
  ) {

    this.cart$ = interval(1000)
    .pipe(
      map((interval: number) => {
        let newCart = this.buildCardDoc();

        const find = window.localStorage.getItem('cart');
        if(!find){ return newCart; }

        const cart = JSON.parse(find);
        return cart;
      })
    );
  }

  /**
   * Generar un id para un documento
   * @returns 
   */
  generateId(){ return this.afs.createId(); }

  /**
   * Crear estructura de documento para carrito
   * @param params 
   * @returns 
   */
  buildCardDoc(params: any = {}){
    return {
      cartId: params.cartId || this.generateId(),
      uid: params.uid || null,
      createdAt: params.createdAt || moment().valueOf(),
      product: params.product || [],
      // status: 'draw' // draw, pending, completed, rejected
    };
  }

  /**
   * Crear un nuevo carrito de compras para un evento
   * @param data              Datos del carrito
   * @returns 
   */
    
  store(data: any){
    window.localStorage.setItem('cart', JSON.stringify(data));
  }
  
  buildAndStore(){
    const data = this.buildCardDoc();
    const find = window.localStorage.getItem('cart');
    if(!find){ this.store(data); }
    return data;
  }

  async addOnCart( data: any[], field: string = 'product'){
    const find = window.localStorage.getItem('cart');
    if(!find){ return; }

    const cart = JSON.parse(find);
    const fieldValue = cart[field] || [];

    const newData = [...fieldValue, ...data];

    cart[field] = newData;

    this.store(cart);

    return cart;
  }

  async removeOnCart(index: number, field: string = 'product'){

    const find = window.localStorage.getItem('cart');
    if(!find){ return; }

    const cart = JSON.parse(find);
    const fieldValue = cart[field] || [];

    fieldValue.splice(index, 1);

    cart[field] = fieldValue;

    this.store(cart);

    return cart;
  }

}
