<div class="row mt-4">
  <div class="bg-brown">
    <div class="row mt-4">
      <div class="col-12">
        <div class="card-verefication-user-body content-table">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">
                    <span
                      [innerHTML]="'general.projectName' | translate"></span>
                  </th>
                  <th scope="col">
                    <span
                      [innerHTML]="'general.numberOfTokens' | translate"></span>
                  </th>
                  <th scope="col">
                    <span [innerHTML]="'general.investment' | translate"></span>
                  </th>
                  <th scope="col">
                    <span [innerHTML]="'general.percentage' | translate"></span>
                  </th>
                  <th scope="col">
                    <span
                      [innerHTML]="
                        'general.approximateEarnings' | translate
                      "></span>
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngIf="wishtList as list; else renderLoader"
                  [ngTemplateOutlet]="renderCheck"
                  [ngTemplateOutletContext]="{ list: list }"></ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- <div class="col-12 pt-3">
                <div class="d-flex justify-content-end align-items-center me-3 me-lg-5 pe-lg-4">
                    <p class="text-yellow f-20 me-1">
                        Total de ganancias del nuevo portafolio:
                    </p>
                    <p class="text-white f-20">
                        {{wishPortfolioValue.ra | number: '1.2-2' | currency}}
                    </p>
                </div>
            </div> -->

      <div class="col-12">
        <div
          class="d-flex justify-content-end align-items-center me-3 me-lg-5 pe-lg-4">
          <p class="text-yellow f-20 me-1">
            {{
              "projectYourInvestment.totalEarningsOfTheNewPortfolio"
                | translate
            }}:
          </p>
          <p class="text-white f-20">
            {{
              userPortfolio.ra + wishPortfolioValue.ra
                | number : "1.2-2"
                | currency
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #renderList let-list="list">
  <tr *ngFor="let item of list">
    <th scope="row">
      <img
        *ngIf="!item.propertie"
        src="assets/img/property-img-slider.png"
        class="img-table"
        style="max-width: 1.5rem"
        alt="" />
      <img
        *ngIf="item.propertie"
        src="{{ item.propertie.gallery[0].url }}"
        class="img-table"
        style="max-width: 1.5rem"
        alt="" />
      {{ item.propertie.projectName }}
    </th>
    <td>{{ item.nroTokens }}</td>
    <td>{{ item.totales | currency }} USD</td>
    <td>{{ item.propertie.estimatedProfitability }}%</td>
    <td>{{ item.gananciaAnual | currency }} USD</td>
    <td>
      <!-- <div class="table-buttons">
                <button class="btn btn-table" title="Comprar propiedad" (click)="buySelect(item)">
                    <img src="assets/img/buy-icon.png" alt="" />
                </button>
                <button class="btn btn-table mx-1" title="Editar propiedad" (click)="edit(item)">
                    <img src="assets/img/edit-icon.png" alt="" />
                </button>
                <button class="btn btn-table" title="Eliminar propiedad"(click)="clear(item)">
                    <img src="assets/img/delete-icon.png" alt="" />
                </button>
            </div> -->

      <div
        class="btn-group btn-group-sm"
        role="group"
        aria-label="Basic example">
        <ng-container
          *ngIf="user && user.exist"
          [ngTemplateOutlet]="renderCheckUser"
          [ngTemplateOutletContext]="{ item: item }"></ng-container>

        <button
          type="button"
          class="btn btn-table"
          title="Editar propiedad"
          (click)="edit(item)">
          <img src="assets/img/edit-icon.png" alt="" style="max-width: 15px" />
        </button>
        <button
          type="button"
          class="btn btn-table"
          title="Eliminar propiedad"
          (click)="clear(item)">
          <img
            src="assets/img/delete-icon.png"
            alt=""
            style="max-width: 15px" />
        </button>
      </div>
    </td>
  </tr>
</ng-template>

<!-- Botón para inicializar compra -->
<ng-template #renderBuyButton let-item="item">
  <button
    type="button"
    class="btn btn-table"
    title="Comprar propiedad"
    (click)="launchSelectPaymentMethod(item)">
    <img src="assets/img/buy-icon.png" alt="" style="max-width: 15px" />
  </button>
</ng-template>

<!-- Botón de usuario con perfil incompleto -->
<ng-template #renderIncompleteProfileButton>
  <button
    type="button"
    class="btn btn-table"
    title="Comprar propiedad"
    (click)="launchCompleteIncompletedAlert()">
    <img src="assets/img/buy-icon.png" alt="" style="max-width: 15px" />
  </button>
</ng-template>

<!-- Verificar estado de perfil de usuario -->
<ng-template #renderCheckUser let-item="item">
  <!-- Usuario registrado y con perfil completado -->
  <ng-container
    *ngIf="
      user.walletAddress && user.kyc == true;
      else renderIncompleteProfileButton
    "
    [ngTemplateOutlet]="renderBuyButton"
    [ngTemplateOutletContext]="{ item: item }"></ng-container>
</ng-template>

<ng-template #renderNoRecords>
  <tr>
    <th scope="row" colspan="7">
      <h5
        class="text-center"
        [innerHTML]="'general.noRecords' | translate"></h5>
    </th>
  </tr>
</ng-template>

<ng-template #renderCheck let-list="list">
  <ng-container
    *ngIf="list.length > 0; else renderNoRecords"
    [ngTemplateOutlet]="renderList"
    [ngTemplateOutletContext]="{ list: list }"></ng-container>
</ng-template>

<ng-template #renderLoader>
  <tr *ngFor="let item of [1, 1, 1, 1]">
    <th scope="row" colspan="7" class="placeholder-glow">
      <span class="placeholder placeholder-lg col-12"></span>
    </th>
  </tr>
</ng-template>

<app-modal-select-payment-method-form
  #modalSelectPaymentMethod
  (onSubmitEvent)="
    onClosedModalSelectPaymentMethod($event)
  "></app-modal-select-payment-method-form>

<app-modal-toppay-form
  #modalBuyTopPay
  (onSubmitEvent)="onCompletedTopPayModal($event)"></app-modal-toppay-form>
