import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";

const URL_ROOT: any = environment.API_URL;

@Injectable({
  providedIn: "root",
})
export class OracleService {
  constructor(private http: HttpClient) {}

  /**
   * Enviar monto de USD para cambiar a COP
   *
   * @param amount  monto en USD
   * @returns
   */
  async usdToCop(amount: number) : Promise<HttpPostResult> {
    try {
      const result: any = await lastValueFrom(
        this.http.post(`${URL_ROOT}/oracle/usd-to-cop`, {
          amount,
        })
      );
      return result;
    } catch (err) {
      console.log("Error on OracleService.usdToCop", err);
      throw err;
    }
  }
}

export interface Oracle {
  oracle: string;
  currency: string;
  usd: number;
  usdWei: string;
  cop: string;
  copWei: string;
}

export interface HttpPostResult {
  message: string;
  error: boolean;
  code: number;
  results: any;
}