<div class="card-verefication-user mt-5">
  <div class="card-verefication-user-header">
    <p>
      <i class="bi bi-wallet2"></i> &nbsp;
      {{ "walletRegister.requestMyWalletInformation" | translate }}
    </p>
  </div>

  <div class="card-verefication-user-body pb-3">
    <p class="text-yellow text-small">
      <small
        [innerHTML]="'walletRegister.sendYourWalletInformation' | translate">
      </small>
    </p>

    <div class="d-flex justify-content-evenly">
      <ng-container
        *ngIf="
          !showSubmitLoaderButton;
          then renderSubmitButton;
          else renderSubmitLoaderButton
        "></ng-container>
    </div>
  </div>
</div>

<ng-template #renderSubmitButton>
  <button
    type="button"
    class="btn btn-main"
    (click)="sendInfo()"
    [innerHTML]="'general.request' | translate"></button>
</ng-template>

<ng-template #renderSubmitLoaderButton>
  <button type="button" class="btn btn-main" disabled>
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"></span>
    {{ "general.loading" | translate }}
  </button>
</ng-template>
