<div class="row mt-5">
  <div class="col mt-2">
    <div class="card-dashboard-user-information">
      <div class="row g-0 align-items-center">
        <div class="col-12 col-md-7">
          <div class="card-dashboard-user-information-avatar-name w-100">
            <div class="row g-0">
              <div class="col-12 col-md-5 position-relative avatarr">
                <div *ngIf="!profile?.avatar" class="user-avatar"></div>

                <div *ngIf="imgUpdate && !profile?.avatar" class="user-avatar">
                  <img [src]="imgUpdate" class="img-fluid" />
                </div>
                <div *ngIf="profile?.avatar && imgUpdate" class="user-avatar">
                  <img [src]="imgUpdate" class="img-fluid" />
                </div>

                <div *ngIf="profile?.avatar && !imgUpdate" class="user-avatar">
                  <img [src]="profile?.avatar" class="img-fluid" />
                </div>

                <ng-container *ngIf="!imgUpdate; else renderUpdateAvatar">
                  <button class="btn btn-avatar" (click)="onAddImage()">
                    <i class="bi bi-camera-fill"></i>
                  </button>
                </ng-container>
              </div>

              <div class="col-12 col-md-7">
                <div class="user-name">
                  <span
                    class="text-yellow"
                    [innerHTML]="'adminUser.hello' | translate"></span>
                  <p class="text-yellow">
                    {{ profile?.name }} {{ profile?.lastName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-8 col-md-4 b-l pm-2 ps-3">
          <div class="d-flex flex-column">
            <div class="text-yellow mb-3" style="font-size: 14px">
              <span class="text-white">
                {{ "general.referralLink" | translate }}:
              </span>
              &nbsp;
              <ng-container
                *ngIf="userDoc$ | async as userDoc; else renderLoaderSpinner">
                <ng-container
                  *ngIf="userDoc.exist; else renderNotFoundUser"
                  [ngTemplateOutlet]="renderReferralLink"
                  [ngTemplateOutletContext]="{ user: userDoc }"></ng-container>
              </ng-container>
            </div>

            <div class="text-yellow" style="font-size: 14px">
              <span class="text-white">Wallet:</span>
              &nbsp;
              <ng-container
                *ngIf="userDoc$ | async as userDoc; else renderLoaderSpinner">
                <ng-container
                  *ngIf="userDoc.exist; else renderNotFoundUser"
                  [ngTemplateOutlet]="renderWallet"
                  [ngTemplateOutletContext]="{ user: userDoc }"></ng-container>
              </ng-container>

              <!-- {{ profile?.walletAddress }} -->
            </div>
          </div>
        </div>

        <div class="col-4 col-md-1 b-l pe-1">
          <div class="d-flex w-100 flex-column align-items-center">
            <div class="btn-languaje mb-1" id="languaje-mobile">
              <button class="btn" type="button" (click)="menuLanguaje()">
                <img
                  *ngIf="currentLanguage === 'en'"
                  class="img-fluid"
                  src="assets/img/estados-unidos.png"
                  alt="bandera estado unidos" />
                <img
                  *ngIf="currentLanguage === 'es'"
                  class="img-fluid"
                  src="assets/img/espana.png"
                  alt="bandera espana" />
              </button>
              <ul class="menu-languaje">
                <li id="es" class="text-white" (click)="changeLanguage('es')">
                  <img
                    class="img-fluid"
                    src="assets/img/espana.png"
                    alt="bandera espana" />
                </li>
                <li id="pt" class="text-white" (click)="changeLanguage('en')">
                  <img
                    class="img-fluid"
                    src="assets/img/estados-unidos.png"
                    alt="bandera estado unidos" />
                </li>
              </ul>
            </div>

            <button
              class="btn"
              [routerLink]="'/dashboard-user/profile'"
              [routerLinkActive]="'active'">
              <img
                src="assets/img/settings-icon.png"
                class="img-fluid"
                alt="" />
            </button>

            <button class="btn" (click)="launchModalContactForm()">
              <img src="assets/img/email-icon.png" class="img-fluid" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #renderReferralLink let-user="user">
  <span
    class="badge bg-warning text-dark cursor-pointer"
    (click)="copyReferralLink(user)">
    <ng-container *ngIf="user.userCode">
      <i class="bi bi-link-45deg"></i>
      {{ user.userCode | uppercase }}
    </ng-container>

    <ng-container *ngIf="!user.userCode"> S/R </ng-container>
  </span>
</ng-template>

<ng-template #renderWallet let-user="user">
  <span class="badge bg-warning text-dark">
    <ng-container *ngIf="user.walletAddress">
      <i class="bi bi-wallet2"></i>
      {{ user.walletAddress | truncateWalletAddress }}
    </ng-container>

    <ng-container *ngIf="!user.walletAddress"> S/R </ng-container>
  </span>
</ng-template>

<ng-template #renderNotFoundUser>
  <span class="badge bg-warning text-dark">
    <i class="bi bi-link-45deg"></i>
    S/R
  </span>
</ng-template>

<ng-template #renderLoaderSpinner>
  <div class="spinner-border spinner-border-sm" role="status">
    <span
      class="visually-hidden"
      [innerHTML]="'general.loading' | translate"></span>
  </div>
</ng-template>

<ng-template #renderUpdateAvatar>
  <button class="btn btn-avatar-update" (click)="saveAvatar()">
    <i class="bi bi-camera-fill me-1"></i>

    {{ "general.update" | translate }}
  </button>
</ng-template>

<app-input-single-image
  (onpUpdateImages)="onSelectImages($event)"
  [multiple]="true"></app-input-single-image>

<app-modal-contact-form #modalContractForm></app-modal-contact-form>
