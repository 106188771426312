import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class KycApiService {

  public prefix = '/kyc';

  constructor(
    private apiSrv: ApiService
  ) { }

  /**
   * @description Llamada a la api de kyc para crear un nuevo link
   * @param uid               Identificador del usuario (dirección de wallet) 
   * @returns 
   */
  async createLink(uid: string): Promise<CreateKycLinkResponse>{
    try {
      return await this.apiSrv.post(`${this.prefix}/create-link`, {uid});
    } catch (err) {
      console.log('Error on KycApiService.createOrder', err);
      throw err;
    }
  }

}

/**
 * @description Interfaz de respuesta de la creación de un link de kyc
 * @param uid               Identificador del usuario (dirección de wallet)
 * @param url               URL del link de kyc
 * @param externalIdentifier Identificador externo del link de kyc
 * @param faceRecordId      Identificador del registro de la cara
 */
export interface CreateKycLinkResponse {
  uid: string;
  url: string;
  externalIdentifier: string;
  faceRecordId: string;
}