import { Component } from '@angular/core';

@Component({
  selector: 'app-not-access',
  templateUrl: './not-access.component.html',
  styleUrls: ['./not-access.component.css']
})
export class NotAccessComponent {

}
