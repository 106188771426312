import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { SwiperComponent } from "swiper/angular";
import { Observable, Subscription, catchError, map, of, tap } from "rxjs";

import SwiperCore, {
  Swiper,
  Virtual,
  SwiperOptions,
  Autoplay,
  Pagination,
  EffectCoverflow,
} from "swiper";
import { InvestalkService } from "src/app/services/firebase/investalk.service";
import { CollectionService } from "src/app/services/firebase/collection.service";

// install Swiper modules
SwiperCore.use([Virtual, Autoplay, Pagination, EffectCoverflow]);

@Component({
  selector: "app-slider-properties",
  templateUrl: "./slider-properties.component.html",
  styleUrls: ["./slider-properties.component.css"],
})
export class SliderPropertiesComponent implements OnInit {
  @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;
  config: SwiperOptions = {
    pagination: {
      el: ".swiper-pagination",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    // loop: true,
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 1,
    spaceBetween: 10,
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: false,
    },

    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      991: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      1199: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      1399: {
        slidesPerView: 1,
      },
    },
  };

  public propertiesList!: any[];
  public proyectsList$!: Observable<any[]>;
  private sub$!: Subscription;
  constructor(private collectionSrv: CollectionService) {}

  async ngOnInit() {
    //obtener Propiedades
    this.proyectsList$ = this.collectionSrv
      .getDynamic([
        { field: "status", condition: "in", value: ["opening", "preview"] },
        // { field: 'status', condition: 'in', value: ['preview', 'opening', 'closed', 'soldOut'] }
      ])
      .pipe(
        map((data: any[]) =>
          data.map((item: any) => {
            let gallery: any[] = Array.isArray(item.gallery)
              ? item.gallery
              : [];
            // find thumbnails if exists or set defautl value
            const thumbnails =
              gallery.find((item: any) => item.thumbnails)?.url ||
              this.collectionSrv.deafultThumbnails;
            /** Si no tiene archivos de */
            if (gallery.length == 0) {
              gallery = new Array(3).fill({
                type: "image/png",
                url: this.collectionSrv.defaultImage,
                order: 0,
                thumbnails: false,
                size: 0,
              });
              gallery[0].thumbnails = true;
            }
            return {
              ...item,
              statusParsed: this.collectionSrv.collectionStatus.find(
                (status: any) => status.value === item.status
              ),
              thumbnails,
              gallery,
            };
          })
        ),
        // tap((data) =>
        //   console.log("ProyectsViewListDesktopComponent.proyectsList$", data)
        // ),
        catchError((err) => {
          console.log(
            "Error on ProyectsViewListDesktopComponent.proyectsList$",
            err
          );
          return of([]);
        })
      );
    this.sub$ = this.proyectsList$.subscribe((res) => {
      this.propertiesList = res;
      // console.log(this.propertiesList);

      if (this.propertiesList.length > 0) {
        this.collectionSrv.onViewSelectedCollection$.next({
          action: "selectProject",
          data: this.propertiesList[0],
        });
      }
    });
  }

  onSelectProject(item: any) {
    // console.log('onSelectProject', item);
    this.collectionSrv.onViewSelectedCollection$.next({
      action: "selectProject",
      data: item,
    });
  }

  async openBuyForm(button: any, form: any) {
    let formContent: any = document.getElementById(form);
    button.classList.toggle("active");
    formContent.classList.toggle("d-block");
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  slideNext() {
    this.swiper?.swiperRef.slideNext(100);
  }
  slidePrev() {
    this.swiper?.swiperRef.slidePrev(100);
  }
}
