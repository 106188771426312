import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, map } from "rxjs";
import { HdWalletService } from "src/app/services/api/hd-wallet.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Web3Service } from "src/app/services/contract/web3.service";
import { Sweetalert2Service } from "src/app/services/sweetalert2.service";

@Component({
  selector: "app-profile-register-wallet-form",
  templateUrl: "./profile-register-wallet-form.component.html",
  styleUrls: ["./profile-register-wallet-form.component.css"],
})
export class ProfileRegisterWalletFormComponent implements OnInit {
  /** Opcion elegida en el formulario */
  public optionSelected: any; // null: no seleccionado, 1 || new: nueva wallet, 2 || own: wallet existente

  /** Bloquear botones */
  public blockButtons: boolean = false;

  /** Estatus de la cuenta de wallet */
  public dataStatus$!: Observable<any>;

  constructor(
    private web3Srv: Web3Service,
    private authSrv: AuthenticationService,
    private sweetAlert2Srv: Sweetalert2Service,
    private spinner: NgxSpinnerService,
    private hdWalletSrv: HdWalletService
  ) {}

  ngOnInit(): void {
    /** Escuchar wallet */
    this.dataStatus$ = this.web3Srv.accountStatus$.pipe(
      map((data: any[]) => (data && data.length > 0 ? data[0] : null))
    );
  }

  /**
   * @dev Conectar cuenta de wallet
   */
  connectAccount() {
    this.web3Srv.launchAskConnectionType();
  }

  /**
   * @dev Desconectar cuenta de wallet
   */
  disconnectAccount() {
    this.web3Srv.logout();
  }

  /**
   * @dev Registrar wallet propia
   * @param addr          Dirección de la wallet a registrar
   * @returns
   */
  async setOwnWallet(addr: string) {
    // console.log('setOwnWallet', addr);
    try {
      const ask = await this.sweetAlert2Srv.askConfirm(
        "walletRegister.areYouSureToRegisterThisWallet?"
      );
      if (!ask) {
        return;
      }

      await this.spinner.show();
      this.blockButtons = true;

      /** Validar si la wallet a registrar ya se encuentra asociada a otra cuenta */
      const findWallet: any[] = await this.authSrv.getDynamicToPromise([
        { field: "walletAddress", condition: "==", value: addr },
      ]);
      // console.log("findWallet", findWallet);

      if (findWallet.length > 0) {
        this.sweetAlert2Srv.showError(
          "errors.theWalletIsAlreadyRegisteredInTheSystem"
        );
        return;
      }

      /** Obtener ID del usuario a través de la sesión */
      const uid: any = await this.authSrv.getUIDPromise();
      // console.log("UID", uid);

      /** Actualizar dirección de billetera del usuario */
      await this.authSrv.update(uid, {
        createWallet: false,
        walletAddress: addr,
      });

      this.sweetAlert2Srv
        .showSuccess("walletRegister.walletHasBeenSuccessfullyRegistered")
        .then(() => {
          /** Cerrar sesión web3 luego de cerrar la alerta */
          this.disconnectAccount();
        });
      return;
    } catch (err) {
      console.log(
        "Error on ProfileRegisterWalletFormComponent.setOwnWallet",
        err
      );
      return;
    } finally {
      this.spinner.hide();
      this.blockButtons = false;
    }
  }

  /**
   * @dev Crear nueva wallet custodio para el usuario
   * @returns
   */
  async setNewWallet() {
    try {
      const ask = await this.sweetAlert2Srv.askConfirm(
        "walletRegister.areYouAsureAboutCreatingANewWallet"
      );
      if (!ask) {
        return;
      }

      await this.spinner.show();
      this.blockButtons = true;

      /** Obtener ID del usuario a través de la sesión */
      const uid: any = await this.authSrv.getUIDPromise();
      console.log("UID", uid);

      /** Crear nueva wallet custodio para el usuario */
      const snapshot = await this.hdWalletSrv.createWalletCustodio({ uid });
      console.log("snapshot", snapshot);

      this.sweetAlert2Srv.showSuccess(
        "walletRegister.walletHasBeenSuccessfullyRegistered"
      );
      return;
    } catch (err) {
      console.log(
        "Error on ProfileRegisterWalletFormComponent.setNewWallet",
        err
      );
      return;
    } finally {
      this.spinner.hide();
      this.blockButtons = false;
    }
  }

  /**
   * TODO: revisar antes de eliminar
   * @param addr
   * @returns
   */
  async onSubmit(addr: string) {
    try {
      const ask = await this.sweetAlert2Srv.askConfirm(
        "¿Está seguro de registrar esta wallet a tu cuenta?, una vez realizada la acción no se puede deshacer"
      );
      if (!ask) {
        return;
      }

      await this.spinner.show();

      console.log("Try to store", addr);

      /** Validar si la wallet a registrar ya se encuentra asociada a otra cuenta */
      const findWallet: any[] = await this.authSrv.getDynamicToPromise([
        { field: "walletAddress", condition: "==", value: addr },
      ]);
      console.log("findWallet", findWallet);

      if (findWallet.length > 0) {
        // this.sweetAlert2Srv.showToast('La wallet ya se encuentra registrada en el sistema', 'error');
        this.sweetAlert2Srv.showError(
          "La wallet ya se encuentra registrada en el sistema"
        );
        return;
      }

      /** Obtener ID del usuario a través de la sesión */
      const uid: any = await this.authSrv.getUIDPromise();
      console.log("UID", uid);

      /** Actualizar dirección de billetera del usuario */
      await this.authSrv.update(uid, { walletAddress: addr });

      /** Cerrar sesión web3 */
      this.web3Srv.logout(false);

      this.sweetAlert2Srv.showToast(
        "Se ha registrado la wallet correctamente",
        "success"
      );
      window.location.reload();
      return;
    } catch (err) {
      console.log("Error on ProfileRegisterWalletFormComponent.onSubmit", err);
      return;
    } finally {
      this.spinner.hide();
    }
  }
}
